<script>
export default {
    name: "trend-section-body",
};
</script>

<template>
    <div class="trend-section-body">
        <slot />
    </div>
</template>

<style lang="scss">
.trend-section-body {
}
</style>
