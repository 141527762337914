export default {
    nl: [
        [
            { x: -68.23729407499985, y: 12.02680084800015 },
            {
                x: -68.24254309799994,
                y: 12.022040106000148,
            },
            { x: -68.25422115799992, y: 12.023627020000092 },
            {
                x: -68.25967363199987,
                y: 12.02806224200016,
            },
            { x: -68.2806290359999, y: 12.072211005000142 },
            {
                x: -68.28538977799991,
                y: 12.09007396000011,
            },
            { x: -68.2872615229999, y: 12.108303127000099 },
            {
                x: -68.28282630099991,
                y: 12.139593817000147,
            },
            { x: -68.28221594999985, y: 12.159898179000052 },
            {
                x: -68.2872615229999,
                y: 12.180324611000117,
            },
            { x: -68.30256100199995, y: 12.202948309000178 },
            {
                x: -68.32127844999991,
                y: 12.213853257000068,
            },
            { x: -68.3697810539999, y: 12.221258856000148 },
            {
                x: -68.39183508999989,
                y: 12.227769273000133,
            },
            { x: -68.41071529899992, y: 12.240301825000143 },
            {
                x: -68.41738847599987,
                y: 12.257025458000072,
            },
            { x: -68.40330969999992, y: 12.276516018000152 },
            {
                x: -68.41075598899991,
                y: 12.28270091400013,
            },
            { x: -68.4064835279999, y: 12.294623114000075 },
            {
                x: -68.40005449099988,
                y: 12.304388739000117,
            },
            { x: -68.39045162699992, y: 12.310207424000154 },
            {
                x: -68.3766169909999,
                y: 12.310044664000186,
            },
            { x: -68.3614802729999, y: 12.302191473000121 },
            {
                x: -68.34044348899988,
                y: 12.274969794000114,
            },
            { x: -68.33189856699993, y: 12.269029039000188 },
            {
                x: -68.3148494129999,
                y: 12.264837958000072,
            },
            { x: -68.25251217399992, y: 12.235541083000143 },
            {
                x: -68.2205297519999,
                y: 12.23114655200007,
            },
            { x: -68.21153723899991, y: 12.228094794000157 },
            {
                x: -68.2046606109999,
                y: 12.223211981000105,
            },
            { x: -68.19420325399992, y: 12.213934637000136 },
            {
                x: -68.19001217399989,
                y: 12.204820054000066,
            },
            { x: -68.20156816299989, y: 12.200832424000083 },
            {
                x: -68.21084550699986,
                y: 12.190090236000158,
            },
            { x: -68.20360266799993, y: 12.137762762000165 },
            {
                x: -68.20531165299984,
                y: 12.118231512000179,
            },
            { x: -68.21393795499995, y: 12.111802476000078 },
            {
                x: -68.23216712099995,
                y: 12.11074453300013,
            },
            { x: -68.23884029899989, y: 12.10455963700015 },
            {
                x: -68.2383520169999,
                y: 12.095770575000088,
            },
            { x: -68.2283422519999, y: 12.069973049000152 },
            {
                x: -68.22581946499992,
                y: 12.060248114000117,
            },
            { x: -68.22752844999991, y: 12.050726630000112 },
            {
                x: -68.2318416009999,
                y: 12.037990627000156,
            },
            { x: -68.23729407499985, y: 12.02680084800015 },
        ],
        [
            {
                x: -62.94890357099987,
                y: 17.47237460300012,
            },
            { x: -62.95872313799992, y: 17.46457117400017 },
            {
                x: -62.98108552199994,
                y: 17.46772373100005,
            },
            { x: -62.99469967399992, y: 17.492132880000142 },
            {
                x: -62.99714107999995,
                y: 17.525620835,
            },
            { x: -62.983796982999934, y: 17.517682944000043 },
            {
                x: -62.97725385399991,
                y: 17.504136838000093,
            },
            { x: -62.96525672899988, y: 17.49891596300013 },
            {
                x: -62.945064509999916,
                y: 17.48538443100007,
            },
            { x: -62.94890357099987, y: 17.47237460300012 },
        ],
        [
            {
                x: -63.22948157499988,
                y: 17.618231512000147,
            },
            { x: -63.23839270699991, y: 17.614691473000093 },
            {
                x: -63.2513321609999,
                y: 17.62376536700016,
            },
            { x: -63.24893144399988, y: 17.640366929000137 },
            {
                x: -63.2384333979999,
                y: 17.646918036000116,
            },
            { x: -63.23029537699989, y: 17.642320054 },
            {
                x: -63.22370357999991,
                y: 17.629217841000028,
            },
            { x: -63.22948157499988, y: 17.618231512000147 },
        ],
        [
            {
                x: 3.73422285200013,
                y: 51.355780341000084,
            },
            { x: 3.755544467000078, y: 51.35008372600011 },
            {
                x: 3.795909050000148,
                y: 51.35008372600011,
            },
            { x: 3.833994988000143, y: 51.34324778900019 },
            {
                x: 3.858164910000141,
                y: 51.345445054,
            },
            { x: 3.955821160000141, y: 51.37177155199999 },
            {
                x: 3.969248894000117,
                y: 51.381659247000115,
            },
            { x: 3.974619988000085, y: 51.401597397999986 },
            {
                x: 3.984141472000089,
                y: 51.410467841000056,
            },
            { x: 4.005707227000102, y: 51.4098167990001 },
            {
                x: 4.029063347000089,
                y: 51.40420156500009,
            },
            { x: 4.043467644000144, y: 51.39850495000012 },
            {
                x: 4.050059441000116,
                y: 51.39223867400015,
            },
            { x: 4.061289910000141, y: 51.37665436400006 },
            {
                x: 4.070160352000073,
                y: 51.370550848000065,
            },
            { x: 4.081309441000086, y: 51.36815013200014 },
            {
                x: 4.105479363000114,
                y: 51.36611562700001,
            },
            { x: 4.115489129000082, y: 51.3609886740001 },
            {
                x: 4.121918165000096,
                y: 51.35936107000008,
            },
            { x: 4.15650475400011, y: 51.357570705000064 },
            {
                x: 4.17367597700013,
                y: 51.37372467700014,
            },
            { x: 4.180023634000065, y: 51.37742747599999 },
            {
                x: 4.199554884000122,
                y: 51.376044012000094,
            },
            { x: 4.221491455026921, y: 51.3680006030731 },
            {
                x: 4.220961547000115,
                y: 51.36381683400005,
            },
            { x: 4.213520141000089, y: 51.34053660100012 },
            {
                x: 4.207939087000084,
                y: 51.33058888800012,
            },
            { x: 4.168561645000096, y: 51.29687001600014 },
            {
                x: 4.118952271000096,
                y: 51.272401225000024,
            },
            { x: 3.995032186000032, y: 51.233230489000064 },
            {
                x: 3.949453572000067,
                y: 51.21077708000017,
            },
            { x: 3.927026001000058, y: 51.20625539200013 },
            {
                x: 3.8086869710001,
                y: 51.20532521600008,
            },
            { x: 3.796284627000119, y: 51.20876169800012 },
            {
                x: 3.781401815000095,
                y: 51.21987213200005,
            },
            { x: 3.779748169000129, y: 51.23031077100008 },
            {
                x: 3.781401815000095,
                y: 51.241421204000105,
            },
            { x: 3.776234172000102, y: 51.254521180000054 },
            {
                x: 3.757320597000046,
                y: 51.262324321000065,
            },
            { x: 3.635054159000049, y: 51.28782664 },
            {
                x: 3.609526001000091,
                y: 51.290436300000025,
            },
            { x: 3.584824666000088, y: 51.28953196300007 },
            {
                x: 3.54503381300006,
                y: 51.28392506900006,
            },
            { x: 3.529014120000113, y: 51.28333079100018 },
            {
                x: 3.514338012000024,
                y: 51.281031189000075,
            },
            { x: 3.503382609000141, y: 51.27395151800011 },
            {
                x: 3.501522258000136,
                y: 51.262401836,
            },
            { x: 3.504106079000053, y: 51.24974111000016 },
            {
                x: 3.501212199000065,
                y: 51.240284323000175,
            },
            { x: 3.45387658700011, y: 51.23563344400012 },
            {
                x: 3.421940552000109,
                y: 51.237984721000075,
            },
            { x: 3.398798477000071, y: 51.24469829300007 },
            {
                x: 3.391658163000102,
                y: 51.246769715000035,
            },
            { x: 3.367370240000099, y: 51.263487040000186 },
            {
                x: 3.353210897000054,
                y: 51.28860178700002,
            },
            { x: 3.353934366000146, y: 51.311907858000055 },
            {
                x: 3.358068481000061,
                y: 51.3367125460001,
            },
            { x: 3.349414674022739, y: 51.375223117889796 },
            {
                x: 3.349457227000073,
                y: 51.37523021,
            },
            { x: 3.510915561000104, y: 51.40794505400011 },
            {
                x: 3.525726759000065,
                y: 51.41559479400014,
            },
            { x: 3.541351759000065, y: 51.41673411699999 },
            {
                x: 3.604665561000103,
                y: 51.391058661000116,
            },
            { x: 3.702159050000148, y: 51.37360260600015 },
            {
                x: 3.73422285200013,
                y: 51.355780341000084,
            },
        ],
        [
            { x: 4.015391472000147, y: 51.701727606000034 },
            {
                x: 4.020274285000113,
                y: 51.69647858300014,
            },
            { x: 4.026621941000144, y: 51.691107489 },
            {
                x: 4.03663170700014,
                y: 51.68585846600011,
            },
            { x: 4.078379754000081, y: 51.67861562700007 },
            {
                x: 4.097341342000078,
                y: 51.67023346600003,
            },
            { x: 4.099375847000118, y: 51.65412018400014 },
            {
                x: 4.089040561000104,
                y: 51.64402903900019,
            },
            { x: 4.072601759000122, y: 51.63690827000012 },
            {
                x: 4.054860873000109,
                y: 51.63263580900012,
            },
            { x: 3.978037957000112, y: 51.62327708499999 },
            {
                x: 3.960297071000098,
                y: 51.623846747000144,
            },
            { x: 3.923106316000115, y: 51.632473049000154 },
            {
                x: 3.901540561000104,
                y: 51.64199453300016,
            },
            { x: 3.891937696000127, y: 51.655503648000135 },
            {
                x: 3.885590040000096,
                y: 51.66836172100015,
            },
            { x: 3.869802280000044, y: 51.67609284100017 },
            {
                x: 3.830577019000117,
                y: 51.68585846600011,
            },
            { x: 3.823903842000078, y: 51.68988678600009 },
            {
                x: 3.817637566000116,
                y: 51.69525788000011,
            },
            { x: 3.809255405000073, y: 51.69953034100014 },
            {
                x: 3.795909050000148,
                y: 51.700140692,
            },
            { x: 3.771250847000147, y: 51.68183014500012 },
            {
                x: 3.765391472000118,
                y: 51.67841217700011,
            },
            { x: 3.720550977000102, y: 51.676825262000094 },
            {
                x: 3.700043165000124,
                y: 51.68060944200011,
            },
            { x: 3.686696811000019, y: 51.69269440300003 },
            {
                x: 3.689463738000086,
                y: 51.725043036000145,
            },
            { x: 3.725840691000059, y: 51.739732164000046 },
            {
                x: 3.810069207000083,
                y: 51.74730052299999,
            },
            { x: 3.814219597000118, y: 51.74823639500006 },
            {
                x: 3.830577019000117,
                y: 51.74730052299999,
            },
            { x: 3.843760613000086, y: 51.74282461100002 },
            {
                x: 3.939626498000081,
                y: 51.741156317,
            },
            { x: 3.961761915000067, y: 51.73810455900012 },
            {
                x: 3.978037957000112,
                y: 51.73029205900012,
            },
            { x: 4.015391472000147, y: 51.701727606000034 },
        ],
        [
            {
                x: 4.892588738000143,
                y: 53.11102936400009,
            },
            { x: 4.892588738000143, y: 53.10480377800003 },
            {
                x: 4.91309655000009,
                y: 53.10480377800003,
            },
            { x: 4.90609785200013, y: 53.091701565000065 },
            {
                x: 4.893077019000145,
                y: 53.07611725500006,
            },
            { x: 4.865244988000085, y: 53.05019765800015 },
            {
                x: 4.818125847000089,
                y: 53.0267601580001,
            },
            { x: 4.795664910000085, y: 53.00031159100011 },
            {
                x: 4.761973504000082,
                y: 52.994330145,
            },
            { x: 4.72681725400011, y: 53.003078518000066 },
            {
                x: 4.707692905000044,
                y: 53.02846914300009,
            },
            { x: 4.71387780000012, y: 53.05463288000014 },
            {
                x: 4.733653191000116,
                y: 53.084214585,
            },
            { x: 4.758474155000101, y: 53.10846588700018 },
            {
                x: 4.805674675000148,
                y: 53.13300202,
            },
            { x: 4.829274936000104, y: 53.162990627000156 },
            {
                x: 4.854340040000096,
                y: 53.18773021000013,
            },
            { x: 4.88575280000012, y: 53.186712958000086 },
            {
                x: 4.889821811000104,
                y: 53.180853583000115,
            },
            { x: 4.892100457000055, y: 53.17572663000011 },
            {
                x: 4.891368035000141,
                y: 53.170965887000115,
            },
            { x: 4.88575280000012, y: 53.16620514500012 },
            {
                x: 4.89991295700014,
                y: 53.15232982000013,
            },
            { x: 4.910166863000085, y: 53.134588934000035 },
            {
                x: 4.909922722000147,
                y: 53.11884186400008,
            },
            { x: 4.892588738000143, y: 53.11102936400009 },
        ],
        [
            {
                x: 4.935720248000081,
                y: 53.22597890800007,
            },
            { x: 4.910166863000085, y: 53.21246979400011 },
            {
                x: 4.878916863000114,
                y: 53.220892645000056,
            },
            { x: 4.921234571000126, y: 53.25372955900014 },
            {
                x: 4.986501498000109,
                y: 53.28998444200006,
            },
            { x: 5.054453972000089, y: 53.31378815300015 },
            {
                x: 5.104828321000127,
                y: 53.30963776200012,
            },
            { x: 5.104828321000127, y: 53.30280182500012 },
            {
                x: 5.078135613000114,
                y: 53.30280182500012,
            },
            { x: 5.065603061000104, y: 53.29848867400001 },
            {
                x: 5.03646894600007,
                y: 53.291815497000144,
            },
            { x: 4.957530144000116, y: 53.24555084800014 },
            {
                x: 4.935720248000081,
                y: 53.22597890800007,
            },
        ],
        [
            { x: 5.427012566000087, y: 53.40643952000012 },
            {
                x: 5.382497592000078,
                y: 53.39468008000013,
            },
            { x: 5.346934441000115, y: 53.389227606000084 },
            {
                x: 5.20834394600007,
                y: 53.352687893000066,
            },
            { x: 5.179860873000081, y: 53.35309479400017 },
            {
                x: 5.167653842000107,
                y: 53.36823151200015,
            },
            { x: 5.178884311000104, y: 53.385443427000084 },
            {
                x: 5.232432488000114,
                y: 53.396714585000076,
            },
            { x: 5.250173373000109, y: 53.40643952000012 },
            {
                x: 5.272959832000112,
                y: 53.40607330900009,
            },
            { x: 5.407237175000148, y: 53.422552802 },
            { x: 5.423594597000146, y: 53.430365302 },
            {
                x: 5.43702233200014,
                y: 53.43646881700006,
            },
            { x: 5.585297071000127, y: 53.45359935100011 },
            {
                x: 5.562022332000112,
                y: 53.43431224200007,
            },
            { x: 5.533702019000088, y: 53.42837148600013 },
            {
                x: 5.475433790000068,
                y: 53.426947333000086,
            },
            { x: 5.477386915000125, y: 53.42352936400006 },
            {
                x: 5.479746941000058,
                y: 53.41620514500006,
            },
            { x: 5.481700066000116, y: 53.412665106000176 },
            {
                x: 5.468597852000045,
                y: 53.40851471600014,
            },
            { x: 5.427012566000087, y: 53.40643952000012 },
        ],
        [
            {
                x: 6.86687259200005,
                y: 53.434271552000084,
            },
            { x: 6.900563998000051, y: 53.35179271000008 },
            {
                x: 6.90577233200014,
                y: 53.35049062700007,
            },
            { x: 6.930023634000037, y: 53.34007396000008 },
            {
                x: 6.93189537900011,
                y: 53.33759186400006,
            },
            { x: 6.936696811000104, y: 53.335394598000065 },
            {
                x: 6.941661004000139,
                y: 53.330715236000124,
            },
            { x: 6.948578321000041, y: 53.32607656500012 },
            {
                x: 6.980153842000049,
                y: 53.31976959800009,
            },
            { x: 7.020030144000116, y: 53.30613841400013 },
            {
                x: 7.037771030000101,
                y: 53.30963776200012,
            },
            { x: 7.046234571000042, y: 53.30385976800006 },
            {
                x: 7.058278842000077,
                y: 53.30194733299999,
            },
            { x: 7.070078972000117, y: 53.30394114800005 },
            {
                x: 7.078623894000145,
                y: 53.30963776200012,
            },
            { x: 7.085459832000083, y: 53.30963776200012 },
            {
                x: 7.085459832000083,
                y: 53.30280182500012,
            },
            { x: 7.077484571000127, y: 53.29865143400015 },
            {
                x: 7.073496941000144,
                y: 53.295640367000075,
            },
            { x: 7.073741082000083, y: 53.291327216000134 },
            {
                x: 7.078623894000145,
                y: 53.282945054000024,
            },
            { x: 7.080739780000073, y: 53.266791083000136 },
            {
                x: 7.111094597000147,
                y: 53.25633372600005,
            },
            { x: 7.183360222000118, y: 53.24510325700005 },
            {
                x: 7.194590691000116,
                y: 53.24502187700007,
            },
            { x: 7.19747237100006, y: 53.21662363700007 },
            {
                x: 7.198505900000043,
                y: 53.200578105000105,
            },
            { x: 7.195405314000112, y: 53.18499766100008 },
            {
                x: 7.188584025000067,
                y: 53.167789408000104,
            },
            { x: 7.174424682000108, y: 53.145775249000124 },
            {
                x: 7.17194421400012,
                y: 53.13771372500004,
            },
            { x: 7.172667684000118, y: 53.12575063100017 },
            {
                x: 7.176491740000074,
                y: 53.11939443000015,
            },
            { x: 7.181349324000081, y: 53.11417511100014 },
            {
                x: 7.185070027000108,
                y: 53.10544179300017,
            },
            { x: 7.194371786000119, y: 53.03384409600001 },
            {
                x: 7.19282149300011,
                y: 52.99800649000015,
            },
            { x: 7.183623088000104, y: 52.96612213100012 },
            {
                x: 7.161918986000103,
                y: 52.9326358040001,
            },
            { x: 7.079856812000116, y: 52.85442352300005 },
            {
                x: 7.072150759000124,
                y: 52.841316552000066,
            },
            { x: 7.061976766000043, y: 52.82401194200009 },
            {
                x: 7.053295125000091,
                y: 52.790577291000105,
            },
            { x: 7.043993367000069, y: 52.68262522400009 },
            {
                x: 7.036655314000085,
                y: 52.64738189700001,
            },
            { x: 7.018465210000045, y: 52.62598785400009 },
            {
                x: 6.982808471000055,
                y: 52.632783305000046,
            },
            { x: 6.967718953000144, y: 52.6366590380001 },
            {
                x: 6.918419637000056,
                y: 52.632189026000084,
            },
            { x: 6.865399617000037, y: 52.64195587200014 },
            {
                x: 6.77052168700007,
                y: 52.641025696000085,
            },
            { x: 6.737035360000078, y: 52.634695333000145 },
            {
                x: 6.719083149000085,
                y: 52.62675898200003,
            },
            { x: 6.7017920320001, y: 52.619114889000045 },
            {
                x: 6.710163615000056,
                y: 52.60865041199998,
            },
            { x: 6.708199911000121, y: 52.599684550000134 },
            {
                x: 6.703652384000094,
                y: 52.5913904830001,
            },
            { x: 6.70396244300008, y: 52.58307057700012 },
            {
                x: 6.710990438000096,
                y: 52.576636862000086,
            },
            { x: 6.743753296000079, y: 52.55971283000018 },
            {
                x: 6.688252807000111,
                y: 52.542582093000036,
            },
            { x: 6.671716349000121, y: 52.54167775500017 },
            {
                x: 6.684015340000144,
                y: 52.52599395800003,
            },
            { x: 6.687529337000115, y: 52.50813975100009 },
            {
                x: 6.688976278000098,
                y: 52.4905180870001,
            },
            { x: 6.695074097000145, y: 52.47560943700013 },
            {
                x: 6.714814494000109,
                y: 52.4615534470001,
            },
            { x: 6.741169474000088, y: 52.45367279100015 },
            {
                x: 6.820337769000105,
                y: 52.44682566300009,
            },
            { x: 6.872014200000137, y: 52.434733378000125 },
            {
                x: 6.9002295330001,
                y: 52.431917013000074,
            },
            { x: 6.926481161000139, y: 52.43297638000014 },
            {
                x: 6.951492553000036,
                y: 52.43747222900008,
            },
            { x: 6.960794311000143, y: 52.44284657800013 },
            {
                x: 6.966995483000062,
                y: 52.44956451500015,
            },
            { x: 6.973300008000137, y: 52.451373190000034 },
            {
                x: 7.034174845000109,
                y: 52.39078257300018,
            },
            { x: 7.048230835000112, y: 52.3650735470001 },
            {
                x: 7.039755900000102,
                y: 52.328615825,
            },
            { x: 7.019705444000067, y: 52.30003875800012 },
            {
                x: 7.012470744000098,
                y: 52.28494923900003,
            },
            { x: 7.011850626000125, y: 52.26662994400006 },
            {
                x: 7.02073897300005,
                y: 52.24526173900013,
            },
            { x: 7.02660015200007, y: 52.23862268700007 },
            {
                x: 7.029110555000017,
                y: 52.23577911400015,
            },
            { x: 7.026320027000053, y: 52.23063730900016 },
            {
                x: 6.981878296000076,
                y: 52.21399749800007,
            },
            { x: 6.971129598000061, y: 52.20627187100014 },
            {
                x: 6.94766849700008,
                y: 52.182810771000085,
            },
            { x: 6.927721395000076, y: 52.17190704400012 },
            {
                x: 6.884829956000118,
                y: 52.16033152300015,
            },
            { x: 6.865399617000037, y: 52.14779998800016 },
            {
                x: 6.843282105000128,
                y: 52.11999806700011,
            },
            { x: 6.830673055000091, y: 52.11289255800007 },
            {
                x: 6.77157568400014,
                y: 52.10879250200013,
            },
            { x: 6.765870808000102, y: 52.108396709000075 },
            {
                x: 6.749851115000125,
                y: 52.10286733000005,
            },
            { x: 6.73290124500005, y: 52.09198944100011 },
            {
                x: 6.7276302490001,
                y: 52.08630503300007,
            },
            { x: 6.719465373000077, y: 52.07356679300009 },
            {
                x: 6.711403849000106,
                y: 52.067288107000174,
            },
            { x: 6.699414917000126, y: 52.06356740400018 },
            {
                x: 6.688872925000083,
                y: 52.06310231600007,
            },
            { x: 6.679881225000115, y: 52.06046681800013 },
            {
                x: 6.672956583000143,
                y: 52.050054016,
            },
            { x: 6.675023641000108, y: 52.03480946900008 },
            {
                x: 6.690939982000145,
                y: 52.02785898800009,
            },
            { x: 6.712850789000099, y: 52.02385406500017 },
            {
                x: 6.778169800000057,
                y: 52.00127146500016,
            },
            { x: 6.804008016000068, y: 51.98437327100005 },
            {
                x: 6.809175659000061,
                y: 51.97954152500013,
            },
            { x: 6.811656127000049, y: 51.9727977510001 },
            {
                x: 6.810725952000098,
                y: 51.960576274000104,
            },
            { x: 6.805661661000101, y: 51.95752736500007 },
            {
                x: 6.798013550000121,
                y: 51.956700542000036,
            },
            { x: 6.789848673000108, y: 51.95114532500004 },
            {
                x: 6.767007690000099,
                y: 51.92551381500006,
            },
            { x: 6.743753296000079, y: 51.908331401 },
            {
                x: 6.716674846000046,
                y: 51.89926218700013,
            },
            { x: 6.628153117000067, y: 51.89750518900014 },
            {
                x: 6.528624308000133,
                y: 51.876421204000124,
            },
            { x: 6.47942834400007, y: 51.85301178000016 },
            {
                x: 6.462891887000068,
                y: 51.848360901000106,
            },
            { x: 6.442531372000133, y: 51.848360901000106 },
            {
                x: 6.378452596000044,
                y: 51.86011728900009,
            },
            { x: 6.386617472000069, y: 51.837560527000065 },
            {
                x: 6.382483358000115,
                y: 51.82758697500015,
            },
            { x: 6.344966268000121, y: 51.8211015830001 },
            {
                x: 6.341969034000015,
                y: 51.83668202800014,
            },
            { x: 6.325742635000097, y: 51.842547303000046 },
            {
                x: 6.28739872200009,
                y: 51.84616465300011,
            },
            { x: 6.282851196000137, y: 51.84960113500013 },
            {
                x: 6.271792439000137,
                y: 51.861900127000084,
            },
            { x: 6.264661092000011, y: 51.86618927100015 },
            {
                x: 6.256392862000069,
                y: 51.86680938700012,
            },
            { x: 6.225180298000083, y: 51.862546082000065 },
            {
                x: 6.191383911000116,
                y: 51.87115020800009,
            },
            { x: 6.159241170000087, y: 51.88729909300018 },
            {
                x: 6.1269950760001,
                y: 51.89673004100013,
            },
            { x: 6.092991984000149, y: 51.885309550000144 },
            {
                x: 6.105394327000056,
                y: 51.87947011300015,
            },
            { x: 6.142704712000067, y: 51.855182190000065 },
            {
                x: 6.156140584000127,
                y: 51.84205637600009,
            },
            { x: 6.140017537000062, y: 51.844536845000064 },
            {
                x: 6.116453084000057,
                y: 51.848283387,
            },
            { x: 6.084000284000126, y: 51.85365773500014 },
            {
                x: 6.043279256000062,
                y: 51.846862285,
            },
            { x: 6.028913208000063, y: 51.84210805300002 },
            {
                x: 6.008242635000045,
                y: 51.83257375100011,
            },
            { x: 5.988915650000081, y: 51.82745778400012 },
            {
                x: 5.948091268000098,
                y: 51.822961935000095,
            },
            { x: 5.931451457000094, y: 51.815649720000025 },
            {
                x: 5.928040812000063,
                y: 51.806735535000186,
            },
            { x: 5.950778442000114, y: 51.795909322000156 },
            {
                x: 5.964110962000063,
                y: 51.77655649899999,
            },
            { x: 5.962353963000083, y: 51.75635101300009 },
            {
                x: 5.946056400000145,
                y: 51.746288216,
            },
            { x: 5.940133097000057, y: 51.74263092100007 },
            {
                x: 5.939306274000103,
                y: 51.73188222300014,
            },
            { x: 5.949228150000095, y: 51.72937591600008 },
            {
                x: 5.978270304000091,
                y: 51.72978932700009,
            },
            { x: 5.989742472000102, y: 51.726378682000146 },
            {
                x: 6.012066691000086,
                y: 51.71586252800002,
            },
            { x: 6.02198856600009, y: 51.70950632799999 },
            {
                x: 6.008552694000115,
                y: 51.68118764300009,
            },
            { x: 6.031497030000054, y: 51.66299753900013 },
            {
                x: 6.099193156000069,
                y: 51.64447153800002,
            },
            { x: 6.087514282000086, y: 51.62209564200013 },
            {
                x: 6.08213993300012,
                y: 51.607807109000056,
            },
            { x: 6.086480754000092, y: 51.59553395600001 },
            {
                x: 6.153039998000082,
                y: 51.538431499000076,
            },
            { x: 6.19334761500005, y: 51.509337667000075 },
            {
                x: 6.20047896300008,
                y: 51.47657480900009,
            },
            { x: 6.205336548000104, y: 51.45843638200016 },
            {
                x: 6.201719197000102,
                y: 51.43864430800009,
            },
            { x: 6.194071086000122, y: 51.417379456 },
            {
                x: 6.192727499000085,
                y: 51.39895680800002,
            },
            { x: 6.207817016000149, y: 51.38766550800012 },
            {
                x: 6.202029256000087,
                y: 51.37888051300014,
            },
            { x: 6.198928670000072, y: 51.356969706000186 },
            {
                x: 6.193554321000106,
                y: 51.344515686000086,
            },
            { x: 6.183632446000104, y: 51.3357048540001 },
            {
                x: 6.158207641000104,
                y: 51.32459442200009,
            },
            { x: 6.147769002000047, y: 51.31816070600014 },
            {
                x: 6.09650598100012,
                y: 51.25881032400012,
            },
            { x: 6.075421997000092, y: 51.24266143900003 },
            {
                x: 6.065190063000102,
                y: 51.23162852000003,
            },
            { x: 6.056611776000096, y: 51.21170725500012 },
            {
                x: 6.05371789500009,
                y: 51.189383037000155,
            },
            { x: 6.06084924300012, y: 51.17090871200004 },
            {
                x: 6.084310344000102,
                y: 51.162692159000116,
            },
            { x: 6.134126424000044, y: 51.18421539400004 },
            {
                x: 6.157070760000096,
                y: 51.179254456000095,
            },
            { x: 6.149112589000111, y: 51.17633473700003 },
            {
                x: 6.12627160700012,
                y: 51.164578349000024,
            },
            { x: 6.147252238000107, y: 51.15225352000006 },
            {
                x: 6.132369426000082,
                y: 51.13982533799999,
            },
            { x: 6.075421997000092, y: 51.12036916100003 },
            {
                x: 5.982921183000144,
                y: 51.074687195000095,
            },
            { x: 5.969485311000113, y: 51.06492035000004 },
            {
                x: 5.950261678000088,
                y: 51.03946970700012,
            },
            { x: 5.937962687000066, y: 51.030943095 },
            {
                x: 5.919565877000138,
                y: 51.029702860000086,
            },
            { x: 5.906233358000122, y: 51.03740264900016 },
            {
                x: 5.894347778000082,
                y: 51.047143657,
            },
            { x: 5.88049849500004, y: 51.05205291800014 },
            {
                x: 5.852489868000106,
                y: 51.042751160000115,
            },
            { x: 5.858174275000067, y: 51.01934173600016 },
            {
                x: 5.87357385200005,
                y: 50.99058380100014,
            },
            { x: 5.874607381000117, y: 50.96536570299999 },
            {
                x: 5.888249959000035,
                y: 50.967122701000065,
            },
            { x: 5.914191529000078, y: 50.975235901000175 },
            {
                x: 5.927730754000065,
                y: 50.977664694000126,
            },
            { x: 5.941580037000051, y: 50.97647613500011 },
            {
                x: 5.967831665000062,
                y: 50.97099843400012,
            },
            { x: 6.00369510900012, y: 50.97373728400007 },
            {
                x: 6.005762167000085,
                y: 50.96851796500014,
            },
            { x: 5.999664348000124, y: 50.96009470600016 },
            {
                x: 5.995840291000064,
                y: 50.9517748010001,
            },
            { x: 5.997287232000076, y: 50.937357076000076 },
            {
                x: 5.999871053000078,
                y: 50.92939890600012,
            },
            { x: 6.007519165000132, y: 50.924076233000065 },
            {
                x: 6.031083618000138,
                y: 50.914722799000124,
            },
            { x: 6.06374312400004, y: 50.90753977499999 },
            {
                x: 6.061779419000088,
                y: 50.90350901300017,
            },
            { x: 6.056508423000139, y: 50.89808298800001 },
            {
                x: 6.054648071000145,
                y: 50.89146840400012,
            },
            { x: 6.063123006000069, y: 50.87090118400012 },
            {
                x: 6.062812948000071,
                y: 50.86294301400007,
            },
            { x: 6.056715129000111, y: 50.85265940400016 },
            {
                x: 6.045553019000096,
                y: 50.84418446900015,
            },
            { x: 6.023022095000073, y: 50.845424704000024 },
            {
                x: 6.009276164000113,
                y: 50.84046376500005,
            },
            { x: 6.000491170000146, y: 50.830438538000024 },
            {
                x: 6.001421346000085,
                y: 50.82180857400009,
            },
            { x: 6.003591756000077, y: 50.81261016899999 },
            {
                x: 5.998320760000041,
                y: 50.800724589000154,
            },
            { x: 5.983954712000127, y: 50.79219797800015 },
            {
                x: 5.968761841000116,
                y: 50.79462677000002,
            },
            { x: 5.972999308000084, y: 50.781811015000116 },
            {
                x: 5.980647420000139,
                y: 50.773439433000036,
            },
            { x: 5.990569295000057, y: 50.76904693600015 },
            {
                x: 5.997080525000115,
                y: 50.762949117000105,
            },
            { x: 5.994910116000113, y: 50.74992665600014 },
            {
                x: 5.974756307000064,
                y: 50.747549540000094,
            },
            { x: 5.902202596000024, y: 50.74904815700002 },
            {
                x: 5.89132117000014,
                y: 50.75123955600016,
            },
            { x: 5.891287548000122, y: 50.751246327 },
            {
                x: 5.879878377000068,
                y: 50.75354400600004,
            },
            { x: 5.793165324000114, y: 50.75214874300015 },
            {
                x: 5.782933390000039,
                y: 50.75669627000009,
            },
            { x: 5.762469523000078, y: 50.77101064099998 },
            {
                x: 5.752444296000135,
                y: 50.773439433000036,
            },
            { x: 5.743555949000039, y: 50.769098613000054 },
            {
                x: 5.732776968000081,
                y: 50.758851489000065,
            },
            { x: 5.727846313000072, y: 50.75416412400001 },
            {
                x: 5.717407674000128,
                y: 50.75282053700014,
            },
            { x: 5.706555623000071, y: 50.75421580000004 },
            {
                x: 5.688365519000115,
                y: 50.7608303840001,
            },
            { x: 5.698700806000119, y: 50.783774720000146 },
            {
                x: 5.700457804000109,
                y: 50.79555694600004,
            },
            { x: 5.68485152100007, y: 50.79850250300014 },
            {
                x: 5.663250773000101,
                y: 50.805065410000125,
            },
            { x: 5.657432381000092, y: 50.807640982000166 },
            {
                x: 5.640719849000078,
                y: 50.81503896100004,
            },
            { x: 5.624493448000067, y: 50.83028350900018 },
            {
                x: 5.622323039000094,
                y: 50.85265940400016,
            },
            { x: 5.694463338000077, y: 50.903664042000045 },
            {
                x: 5.706038859000046,
                y: 50.906609599000134,
            },
            { x: 5.717614380000071, y: 50.909245097000124 },
            {
                x: 5.722368612000082,
                y: 50.9116738900001,
            },
            { x: 5.73528771900007, y: 50.923972881000125 },
            {
                x: 5.752547648000075,
                y: 50.94722727500006,
            },
            { x: 5.764019816000086, y: 50.959422913000154 },
            {
                x: 5.75430464700014,
                y: 50.96309194000015,
            },
            { x: 5.744692830000133, y: 50.963246969000025 },
            {
                x: 5.722368612000082,
                y: 50.959422913000154,
            },
            { x: 5.761746053000081, y: 50.999162090000155 },
            {
                x: 5.777559041000075,
                y: 51.023553366,
            },
            { x: 5.764019816000086, y: 51.035180563000054 },
            {
                x: 5.766396932000133,
                y: 51.04843556800016,
            },
            { x: 5.771461222000113, y: 51.058124899000106 },
            {
                x: 5.779626098000023,
                y: 51.06130299900015,
            },
            { x: 5.79130497200009, y: 51.05499847500015 },
            {
                x: 5.798849731000132,
                y: 51.06148386600001,
            },
            { x: 5.804327434000128, y: 51.06858937600013 },
            {
                x: 5.81176884000007,
                y: 51.082309469000066,
            },
            { x: 5.808978312000106, y: 51.08634023000015 },
            {
                x: 5.807428019000071,
                y: 51.08938914,
            },
            { x: 5.804947550000094, y: 51.096623840000134 },
            {
                x: 5.81590295400008,
                y: 51.09587453200005,
            },
            { x: 5.826651652000095, y: 51.09690806100003 },
            {
                x: 5.836780232000137,
                y: 51.09949188300014,
            },
            { x: 5.845978638000048, y: 51.103393454000084 },
            {
                x: 5.823241007000064,
                y: 51.11874135400016,
            },
            { x: 5.826444946000038, y: 51.130161845000075 },
            {
                x: 5.840500936000069,
                y: 51.138921,
            },
            { x: 5.838020467000092, y: 51.14372690900014 },
            {
                x: 5.829132121000071,
                y: 51.15646514900011,
            },
            { x: 5.812078898000038, y: 51.15731781000015 },
            {
                x: 5.781383098000106,
                y: 51.15240854900009,
            },
            { x: 5.768050577000082, y: 51.15946238300004 },
            {
                x: 5.74717329900011,
                y: 51.17760081000015,
            },
            { x: 5.730843547000063, y: 51.183750306000135 },
            {
                x: 5.718027791000083,
                y: 51.18460296700006,
            },
            { x: 5.676169881000106, y: 51.17917694099999 },
            {
                x: 5.659013306000048,
                y: 51.17907358800015,
            },
            { x: 5.648161255000105, y: 51.183957011000174 },
            {
                x: 5.638549438000098,
                y: 51.190907491000175,
            },
            { x: 5.62501021300011, y: 51.196901957000094 },
            {
                x: 5.568372843000134,
                y: 51.207650655000016,
            },
            { x: 5.551406597000096, y: 51.21676713400008 },
            {
                x: 5.54553186000004,
                y: 51.21992380800005,
            },
            { x: 5.542327921000066, y: 51.24266143900003 },
            {
                x: 5.535196573000121,
                y: 51.26204010000002,
            },
            { x: 5.516696411000083, y: 51.27723297200013 },
            {
                x: 5.493235311000119,
                y: 51.28653472900005,
            },
            { x: 5.471634562000133, y: 51.28808502300008 },
            {
                x: 5.451894165000084,
                y: 51.28211639400014,
            },
            { x: 5.409932901000076, y: 51.26353871700011 },
            {
                x: 5.389055623000104,
                y: 51.258706971,
            },
            { x: 5.270716593000145, y: 51.261833395000124 },
            {
                x: 5.239455934000091,
                y: 51.25693018200017,
            },
            { x: 5.232992798000112, y: 51.25591644300012 },
            {
                x: 5.215422811000025,
                y: 51.258732808,
            },
            { x: 5.214906047000085, y: 51.294337870000064 },
            {
                x: 5.197336059000122,
                y: 51.30834218299999,
            },
            { x: 5.178835897000083, y: 51.309504903000104 },
            {
                x: 5.140181926000082,
                y: 51.30738617000013,
            },
            { x: 5.122921997000105, y: 51.31338063600005 },
            {
                x: 5.118064412000081,
                y: 51.31991770500012,
            },
            { x: 5.107832479000081, y: 51.34198354100012 },
            {
                x: 5.102561483000073,
                y: 51.35066518200007,
            },
            { x: 5.068144979000095, y: 51.37453969400015 },
            {
                x: 5.061737101000062,
                y: 51.38425486300009,
            },
            { x: 5.065251099000108, y: 51.40590728799999 },
            {
                x: 5.076413208000048,
                y: 51.421901144000124,
            },
            { x: 5.079927205000104, y: 51.438540955000136 },
            {
                x: 5.060290161000069,
                y: 51.46197621700012,
            },
            { x: 5.030317831000048, y: 51.4744302370001 },
            {
                x: 5.027527303000085,
                y: 51.47680735300007,
            },
            { x: 5.012127727000092, y: 51.47432688400009 },
            {
                x: 5.00613326000007,
                y: 51.468306580000146,
            },
            { x: 5.002722616000142, y: 51.45900482200001 },
            {
                x: 4.995797973000066,
                y: 51.44670583100015,
            },
            { x: 4.980191691000101, y: 51.43053110800018 },
            {
                x: 4.957144002000064,
                y: 51.410997416000086,
            },
            { x: 4.931925903000092, y: 51.395623678 },
            {
                x: 4.910325154000134,
                y: 51.39190297500009,
            },
            { x: 4.871671183000046, y: 51.403039246 },
            {
                x: 4.853274373000119,
                y: 51.406139832000136,
            },
            { x: 4.783304484000013, y: 51.40763844800016 },
            {
                x: 4.762117146000151,
                y: 51.413374532000105,
            },
            { x: 4.767801554000101, y: 51.42538930300016 },
            {
                x: 4.779067016000056,
                y: 51.42642283200014,
            },
            { x: 4.822681925000011, y: 51.413684591 },
            {
                x: 4.815447224000138,
                y: 51.431073711000025,
            },
            { x: 4.819891398000067, y: 51.44621490500005 },
            {
                x: 4.826092570000128,
                y: 51.46055511500013,
            },
            { x: 4.823922159000062, y: 51.47564463300013 },
            {
                x: 4.813586873000133,
                y: 51.48411956900004,
            },
            { x: 4.796843709000086, y: 51.49101837200011 },
            {
                x: 4.778653605000045,
                y: 51.49535919200009,
            },
            { x: 4.764184204000117, y: 51.49623769100013 },
            {
                x: 4.731214641000064,
                y: 51.485618185000064,
            },
            { x: 4.653906697000053, y: 51.42621612600003 },
            {
                x: 4.643571411000067,
                y: 51.42141021700009,
            },
            { x: 4.630135539000094, y: 51.418206279000124 },
            {
                x: 4.614632609000097,
                y: 51.417560323000046,
            },
            { x: 4.582696573000106, y: 51.42259877600013 },
            {
                x: 4.553861125000083,
                y: 51.41929148400011,
            },
            { x: 4.540631958000091, y: 51.42006663000013 },
            {
                x: 4.521511678000081,
                y: 51.42864491800007,
            },
            { x: 4.523372029000086, y: 51.43841176400012 },
            {
                x: 4.531846964000096,
                y: 51.4508657840001,
            },
            { x: 4.533190551000075, y: 51.467583110000064 },
            {
                x: 4.525232381000109,
                y: 51.47592885400012,
            },
            { x: 4.512623331000071, y: 51.47737579400011 },
            {
                x: 4.483064412000147,
                y: 51.47432688400009,
            },
            { x: 4.429424275000059, y: 51.46197621700012 },
            {
                x: 4.37712772600014,
                y: 51.44288177500012,
            },
            { x: 4.389220011000134, y: 51.434587708 },
            {
                x: 4.387152954000071,
                y: 51.42745636000013,
            },
            { x: 4.3806417240001, y: 51.419653219000125 },
            {
                x: 4.379918253000113,
                y: 51.409524638000065,
            },
            { x: 4.387669719000115, y: 51.40053293900002 },
            {
                x: 4.410097290000124,
                y: 51.38378977500018,
            },
            { x: 4.416195109000085, y: 51.37397125300011 },
            {
                x: 4.411234171000018,
                y: 51.35673716200013,
            },
            { x: 4.392217244000051, y: 51.35040679900011 },
            {
                x: 4.345605103000081,
                y: 51.35229299000012,
            },
            { x: 4.326278117000129, y: 51.35642710400005 },
            {
                x: 4.290104614000114,
                y: 51.3687519330001,
            },
            { x: 4.28112422200013, y: 51.368946510000015 },
            {
                x: 4.261067579111284,
                y: 51.36937927502028,
            },
            { x: 4.257090691000116, y: 51.37250397300012 },
            {
                x: 4.249034050000091,
                y: 51.384222723,
            },
            { x: 4.24634850400011, y: 51.396429755 },
            {
                x: 4.246836785000085,
                y: 51.40672435100011,
            },
            { x: 4.244883660000141, y: 51.414496161 },
            {
                x: 4.234711134000094,
                y: 51.41901276200015,
            },
            { x: 4.222992384000122, y: 51.40949127800015 },
            {
                x: 4.201019727000102,
                y: 51.40534088700012,
            },
            { x: 4.111094597000118, y: 51.40696849200005 },
            {
                x: 4.090342644000116,
                y: 51.41128164300015,
            },
            { x: 4.070160352000073, y: 51.41901276200015 },
            {
                x: 4.015147332000112,
                y: 51.448919989000146,
            },
            { x: 3.998789910000084, y: 51.45311107000008 },
            {
                x: 3.957041863000143,
                y: 51.45579661700004,
            },
            { x: 3.94312584700009, y: 51.45038483299999 },
            {
                x: 3.91342207100007,
                y: 51.415269273000135,
            },
            { x: 3.902110222000061, y: 51.40493398600002 },
            {
                x: 3.886729363000114,
                y: 51.399807033000016,
            },
            { x: 3.850759311000047, y: 51.39736562700007 },
            {
                x: 3.837901238000114,
                y: 51.39223867400015,
            },
            { x: 3.82715905000012, y: 51.391058661000116 },
            {
                x: 3.816416863000114,
                y: 51.39411041900003,
            },
            { x: 3.789073113000114, y: 51.41217682500006 },
            {
                x: 3.728770379000081,
                y: 51.430812893000116,
            },
            { x: 3.68970787900011, y: 51.454291083 },
            {
                x: 3.674001498000052,
                y: 51.45538971600014,
            },
            { x: 3.632578972000146, y: 51.445705471000096 },
            {
                x: 3.610036655000073,
                y: 51.44505442900013,
            },
            { x: 3.553396030000073, y: 51.45311107000008 },
            {
                x: 3.535411004000139,
                y: 51.459214585000055,
            },
            { x: 3.520192905000073, y: 51.47337474199999 },
            {
                x: 3.506846550000148,
                y: 51.48932526200018,
            },
            { x: 3.494802280000044, y: 51.500921942 },
            {
                x: 3.461924675000148,
                y: 51.51898834800012,
            },
            { x: 3.451019727000073, y: 51.529730536000145 },
            {
                x: 3.447032097000089,
                y: 51.54596588700017,
            },
            { x: 3.457530144000145, y: 51.55508047100007 },
            {
                x: 3.560394727000045,
                y: 51.59463125200001,
            },
            { x: 3.828868035000113, y: 51.61273834800012 },
            {
                x: 3.851084832000083,
                y: 51.61078522300009,
            },
            { x: 3.866058790000125, y: 51.60541413000011 },
            {
                x: 3.882172071000098,
                y: 51.59609609600007,
            },
            { x: 3.896494988000085, y: 51.58380768400012 },
            {
                x: 3.905772332000112,
                y: 51.5692406270001,
            },
            { x: 3.895274285000141, y: 51.56659577 },
            {
                x: 3.878265821000099,
                y: 51.55939362200017,
            },
            { x: 3.871592644000116, y: 51.55556875200015 },
            {
                x: 3.871592644000116,
                y: 51.54938385600009,
            },
            { x: 3.967295769000089, y: 51.54311758000013 },
            {
                x: 4.015879754000139,
                y: 51.530829169000086,
            },
            { x: 4.057139519000089, y: 51.508368231000176 },
            {
                x: 4.070323113000143,
                y: 51.49274323100009,
            },
            { x: 4.090017123000081, y: 51.45823802299999 },
            {
                x: 4.10482832100007,
                y: 51.445705471000096,
            },
            { x: 4.12875410200013, y: 51.44135163 },
            {
                x: 4.180837436000076,
                y: 51.44586823100006,
            },
            { x: 4.200450066000116, y: 51.43952057500011 },
            {
                x: 4.22022545700014,
                y: 51.443548895000085,
            },
            { x: 4.252777540000125, y: 51.44253164300004 },
            {
                x: 4.263845248000051,
                y: 51.44440338700018,
            },
            { x: 4.283213738000086, y: 51.44769928600011 },
            {
                x: 4.296722852000045,
                y: 51.470526434000035,
            },
            { x: 4.288584832000139, y: 51.49213288000014 },
            {
                x: 4.267751498000052,
                y: 51.50828685100002,
            },
            { x: 4.23975670700014, y: 51.51850006700012 },
            {
                x: 4.230479363000086,
                y: 51.51955801000018,
            },
            { x: 4.19467207100007, y: 51.52358633000016 },
            {
                x: 4.15333092500012,
                y: 51.53506094,
            },
            { x: 4.137950066000087, y: 51.535874742000104 },
            {
                x: 4.106293165000125,
                y: 51.533514716000134,
            },
            { x: 4.091319207000083, y: 51.53506094 },
            {
                x: 4.067230665000068,
                y: 51.54401276200004,
            },
            { x: 3.988129102000073, y: 51.590277411000116 },
            {
                x: 4.041188998000081,
                y: 51.606024481000176,
            },
            { x: 4.067718946000042, y: 51.61066315300012 },
            {
                x: 4.15723717500012,
                y: 51.60789622600005,
            },
            { x: 4.18336022200009, y: 51.6029320330001 },
            {
                x: 4.20687910200013,
                y: 51.590521552000084,
            },
            { x: 4.207286004000139, y: 51.590277411000116 },
            {
                x: 4.202647332000112,
                y: 51.60911692900008,
            },
            { x: 4.189952019000144, y: 51.6166852890001 },
            {
                x: 4.186127149000129,
                y: 51.61680735900008,
            },
            { x: 4.146657748000081, y: 51.61786530200014 },
            {
                x: 4.135996941000144,
                y: 51.62042877800015,
            },
            { x: 4.125987175000148, y: 51.62470123900006 },
            {
                x: 4.118011915000096,
                y: 51.63068268400017,
            },
            { x: 4.112966342000078, y: 51.639390367000075 },
            {
                x: 4.109873894000089,
                y: 51.65086497600011,
            },
            { x: 4.109629754000139, y: 51.66229889500006 },
            {
                x: 4.112640821000042,
                y: 51.67080312700009,
            },
            { x: 4.125661655000044, y: 51.67963288000014 },
            {
                x: 4.142914259000037,
                y: 51.68414948100009,
            },
            { x: 4.176931186000076, y: 51.68585846600011 },
            {
                x: 4.166351759000122,
                y: 51.69269440300003,
            },
            { x: 4.141123894000145, y: 51.70392487200003 },
            {
                x: 4.071543816000144,
                y: 51.71718984600015,
            },
            { x: 4.057139519000089, y: 51.73029205900012 },
            {
                x: 4.050791863000057,
                y: 51.763495184000035,
            },
            { x: 4.033946160000056, y: 51.78424713700004 },
            {
                x: 4.009776238000143,
                y: 51.79791901200015,
            },
            { x: 3.98145592500012, y: 51.81000397300006 },
            {
                x: 3.962575717000078,
                y: 51.80121491100003,
            },
            { x: 3.93718509200005, y: 51.79633209800012 },
            {
                x: 3.911387566000115,
                y: 51.79637278900013,
            },
            { x: 3.891856316000144, y: 51.792954820000105 },
            {
                x: 3.888194207000083,
                y: 51.78888580900015,
            },
            { x: 3.881114129000139, y: 51.78807200700005 },
            {
                x: 3.871592644000116,
                y: 51.78827545799999,
            },
            { x: 3.862315300000091, y: 51.81606679900007 },
            {
                x: 3.891368035000141,
                y: 51.82827383000012,
            },
            { x: 3.954112175000119, y: 51.840155341000084 },
            {
                x: 3.962657097000147,
                y: 51.84735748900012,
            },
            { x: 3.982188347000146, y: 51.84829336100002 },
            {
                x: 4.015635613000086,
                y: 51.84357330900009,
            },
            { x: 4.043467644000144, y: 51.83421458500008 },
            {
                x: 4.047618035000085,
                y: 51.833685614,
            },
            { x: 4.055023634000094, y: 51.83421458500008 },
            {
                x: 4.065440300000063,
                y: 51.837103583,
            },
            { x: 4.07764733200014, y: 51.84357330900009 },
            {
                x: 4.07007897200009,
                y: 51.85272858299999,
            },
            { x: 4.044606967000107, y: 51.87449778900004 },
            {
                x: 4.037852410000141,
                y: 51.87791575700005,
            },
            { x: 4.032074415000096, y: 51.88202545800003 },
            {
                x: 4.029795769000117,
                y: 51.88825104400017,
            },
            { x: 4.031993035000113, y: 51.89606354400014 },
            {
                x: 4.03663170700014,
                y: 51.901556708,
            },
            { x: 4.041270379000082, y: 51.90550364800008 },
            {
                x: 4.043467644000144,
                y: 51.908677476000136,
            },
            { x: 4.040212436000104, y: 51.92405833500008 },
            {
                x: 4.021169467000107,
                y: 51.963324286,
            },
            { x: 4.01002037900011, y: 51.98012929900001 },
            {
                x: 4.015635613000086,
                y: 51.98769765800016,
            },
            { x: 4.02247155000012, y: 51.99437083500011 },
            {
                x: 4.069021030000044,
                y: 51.9879417990001,
            },
            { x: 4.10767662900011, y: 51.99188873900009 },
            {
                x: 4.141856316000087,
                y: 52.00560130400011,
            },
            { x: 4.28467858200014, y: 52.11294179900006 },
            {
                x: 4.406911655000044,
                y: 52.22345612200008,
            },
            { x: 4.508148634000122, y: 52.33637116100006 },
            {
                x: 4.56861412900011,
                y: 52.44525788000006,
            },
            { x: 4.577321811000104, y: 52.473578192 },
            {
                x: 4.577321811000104,
                y: 52.4809431010001,
            },
            { x: 4.596934441000087, y: 52.50657786700007 },
            {
                x: 4.639333530000073,
                y: 52.700669664000046,
            },
            { x: 4.657074415000068, y: 52.75018952 },
            {
                x: 4.659841342000107,
                y: 52.76528554900001,
            },
            { x: 4.669444207000083, y: 52.79657623900006 },
            {
                x: 4.711680535000112,
                y: 52.86310455900015,
            },
            { x: 4.721364780000073, y: 52.89594147300006 },
            {
                x: 4.722422722000118,
                y: 52.91449616100005,
            },
            { x: 4.726410352000102, y: 52.938299872000144 },
            {
                x: 4.733897332000083,
                y: 52.958889065,
            },
            { x: 4.745453321000099, y: 52.96759674700017 },
            {
                x: 4.806976759000065,
                y: 52.96181875200001,
            },
            { x: 4.81690514400006, y: 52.96759674700017 },
            {
                x: 4.824392123000052,
                y: 52.96015045799999,
            },
            { x: 4.809418165000096, y: 52.929592190000065 },
            {
                x: 4.832774285000085,
                y: 52.911322333,
            },
            { x: 4.873789910000113, y: 52.90395742400001 },
            {
                x: 4.91309655000009,
                y: 52.90558502800003,
            },
            { x: 4.935801629000139, y: 52.913397528000026 },
            {
                x: 4.978282097000118,
                y: 52.93569570500016,
            },
            { x: 4.99903405000012, y: 52.9403343770001 },
            {
                x: 5.038910352000102,
                y: 52.94350820500016,
            },
            { x: 5.078135613000114, y: 52.95400625200013 },
            {
                x: 5.279470248000109,
                y: 53.062933661000116,
            },
            { x: 5.294200066000116, y: 53.073431708 },
            {
                x: 5.31275475400011,
                y: 53.082831122000115,
            },
            { x: 5.358897332000139, y: 53.089341539000095 },
            {
                x: 5.379242384000122,
                y: 53.09735748900014,
            },
            { x: 5.39975019600007, y: 53.12205638200013 },
            {
                x: 5.42709394600007,
                y: 53.19033437700013,
            },
            { x: 5.447438998000052, y: 53.220892645000056 },
            {
                x: 5.482595248000109,
                y: 53.24555084800014,
            },
            { x: 5.504242384000094, y: 53.257025458 },
            {
                x: 5.541026238000085,
                y: 53.266587632,
            },
            { x: 5.557871941000087, y: 53.27802155200013 },
            {
                x: 5.573496941000087,
                y: 53.291652736000074,
            },
            { x: 5.59156334700009, y: 53.30280182500012 },
            {
                x: 5.611827019000089,
                y: 53.30711497600011,
            },
            { x: 5.659678582000111, y: 53.31195709800009 },
            {
                x: 5.694590691000144,
                y: 53.33006419500002,
            },
            { x: 5.854746941000087, y: 53.368801174000126 },
            {
                x: 5.890798373000052,
                y: 53.38426341400004,
            },
            { x: 5.91374759200005, y: 53.3854027360001 },
            {
                x: 5.980804884000122,
                y: 53.40607330900009,
            },
            { x: 6.077321811000076, y: 53.40892161700005 },
            {
                x: 6.186045769000116,
                y: 53.410996812000164,
            },
            { x: 6.273936394000089, y: 53.412665106000176 },
            {
                x: 6.284353061000076,
                y: 53.41054922100007,
            },
            { x: 6.301931186000104, y: 53.40110911700005 },
            {
                x: 6.311534050000091,
                y: 53.39899323100006,
            },
            { x: 6.32007897200009, y: 53.40070221600014 },
            {
                x: 6.345550977000102,
                y: 53.412665106000176,
            },
            { x: 6.494395379000139, y: 53.43870677300005 },
            {
                x: 6.73023522200009,
                y: 53.46063873900008,
            },
            { x: 6.82911217500009, y: 53.450588283000016 },
            {
                x: 6.849782748000109,
                y: 53.44489166900002,
            },
            { x: 6.86687259200005, y: 53.434271552000084 },
        ],
        [
            {
                x: 5.913828972000118,
                y: 53.473863023000106,
            },
            { x: 5.954600457000083, y: 53.461086330000064 },
            {
                x: 5.855642123000081,
                y: 53.44741445500013,
            },
            { x: 5.752777540000095, y: 53.44741445500013 },
            {
                x: 5.727061394000117,
                y: 53.44334544499999,
            },
            { x: 5.678965691000059, y: 53.43012116100014 },
            {
                x: 5.653575066000144,
                y: 53.433742580000015,
            },
            { x: 5.636078321000099, y: 53.44867584800015 },
            {
                x: 5.641937696000127,
                y: 53.46336497600005,
            },
            { x: 5.658864780000016, y: 53.47186920800008 },
            {
                x: 5.674001498000109,
                y: 53.467922268000095,
            },
            { x: 5.913828972000118, y: 53.473863023000106 },
        ],
        [
            {
                x: 6.153168165000125,
                y: 53.46881745000008,
            },
            { x: 6.133311394000117, y: 53.45359935100011 },
            {
                x: 6.127452019000089,
                y: 53.45990631700015,
            },
            { x: 6.127126498000052, y: 53.463080145000035 },
            {
                x: 6.133311394000117,
                y: 53.467922268000095,
            },
            { x: 6.122813347000147, y: 53.481634833000115 },
            {
                x: 6.147959832000139,
                y: 53.494859117000075,
            },
            { x: 6.185394727000072, y: 53.50486888200014 },
            {
                x: 6.211761915000068,
                y: 53.508856512000115,
            },
            { x: 6.310801629000139, y: 53.51414622599999 },
            {
                x: 6.338715040000095,
                y: 53.508856512000115,
            },
            { x: 6.338715040000095, y: 53.50202057500009 },
            {
                x: 6.325043165000068,
                y: 53.50202057500009,
            },
            { x: 6.153168165000125, y: 53.46881745000008 },
        ],
        [
            {
                x: 6.455088738000143,
                y: 53.558091539000124,
            },
            { x: 6.510101759000122, y: 53.543605861000074 },
            {
                x: 6.505625847000146,
                y: 53.54376862200006,
            },
            { x: 6.501963738000114, y: 53.54254791900003 },
            {
                x: 6.498871290000125,
                y: 53.53998444200012,
            },
            { x: 6.496429884000094, y: 53.53620026200009 },
            {
                x: 6.460703972000146,
                y: 53.543361721000124,
            },
            { x: 6.44304446700005, y: 53.54999420799999 },
            {
                x: 6.44117272200009,
                y: 53.55662669500008,
            },
            { x: 6.455088738000143, y: 53.558091539000124 },
        ],
    ],
};
