export default [
    { id: 1, title: "Groningen", code: "VR01", regionType: "safety-region" },
    { id: 2, title: "Fryslân", code: "VR02", regionType: "safety-region" },
    { id: 3, title: "Drenthe", code: "VR03", regionType: "safety-region" },
    { id: 4, title: "IJsselland", code: "VR04", regionType: "safety-region" },
    { id: 5, title: "Twente", code: "VR05", regionType: "safety-region" },
    {
        id: 6,
        title: "Noord- en Oost-Gelderland",
        code: "VR06",
        regionType: "safety-region",
    },
    {
        id: 7,
        title: "Gelderland-Midden",
        code: "VR07",
        regionType: "safety-region",
    },
    {
        id: 8,
        title: "Gelderland-Zuid",
        code: "VR08",
        regionType: "safety-region",
    },
    { id: 9, title: "Utrecht", code: "VR09", regionType: "safety-region" },
    {
        id: 10,
        title: "Noord-Holland-Noord",
        code: "VR10",
        regionType: "safety-region",
    },
    {
        id: 11,
        title: "Zaanstreek-Waterland",
        code: "VR11",
        regionType: "safety-region",
    },
    {
        id: 12,
        title: "Kennemerland",
        code: "VR12",
        regionType: "safety-region",
    },
    {
        id: 13,
        title: "Amsterdam-Amstelland",
        code: "VR13",
        regionType: "safety-region",
    },
    {
        id: 14,
        title: "Gooi en Vechtstreek",
        code: "VR14",
        regionType: "safety-region",
    },
    { id: 15, title: "Haaglanden", code: "VR15", regionType: "safety-region" },
    {
        id: 16,
        title: "Brabant-Zuidoost",
        code: "VR22",
        regionType: "safety-region",
    },
    {
        id: 17,
        title: "Limburg-Noord",
        code: "VR23",
        regionType: "safety-region",
    },
    {
        id: 18,
        title: "Hollands-Midden",
        code: "VR16",
        regionType: "safety-region",
    },
    {
        id: 19,
        title: "Rotterdam-Rijnmond",
        code: "VR17",
        regionType: "safety-region",
    },
    {
        id: 20,
        title: "Zuid-Holland-Zuid",
        code: "VR18",
        regionType: "safety-region",
    },
    { id: 21, title: "Zeeland", code: "VR19", regionType: "safety-region" },
    {
        id: 22,
        title: "Midden- en West-Brabant",
        code: "VR20",
        regionType: "safety-region",
    },
    {
        id: 23,
        title: "Brabant-Noord",
        code: "VR21",
        regionType: "safety-region",
    },
    {
        id: 24,
        title: "Limburg-Zuid",
        code: "VR24",
        regionType: "safety-region",
    },
    { id: 25, title: "Flevoland", code: "VR25", regionType: "safety-region" },
];
