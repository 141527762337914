const dictionary = {
    absolute: {
        nl: "Absoluut",
        en: "Absolute",
    },
    "age-distribution": {
        nl: "Leeftijdsverdeling",
        en: "Age distribution",
    },
    and: {
        nl: "en",
        en: "and",
    },
    change: {
        nl: "Verandering",
        en: "Change",
    },
    city: {
        nl: "Stad",
        en: "City",
    },
    close: {
        nl: "Sluiten",
        en: "Close",
    },
    compare: {
        nl: "Vergelijken",
        en: "Compare",
    },
    country: {
        nl: "Land",
        en: "Country",
    },
    countries: {
        nl: "Landen",
        en: "Countries",
    },
    "color-blindness": {
        nl: "Kleurenblind",
        en: "Color blind",
    },
    "color-set": {
        nl: "Kleursysteem",
        en: "Color system",
    },
    cumulative: {
        nl: "Cumulatief",
        en: "Cumulative",
    },
    current: {
        nl: "Huidig",
        en: "Current",
    },
    days: {
        nl: "Dagen",
        en: "Days",
    },
    "data-positive-tests": {
        nl: "Data positieve testen",
        en: "Data positive tests",
    },
    deceased: {
        nl: "Overledenen",
        en: "Deceased",
    },
    "deceased-per-positive-test": {
        nl: "Overledenen per positieve test",
        en: "Deceased per positive test",
    },
    description: {
        nl: "Beschrijving",
        en: "Description",
    },
    details: {
        nl: "Details",
        en: "Details",
    },
    district: {
        nl: "Stadsdeel",
        en: "District",
    },
    districts: {
        nl: "Stadsdelen",
        en: "Districts",
    },
    "follow-or-feedback": {
        nl: "Volg of feedback",
        en: "Follow or feedback",
    },
    "footer-text": {
        nl: "Wilt u gebruik maken van onze kaarten voor print of web; of voor andere vragen, neem dan contact op met: info@innouveau.nl",
        en: "Would you like to use our maps for print or web; or for other questions, please contact: info@innouveau.nl",
    },
    for: {
        nl: "voor",
        en: "for",
    },
    "for-7-days": {
        nl: "over 7 dagen",
        en: "for 7 days",
    },
    from: {
        nl: "van",
        en: "from",
    },
    ggd: {
        nl: "GGD",
        en: "GGD",
    },
    ggds: {
        nl: "GGD'en",
        en: "GGDs",
    },
    gradient: {
        nl: "Kleurverloop",
        en: "Gradient",
    },
    "gradient-1": {
        nl: "Kleurverloop 1",
        en: "Gradient 1",
    },
    "gradient-2": {
        nl: "Kleurverloop 2",
        en: "Gradient 2",
    },
    "help-with-data": {
        nl: "voor hulp en ter beschikking stellen van alle data.",
        en: "for help and providing all the data.",
    },
    hospitalisations: {
        nl: "Ziekenhuisopnames",
        en: "Hospitalisations",
    },
    "hospitalisations-per-positive-test": {
        nl: "Ziekenhuisopnames per positieve test",
        en: "Hospitalisations per positive test",
    },
    info: {
        nl: "Info",
        en: "Info",
    },
    increase: {
        nl: "Toename",
        en: "Increase",
    },
    inhabitants: {
        nl: "inwoners",
        en: "inhabitants",
    },
    "inhabitants-short": {
        nl: "inw.",
        en: "inh.",
    },
    "in-period": {
        nl: "over periode",
        en: "in period",
    },
    language: {
        nl: "Taal",
        en: "Language",
    },
    "last-7-days": {
        nl: "Laatste 7 dagen",
        en: "Last 7 days",
    },
    "late-reporting-warning": {
        nl: "Deze regio heeft last van vertraagde rapportage. Cijfers kunnen vertekend zijn.",
        en: "This region is suffering from late reporting. Numbers could be distorted by it.",
    },
    least: {
        nl: "Minste",
        en: "Least",
    },
    link: {
        nl: "Link",
        en: "URL",
    },
    "made-by": {
        nl: "Deze kaart is gemaakt door",
        en: "This appication is made by",
    },
    map: {
        nl: "Kaart",
        en: "Map",
    },
    most: {
        nl: "Meeste",
        en: "Most",
    },
    municipality: {
        nl: "Gemeente",
        en: "Municipality",
    },
    municipalities: {
        nl: "Gemeentes",
        en: "Municipalities",
    },
    number: {
        nl: "Aantal",
        en: "Number of",
    },
    notes: {
        nl: "Opmerkingen",
        en: "Notes",
    },
    "not-without": {
        nl: "Maar was niet mogelijk zonder de hulp van o.a.",
        en: "But was not possible without the help of a.o.",
    },
    organisation: {
        nl: "Organisatie",
        en: "Organisation",
    },
    "or-mor": {
        nl: "of meer",
        en: "or more",
    },
    "other-maps": {
        nl: "Andere kaarten",
        en: "Other maps",
    },
    pages: {
        nl: "Pagina's",
        en: "Pages",
    },
    "part-of-population": {
        nl: "Deel van de bevolking",
        en: "Part of population",
    },
    per: {
        nl: "per",
        en: "per",
    },
    percentage: {
        nl: "Percentage",
        en: "Percentage",
    },
    period: {
        nl: "Periode",
        en: "Period",
    },
    population: {
        nl: "Inwoners",
        en: "Population",
    },
    "positive-tests": {
        nl: "Positieven",
        en: "Positives",
    },
    positiveTests: {
        nl: "Positieven",
        en: "Positives",
    },
    province: {
        nl: "Provincie",
        en: "Province",
    },
    provinces: {
        nl: "Provincies",
        en: "Provinces",
    },
    "ratios-since-july-1": {
        nl: "Ratio's sinds 1 juli",
        en: "Ratios since july 1st",
    },
    relative: {
        nl: "Relatieve",
        en: "Relative",
    },
    "safety-region": {
        nl: "Veiligheidsregio",
        en: "Safety region",
    },
    "safety-regions": {
        nl: "Veiligheidsregio's",
        en: "Safety regions",
    },
    "search-or-click-on-map": {
        nl: "Zoek of klik op de kaart",
        en: "Search or click on the map",
    },
    "select-region-first": {
        nl: "Selecteer eerst een gebied op de kaart",
        en: "Select a region on the map first",
    },
    "seven-days-back": {
        nl: "7 dagen geleden",
        en: "7 days back",
    },
    "show-trend": {
        nl: "Toon trends",
        en: "Show trends",
    },
    "signal-value": {
        nl: "Signaalwaarde",
        en: "Signal value",
    },
    "signaling-system": {
        nl: "Signaal systeem",
        en: "Signaling system",
    },
    "source-of-application": {
        nl: "Broncode van deze applicatie",
        en: "Source code of this application",
    },
    sources: {
        nl: "Bronnen",
        en: "Sources",
    },
    speed: {
        nl: "Snelheid",
        en: "Speed",
    },
    "test-results": {
        nl: "Test resultaten",
        en: "Test results",
    },
    timeline: {
        nl: "Tijdlijn",
        en: "Timeline",
    },
    today: {
        nl: "Vandaag",
        en: "Today",
    },
    till: {
        nl: "tot",
        en: "till",
    },
    total: {
        nl: "Totaal",
        en: "Total",
    },
    "total-deceased": {
        nl: "Totaal overleden sinds dag 1",
        en: "Total deceased since day 1",
    },
    "total-hospitalisations": {
        nl: "Totaal ziekenhuisopnames sinds dag 1",
        en: "Total hospitalisations since day 1",
    },
    "total-infections": {
        nl: "Totaal positieve tests sinds dag 1",
        en: "Total of positive tests since day 1",
    },
    "total-infections-percentage-of-population": {
        nl: "Totaal positieve tests sinds dag 1 als pct vd bevolking",
        en: "Total of positive tests since day 1 as perc of population",
    },
    "traffic-light": {
        nl: "Stoplicht",
        en: "Traffic light",
    },
    trends: {
        nl: "Trends",
        en: "Trends",
    },
    "total-since-day-1": {
        nl: "Totalen sinds dag 1",
        en: "Totals since day 1",
    },
    "trend-today": {
        nl: "Trend vandaag",
        en: "Trend today",
    },
    "trend-week": {
        nl: "Trend deze week",
        en: "Trend this week",
    },
    "show-notification": {
        nl: "Het tonen van trends maakt de user interface wat langzaam, daarom staan ze standaard uit",
        en: "Showing trends does make the user interface a bit slow, they are disabled by default",
    },
    vaccination: {
        nl: "Gevaccineerd",
        en: "Vaccinated",
    },
    "vaccinated-top": {
        nl: "Hoogste vaccinatie-graad",
        en: "Highest vaccination rate",
    },
    "vaccinated-bottom": {
        nl: "Laagste vaccinatie-graad",
        en: "Lowest vaccination rate",
    },
    "vaccination-change-note": {
        nl: "Verandering is per periode van 7 weken",
        en: "Change per periode of 7 weeks",
    },
    "with-more-than": {
        nl: "Met meer dan",
        en: "With more than",
    },
    "with-growth": {
        nl: "Met groei",
        en: "With growth",
    },
    "with-shrinkage": {
        nl: "Met krimp",
        en: "With shrinkage",
    },
    "work-in-progress": {
        nl: "Not niet klaar",
        en: "Work in progress",
    },
};

export default dictionary;
