<script>
export default {
    name: "trend-section-head",
};
</script>

<template>
    <div class="trend-section-head">
        <slot />
    </div>
</template>

<style lang="scss">
.trend-section-head {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
}
</style>
