export default [
    { id: 1, title: "Groningen", code: "PV20", regionType: "province" },
    { id: 2, title: "Friesland", code: "PV21", regionType: "province" },
    { id: 3, title: "Drenthe", code: "PV22", regionType: "province" },
    { id: 4, title: "Overijssel", code: "PV23", regionType: "province" },
    { id: 5, title: "Flevoland", code: "PV24", regionType: "province" },
    { id: 6, title: "Gelderland", code: "PV25", regionType: "province" },
    { id: 7, title: "Utrecht", code: "PV26", regionType: "province" },
    { id: 8, title: "Noord-Holland", code: "PV27", regionType: "province" },
    { id: 9, title: "Zuid-Holland", code: "PV28", regionType: "province" },
    { id: 10, title: "Zeeland", code: "PV29", regionType: "province" },
    { id: 11, title: "Noord-Brabant", code: "PV30", regionType: "province" },
    { id: 12, title: "Limburg", code: "PV31", regionType: "province" },
];
