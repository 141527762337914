export default [
    { id: 1, title: "GGD Groningen", code: "GG0111", regionType: "ggd" },
    { id: 2, title: "GGD Drenthe", code: "GG0706", regionType: "ggd" },
    { id: 3, title: "GGD IJsselland", code: "GG1009", regionType: "ggd" },
    { id: 4, title: "GGD Regio Twente", code: "GG1106", regionType: "ggd" },
    {
        id: 5,
        title: "GGD Noord- en Oost-Gelderland",
        code: "GG1413",
        regionType: "ggd",
    },
    {
        id: 6,
        title: "Veiligheids- en Gezondheidsregio Gelderland-Midden",
        code: "GG1911",
        regionType: "ggd",
    },
    { id: 7, title: "GGD Gelderland-Zuid", code: "GG2014", regionType: "ggd" },
    { id: 8, title: "GGD Flevoland", code: "GG2209", regionType: "ggd" },
    { id: 9, title: "GGD Regio Utrecht", code: "GG2514", regionType: "ggd" },
    {
        id: 10,
        title: "GGD Hollands-Noorden",
        code: "GG2707",
        regionType: "ggd",
    },
    { id: 11, title: "GGD Kennemerland", code: "GG3109", regionType: "ggd" },
    { id: 12, title: "GGD Amsterdam", code: "GG3406", regionType: "ggd" },
    {
        id: 13,
        title: "GGD Gooi en Vechtstreek",
        code: "GG3606",
        regionType: "ggd",
    },
    { id: 14, title: "GGD Hollands-Midden", code: "GG4506", regionType: "ggd" },
    {
        id: 15,
        title: "GGD Rotterdam-Rijnmond",
        code: "GG4607",
        regionType: "ggd",
    },
    {
        id: 16,
        title: "Dienst Gezondheid & Jeugd ZHZ",
        code: "GG4816",
        regionType: "ggd",
    },
    { id: 17, title: "GGD Zeeland", code: "GG5006", regionType: "ggd" },
    { id: 18, title: "GGD West-Brabant", code: "GG5206", regionType: "ggd" },
    {
        id: 19,
        title: "GGD Hart voor Brabant",
        code: "GG5406",
        regionType: "ggd",
    },
    {
        id: 20,
        title: "GGD Brabant-Zuidoost",
        code: "GG5608",
        regionType: "ggd",
    },
    { id: 21, title: "GGD Limburg-Noord", code: "GG6011", regionType: "ggd" },
    { id: 22, title: "GGD Haaglanden", code: "GG7014", regionType: "ggd" },
    { id: 23, title: "GGD Fryslân", code: "GG7206", regionType: "ggd" },
    { id: 24, title: "GGD Zuid-Limburg", code: "GG6106", regionType: "ggd" },
    {
        id: 25,
        title: "GGD Zaanstreek/Waterland",
        code: "GG7306",
        regionType: "ggd",
    },
];
