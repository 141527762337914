class AgeGroup {
    constructor({ id = null, title = "", color = "", active = true }) {
        this.id = id;
        this.title = title;
        this.color = color;
        this.active = active;
    }
}

export default AgeGroup;
