export default {
    GM0014: "VR01",
    GM0034: "VR25",
    GM0037: "VR01",
    GM0047: "VR01",
    GM0050: "VR25",
    GM0059: "VR02",
    GM0060: "VR02",
    GM0072: "VR02",
    GM0074: "VR02",
    GM0080: "VR02",
    GM0085: "VR02",
    GM0086: "VR02",
    GM0088: "VR02",
    GM0090: "VR02",
    GM0093: "VR02",
    GM0096: "VR02",
    GM0098: "VR02",
    GM0106: "VR03",
    GM0109: "VR03",
    GM0114: "VR03",
    GM0118: "VR03",
    GM0119: "VR03",
    GM0141: "VR05",
    GM0147: "VR05",
    GM0148: "VR04",
    GM0150: "VR04",
    GM0153: "VR05",
    GM0158: "VR05",
    GM0160: "VR04",
    GM0163: "VR05",
    GM0164: "VR05",
    GM0166: "VR04",
    GM0168: "VR05",
    GM0171: "VR25",
    GM0173: "VR05",
    GM0175: "VR04",
    GM0177: "VR04",
    GM0180: "VR04",
    GM0183: "VR05",
    GM0184: "VR25",
    GM0189: "VR05",
    GM0193: "VR04",
    GM0197: "VR06",
    GM0200: "VR06",
    GM0202: "VR07",
    GM0203: "VR07",
    GM0209: "VR08",
    GM0213: "VR06",
    GM0214: "VR08",
    GM0216: "VR08",
    GM0221: "VR07",
    GM0222: "VR06",
    GM0225: "VR08",
    GM0226: "VR07",
    GM0228: "VR07",
    GM0230: "VR06",
    GM0232: "VR06",
    GM0233: "VR06",
    GM0243: "VR06",
    GM0244: "VR06",
    GM0246: "VR06",
    GM0252: "VR08",
    GM0262: "VR06",
    GM0263: "VR08",
    GM0267: "VR07",
    GM0268: "VR08",
    GM0269: "VR06",
    GM0273: "VR06",
    GM0274: "VR07",
    GM0275: "VR07",
    GM0277: "VR07",
    GM0279: "VR07",
    GM0281: "VR08",
    GM0285: "VR06",
    GM0289: "VR07",
    GM0293: "VR07",
    GM0294: "VR06",
    GM0296: "VR08",
    GM0297: "VR08",
    GM0299: "VR07",
    GM0301: "VR06",
    GM0302: "VR06",
    GM0303: "VR25",
    GM0307: "VR09",
    GM0308: "VR09",
    GM0310: "VR09",
    GM0312: "VR09",
    GM0313: "VR09",
    GM0317: "VR09",
    GM0321: "VR09",
    GM0327: "VR09",
    GM0331: "VR09",
    GM0335: "VR09",
    GM0339: "VR09",
    GM0340: "VR09",
    GM0342: "VR09",
    GM0344: "VR09",
    GM0345: "VR09",
    GM0351: "VR09",
    GM0352: "VR09",
    GM0353: "VR09",
    GM0355: "VR09",
    GM0356: "VR09",
    GM0358: "VR13",
    GM0361: "VR10",
    GM0362: "VR13",
    GM0363: "VR13",
    GM0373: "VR10",
    GM0375: "VR12",
    GM0376: "VR14",
    GM0377: "VR12",
    GM0383: "VR10",
    GM0384: "VR13",
    GM0385: "VR11",
    GM0388: "VR10",
    GM0392: "VR12",
    GM0394: "VR12",
    GM0396: "VR12",
    GM0397: "VR12",
    GM0399: "VR10",
    GM0400: "VR10",
    GM0402: "VR14",
    GM0405: "VR10",
    GM0406: "VR14",
    GM0415: "VR11",
    GM0417: "VR14",
    GM0420: "VR10",
    GM0431: "VR11",
    GM0432: "VR10",
    GM0437: "VR13",
    GM0439: "VR11",
    GM0441: "VR10",
    GM0448: "VR10",
    GM0450: "VR12",
    GM0451: "VR13",
    GM0453: "VR12",
    GM0457: "VR14",
    GM0473: "VR12",
    GM0479: "VR11",
    GM0482: "VR18",
    GM0484: "VR16",
    GM0489: "VR17",
    GM0498: "VR10",
    GM0501: "VR17",
    GM0502: "VR17",
    GM0503: "VR15",
    GM0505: "VR18",
    GM0512: "VR18",
    GM0513: "VR16",
    GM0518: "VR15",
    GM0523: "VR18",
    GM0530: "VR17",
    GM0531: "VR18",
    GM0532: "VR10",
    GM0534: "VR16",
    GM0537: "VR16",
    GM0542: "VR17",
    GM0546: "VR16",
    GM0547: "VR16",
    GM0553: "VR16",
    GM0556: "VR17",
    GM0569: "VR16",
    GM0575: "VR16",
    GM0579: "VR16",
    GM0589: "VR09",
    GM0590: "VR18",
    GM0597: "VR17",
    GM0599: "VR17",
    GM0603: "VR15",
    GM0606: "VR17",
    GM0610: "VR18",
    GM0613: "VR17",
    GM0614: "VR17",
    GM0622: "VR17",
    GM0626: "VR16",
    GM0627: "VR16",
    GM0629: "VR15",
    GM0632: "VR09",
    GM0637: "VR15",
    GM0638: "VR16",
    GM0642: "VR18",
    GM0654: "VR19",
    GM0664: "VR19",
    GM0668: "VR08",
    GM0677: "VR19",
    GM0678: "VR19",
    GM0687: "VR19",
    GM0703: "VR19",
    GM0715: "VR19",
    GM0716: "VR19",
    GM0717: "VR19",
    GM0718: "VR19",
    GM0736: "VR09",
    GM0737: "VR02",
    GM0743: "VR22",
    GM0744: "VR20",
    GM0748: "VR20",
    GM0753: "VR22",
    GM0755: "VR21",
    GM0757: "VR21",
    GM0758: "VR20",
    GM0762: "VR22",
    GM0765: "VR01",
    GM0766: "VR20",
    GM0770: "VR22",
    GM0772: "VR22",
    GM0777: "VR20",
    GM0779: "VR20",
    GM0784: "VR20",
    GM0785: "VR20",
    GM0794: "VR22",
    GM0796: "VR21",
    GM0797: "VR21",
    GM0798: "VR20",
    GM0809: "VR20",
    GM0820: "VR22",
    GM0823: "VR22",
    GM0824: "VR20",
    GM0826: "VR20",
    GM0828: "VR21",
    GM0840: "VR20",
    GM0845: "VR21",
    GM0847: "VR22",
    GM0848: "VR22",
    GM0851: "VR20",
    GM0852: "VR11",
    GM0855: "VR20",
    GM0858: "VR22",
    GM0861: "VR22",
    GM0865: "VR21",
    GM0866: "VR22",
    GM0867: "VR20",
    GM0873: "VR20",
    GM0879: "VR20",
    GM0880: "VR11",
    GM0882: "VR24",
    GM0888: "VR24",
    GM0889: "VR23",
    GM0893: "VR23",
    GM0899: "VR24",
    GM0907: "VR23",
    GM0917: "VR24",
    GM0928: "VR24",
    GM0935: "VR24",
    GM0938: "VR24",
    GM0944: "VR23",
    GM0946: "VR23",
    GM0957: "VR23",
    GM0965: "VR24",
    GM0971: "VR24",
    GM0981: "VR24",
    GM0983: "VR23",
    GM0984: "VR23",
    GM0986: "VR24",
    GM0988: "VR23",
    GM0994: "VR24",
    GM0995: "VR25",
    GM1507: "VR23",
    GM1509: "VR06",
    GM1525: "VR16",
    GM1581: "VR09",
    GM1586: "VR06",
    GM1598: "VR10",
    GM1621: "VR17",
    GM1640: "VR23",
    GM1641: "VR23",
    GM1652: "VR22",
    GM1655: "VR20",
    GM1658: "VR22",
    GM1659: "VR22",
    GM1667: "VR22",
    GM1669: "VR23",
    GM1674: "VR20",
    GM1676: "VR19",
    GM1680: "VR03",
    GM1681: "VR03",
    GM1690: "VR03",
    GM1695: "VR19",
    GM1696: "VR14",
    GM1699: "VR03",
    GM1700: "VR05",
    GM1701: "VR03",
    GM1705: "VR07",
    GM1706: "VR22",
    GM1708: "VR04",
    GM1709: "VR20",
    GM1711: "VR23",
    GM1714: "VR19",
    GM1719: "VR20",
    GM1721: "VR21",
    GM1723: "VR20",
    GM1724: "VR22",
    GM1728: "VR22",
    GM1729: "VR24",
    GM1730: "VR03",
    GM1731: "VR03",
    GM1734: "VR07",
    GM1735: "VR05",
    GM1740: "VR08",
    GM1742: "VR05",
    GM1771: "VR22",
    GM1773: "VR04",
    GM1774: "VR05",
    GM1783: "VR15",
    GM1842: "VR15",
    GM1859: "VR06",
    GM1876: "VR06",
    GM1883: "VR24",
    GM1884: "VR16",
    GM1891: "VR02",
    GM1892: "VR16",
    GM1894: "VR23",
    GM1895: "VR01",
    GM1896: "VR04",
    GM1900: "VR02",
    GM1901: "VR16",
    GM1903: "VR24",
    GM1904: "VR09",
    GM1911: "VR10",
    GM1916: "VR15",
    GM1924: "VR17",
    GM1926: "VR15",
    GM1930: "VR17",
    GM1931: "VR16",
    GM1940: "VR02",
    GM1942: "VR14",
    GM1945: "VR08",
    GM1948: "VR21",
    GM1949: "VR02",
    GM1950: "VR01",
    GM1952: "VR01",
    GM1954: "VR24",
    GM1955: "VR06",
    GM1959: "VR20",
    GM1960: "VR08",
    GM1961: "VR09",
    GM1963: "VR18",
    GM1966: "VR01",
    GM1969: "VR01",
    GM1970: "VR02",
    GM1978: "VR18",
    GM1979: "VR01",
    GM1980: "VR10",
    GM1982: "VR21",
    GM1991: "VR21",
};
