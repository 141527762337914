// year: 2020
// source: https://www.cbs.nl/nl-nl/maatwerk/2020/39/buurt-wijk-en-gemeente-2020-voor-postcode-huisnummer
export default {
    WK000300: "GM0003",
    WK001001: "GM0010",
    WK001002: "GM0010",
    WK001003: "GM0010",
    WK001004: "GM0010",
    WK001005: "GM0010",
    WK001006: "GM0010",
    WK001007: "GM0010",
    WK001008: "GM0010",
    WK001009: "GM0010",
    WK002400: "GM0024",
    WK002401: "GM0024",
    WK002402: "GM0024",
    WK002403: "GM0024",
    WK003701: "GM0037",
    WK003702: "GM0037",
    WK003703: "GM0037",
    WK003704: "GM0037",
    WK003705: "GM0037",
    WK004700: "GM0047",
    WK004701: "GM0047",
    WK004702: "GM0047",
    WK005000: "GM0050",
    WK005900: "GM0059",
    WK005901: "GM0059",
    WK005902: "GM0059",
    WK006000: "GM0060",
    WK007200: "GM0072",
    WK007201: "GM0072",
    WK007202: "GM0072",
    WK007401: "GM0074",
    WK007402: "GM0074",
    WK007403: "GM0074",
    WK007404: "GM0074",
    WK007406: "GM0074",
    WK007407: "GM0074",
    WK007409: "GM0074",
    WK008501: "GM0085",
    WK008502: "GM0085",
    WK008503: "GM0085",
    WK008504: "GM0085",
    WK008505: "GM0085",
    WK008506: "GM0085",
    WK008507: "GM0085",
    WK008508: "GM0085",
    WK008509: "GM0085",
    WK008510: "GM0085",
    WK008511: "GM0085",
    WK008512: "GM0085",
    WK008513: "GM0085",
    WK008601: "GM0086",
    WK008602: "GM0086",
    WK008604: "GM0086",
    WK008605: "GM0086",
    WK008606: "GM0086",
    WK008607: "GM0086",
    WK008608: "GM0086",
    WK008609: "GM0086",
    WK008610: "GM0086",
    WK008611: "GM0086",
    WK008613: "GM0086",
    WK008614: "GM0086",
    WK008615: "GM0086",
    WK008616: "GM0086",
    WK008617: "GM0086",
    WK008800: "GM0088",
    WK009000: "GM0090",
    WK009001: "GM0090",
    WK009300: "GM0093",
    WK009301: "GM0093",
    WK009302: "GM0093",
    WK009303: "GM0093",
    WK009304: "GM0093",
    WK009305: "GM0093",
    WK009600: "GM0096",
    WK009800: "GM0098",
    WK009801: "GM0098",
    WK009802: "GM0098",
    WK009803: "GM0098",
    WK009804: "GM0098",
    WK009805: "GM0098",
    WK009806: "GM0098",
    WK009807: "GM0098",
    WK009808: "GM0098",
    WK009809: "GM0098",
    WK009810: "GM0098",
    WK009811: "GM0098",
    WK009812: "GM0098",
    WK009813: "GM0098",
    WK009814: "GM0098",
    WK009815: "GM0098",
    WK010600: "GM0106",
    WK010601: "GM0106",
    WK010602: "GM0106",
    WK010603: "GM0106",
    WK010604: "GM0106",
    WK010605: "GM0106",
    WK010606: "GM0106",
    WK010607: "GM0106",
    WK010608: "GM0106",
    WK010699: "GM0106",
    WK010910: "GM0109",
    WK010911: "GM0109",
    WK010912: "GM0109",
    WK010913: "GM0109",
    WK010914: "GM0109",
    WK010920: "GM0109",
    WK010930: "GM0109",
    WK010940: "GM0109",
    WK010950: "GM0109",
    WK011401: "GM0114",
    WK011402: "GM0114",
    WK011403: "GM0114",
    WK011404: "GM0114",
    WK011405: "GM0114",
    WK011406: "GM0114",
    WK011407: "GM0114",
    WK011408: "GM0114",
    WK011409: "GM0114",
    WK011410: "GM0114",
    WK011413: "GM0114",
    WK011421: "GM0114",
    WK011432: "GM0114",
    WK011440: "GM0114",
    WK011441: "GM0114",
    WK011442: "GM0114",
    WK011443: "GM0114",
    WK011444: "GM0114",
    WK011445: "GM0114",
    WK011446: "GM0114",
    WK011447: "GM0114",
    WK011448: "GM0114",
    WK011449: "GM0114",
    WK011450: "GM0114",
    WK011451: "GM0114",
    WK011452: "GM0114",
    WK011453: "GM0114",
    WK011850: "GM0118",
    WK011851: "GM0118",
    WK011852: "GM0118",
    WK011853: "GM0118",
    WK011854: "GM0118",
    WK011855: "GM0118",
    WK011856: "GM0118",
    WK011857: "GM0118",
    WK011858: "GM0118",
    WK011859: "GM0118",
    WK011860: "GM0118",
    WK011861: "GM0118",
    WK011862: "GM0118",
    WK011900: "GM0119",
    WK011901: "GM0119",
    WK011902: "GM0119",
    WK011903: "GM0119",
    WK011904: "GM0119",
    WK011905: "GM0119",
    WK011906: "GM0119",
    WK011907: "GM0119",
    WK011908: "GM0119",
    WK011909: "GM0119",
    WK011910: "GM0119",
    WK011911: "GM0119",
    WK011912: "GM0119",
    WK011913: "GM0119",
    WK014110: "GM0141",
    WK014111: "GM0141",
    WK014112: "GM0141",
    WK014113: "GM0141",
    WK014114: "GM0141",
    WK014115: "GM0141",
    WK014116: "GM0141",
    WK014117: "GM0141",
    WK014118: "GM0141",
    WK014119: "GM0141",
    WK014120: "GM0141",
    WK014121: "GM0141",
    WK014700: "GM0147",
    WK014701: "GM0147",
    WK014801: "GM0148",
    WK014802: "GM0148",
    WK014803: "GM0148",
    WK015001: "GM0150",
    WK015002: "GM0150",
    WK015003: "GM0150",
    WK015004: "GM0150",
    WK015005: "GM0150",
    WK015006: "GM0150",
    WK015007: "GM0150",
    WK015008: "GM0150",
    WK015009: "GM0150",
    WK015010: "GM0150",
    WK015011: "GM0150",
    WK015012: "GM0150",
    WK015013: "GM0150",
    WK015014: "GM0150",
    WK015015: "GM0150",
    WK015300: "GM0153",
    WK015301: "GM0153",
    WK015302: "GM0153",
    WK015303: "GM0153",
    WK015304: "GM0153",
    WK015305: "GM0153",
    WK015306: "GM0153",
    WK015307: "GM0153",
    WK015308: "GM0153",
    WK015309: "GM0153",
    WK015800: "GM0158",
    WK015801: "GM0158",
    WK015802: "GM0158",
    WK015803: "GM0158",
    WK015804: "GM0158",
    WK015805: "GM0158",
    WK015806: "GM0158",
    WK015809: "GM0158",
    WK016001: "GM0160",
    WK016002: "GM0160",
    WK016003: "GM0160",
    WK016004: "GM0160",
    WK016005: "GM0160",
    WK016006: "GM0160",
    WK016007: "GM0160",
    WK016008: "GM0160",
    WK016009: "GM0160",
    WK016010: "GM0160",
    WK016011: "GM0160",
    WK016012: "GM0160",
    WK016013: "GM0160",
    WK016014: "GM0160",
    WK016015: "GM0160",
    WK016016: "GM0160",
    WK016017: "GM0160",
    WK016018: "GM0160",
    WK016019: "GM0160",
    WK016020: "GM0160",
    WK016021: "GM0160",
    WK016022: "GM0160",
    WK016023: "GM0160",
    WK016024: "GM0160",
    WK016025: "GM0160",
    WK016026: "GM0160",
    WK016027: "GM0160",
    WK016028: "GM0160",
    WK016029: "GM0160",
    WK016301: "GM0163",
    WK016302: "GM0163",
    WK016303: "GM0163",
    WK016304: "GM0163",
    WK016305: "GM0163",
    WK016306: "GM0163",
    WK016307: "GM0163",
    WK016308: "GM0163",
    WK016309: "GM0163",
    WK016400: "GM0164",
    WK016401: "GM0164",
    WK016402: "GM0164",
    WK016403: "GM0164",
    WK016404: "GM0164",
    WK016405: "GM0164",
    WK016406: "GM0164",
    WK016407: "GM0164",
    WK016408: "GM0164",
    WK016409: "GM0164",
    WK016600: "GM0166",
    WK016603: "GM0166",
    WK016604: "GM0166",
    WK016605: "GM0166",
    WK016606: "GM0166",
    WK016607: "GM0166",
    WK016608: "GM0166",
    WK016609: "GM0166",
    WK016800: "GM0168",
    WK016801: "GM0168",
    WK016802: "GM0168",
    WK016803: "GM0168",
    WK016804: "GM0168",
    WK017101: "GM0171",
    WK017102: "GM0171",
    WK017103: "GM0171",
    WK017104: "GM0171",
    WK017105: "GM0171",
    WK017106: "GM0171",
    WK017107: "GM0171",
    WK017108: "GM0171",
    WK017109: "GM0171",
    WK017110: "GM0171",
    WK017111: "GM0171",
    WK017112: "GM0171",
    WK017301: "GM0173",
    WK017302: "GM0173",
    WK017303: "GM0173",
    WK017304: "GM0173",
    WK017305: "GM0173",
    WK017306: "GM0173",
    WK017307: "GM0173",
    WK017308: "GM0173",
    WK017309: "GM0173",
    WK017310: "GM0173",
    WK017311: "GM0173",
    WK017312: "GM0173",
    WK017313: "GM0173",
    WK017314: "GM0173",
    WK017501: "GM0175",
    WK017502: "GM0175",
    WK017503: "GM0175",
    WK017504: "GM0175",
    WK017505: "GM0175",
    WK017506: "GM0175",
    WK017507: "GM0175",
    WK017508: "GM0175",
    WK017509: "GM0175",
    WK017510: "GM0175",
    WK017511: "GM0175",
    WK018000: "GM0180",
    WK018001: "GM0180",
    WK018002: "GM0180",
    WK018003: "GM0180",
    WK018004: "GM0180",
    WK018005: "GM0180",
    WK018006: "GM0180",
    WK018007: "GM0180",
    WK018300: "GM0183",
    WK018301: "GM0183",
    WK018302: "GM0183",
    WK018303: "GM0183",
    WK018304: "GM0183",
    WK018305: "GM0183",
    WK018306: "GM0183",
    WK018307: "GM0183",
    WK018400: "GM0184",
    WK018900: "GM0189",
    WK018901: "GM0189",
    WK018902: "GM0189",
    WK018903: "GM0189",
    WK018904: "GM0189",
    WK018905: "GM0189",
    WK019310: "GM0193",
    WK019311: "GM0193",
    WK019312: "GM0193",
    WK019313: "GM0193",
    WK019314: "GM0193",
    WK019320: "GM0193",
    WK019321: "GM0193",
    WK019322: "GM0193",
    WK019330: "GM0193",
    WK019331: "GM0193",
    WK019332: "GM0193",
    WK019340: "GM0193",
    WK019341: "GM0193",
    WK019350: "GM0193",
    WK019351: "GM0193",
    WK019352: "GM0193",
    WK019701: "GM0197",
    WK019702: "GM0197",
    WK019703: "GM0197",
    WK019704: "GM0197",
    WK020001: "GM0200",
    WK020002: "GM0200",
    WK020003: "GM0200",
    WK020004: "GM0200",
    WK020005: "GM0200",
    WK020006: "GM0200",
    WK020007: "GM0200",
    WK020008: "GM0200",
    WK020010: "GM0200",
    WK020011: "GM0200",
    WK020012: "GM0200",
    WK020013: "GM0200",
    WK020014: "GM0200",
    WK020015: "GM0200",
    WK020016: "GM0200",
    WK020017: "GM0200",
    WK020201: "GM0202",
    WK020202: "GM0202",
    WK020203: "GM0202",
    WK020204: "GM0202",
    WK020205: "GM0202",
    WK020206: "GM0202",
    WK020207: "GM0202",
    WK020208: "GM0202",
    WK020209: "GM0202",
    WK020210: "GM0202",
    WK020211: "GM0202",
    WK020212: "GM0202",
    WK020213: "GM0202",
    WK020214: "GM0202",
    WK020215: "GM0202",
    WK020216: "GM0202",
    WK020217: "GM0202",
    WK020218: "GM0202",
    WK020219: "GM0202",
    WK020220: "GM0202",
    WK020221: "GM0202",
    WK020222: "GM0202",
    WK020223: "GM0202",
    WK020224: "GM0202",
    WK020354: "GM0203",
    WK020355: "GM0203",
    WK020356: "GM0203",
    WK020357: "GM0203",
    WK020358: "GM0203",
    WK020359: "GM0203",
    WK020360: "GM0203",
    WK020361: "GM0203",
    WK020362: "GM0203",
    WK020363: "GM0203",
    WK020901: "GM0209",
    WK020902: "GM0209",
    WK020903: "GM0209",
    WK020904: "GM0209",
    WK021301: "GM0213",
    WK021302: "GM0213",
    WK021303: "GM0213",
    WK021304: "GM0213",
    WK021305: "GM0213",
    WK021306: "GM0213",
    WK021400: "GM0214",
    WK021401: "GM0214",
    WK021402: "GM0214",
    WK021403: "GM0214",
    WK021404: "GM0214",
    WK021405: "GM0214",
    WK021406: "GM0214",
    WK021600: "GM0216",
    WK022101: "GM0221",
    WK022201: "GM0222",
    WK022202: "GM0222",
    WK022203: "GM0222",
    WK022204: "GM0222",
    WK022205: "GM0222",
    WK022206: "GM0222",
    WK022207: "GM0222",
    WK022208: "GM0222",
    WK022209: "GM0222",
    WK022211: "GM0222",
    WK022231: "GM0222",
    WK022500: "GM0225",
    WK022501: "GM0225",
    WK022502: "GM0225",
    WK022503: "GM0225",
    WK022504: "GM0225",
    WK022601: "GM0226",
    WK022602: "GM0226",
    WK022603: "GM0226",
    WK022604: "GM0226",
    WK022605: "GM0226",
    WK022606: "GM0226",
    WK022801: "GM0228",
    WK022802: "GM0228",
    WK022803: "GM0228",
    WK022804: "GM0228",
    WK022805: "GM0228",
    WK022810: "GM0228",
    WK022811: "GM0228",
    WK022812: "GM0228",
    WK022813: "GM0228",
    WK022820: "GM0228",
    WK022830: "GM0228",
    WK022840: "GM0228",
    WK022850: "GM0228",
    WK022860: "GM0228",
    WK022870: "GM0228",
    WK022880: "GM0228",
    WK022890: "GM0228",
    WK023001: "GM0230",
    WK023002: "GM0230",
    WK023003: "GM0230",
    WK023200: "GM0232",
    WK023201: "GM0232",
    WK023202: "GM0232",
    WK023203: "GM0232",
    WK023300: "GM0233",
    WK023301: "GM0233",
    WK024301: "GM0243",
    WK024302: "GM0243",
    WK024303: "GM0243",
    WK024304: "GM0243",
    WK024305: "GM0243",
    WK024306: "GM0243",
    WK024307: "GM0243",
    WK024308: "GM0243",
    WK024309: "GM0243",
    WK024310: "GM0243",
    WK024311: "GM0243",
    WK024312: "GM0243",
    WK024313: "GM0243",
    WK024314: "GM0243",
    WK024315: "GM0243",
    WK024316: "GM0243",
    WK024400: "GM0244",
    WK024401: "GM0244",
    WK024600: "GM0246",
    WK024601: "GM0246",
    WK025200: "GM0252",
    WK025201: "GM0252",
    WK026200: "GM0262",
    WK026201: "GM0262",
    WK026202: "GM0262",
    WK026203: "GM0262",
    WK026204: "GM0262",
    WK026205: "GM0262",
    WK026300: "GM0263",
    WK026301: "GM0263",
    WK026302: "GM0263",
    WK026303: "GM0263",
    WK026304: "GM0263",
    WK026701: "GM0267",
    WK026702: "GM0267",
    WK026703: "GM0267",
    WK026704: "GM0267",
    WK026705: "GM0267",
    WK026801: "GM0268",
    WK026802: "GM0268",
    WK026803: "GM0268",
    WK026804: "GM0268",
    WK026805: "GM0268",
    WK026806: "GM0268",
    WK026807: "GM0268",
    WK026808: "GM0268",
    WK026809: "GM0268",
    WK026900: "GM0269",
    WK026901: "GM0269",
    WK026902: "GM0269",
    WK027300: "GM0273",
    WK027301: "GM0273",
    WK027401: "GM0274",
    WK027402: "GM0274",
    WK027403: "GM0274",
    WK027404: "GM0274",
    WK027405: "GM0274",
    WK027406: "GM0274",
    WK027407: "GM0274",
    WK027408: "GM0274",
    WK027409: "GM0274",
    WK027410: "GM0274",
    WK027411: "GM0274",
    WK027412: "GM0274",
    WK027500: "GM0275",
    WK027501: "GM0275",
    WK027502: "GM0275",
    WK027700: "GM0277",
    WK027900: "GM0279",
    WK028100: "GM0281",
    WK028101: "GM0281",
    WK028102: "GM0281",
    WK028103: "GM0281",
    WK028500: "GM0285",
    WK028501: "GM0285",
    WK028502: "GM0285",
    WK028503: "GM0285",
    WK028901: "GM0289",
    WK028902: "GM0289",
    WK028903: "GM0289",
    WK028904: "GM0289",
    WK028905: "GM0289",
    WK028906: "GM0289",
    WK028907: "GM0289",
    WK028908: "GM0289",
    WK028909: "GM0289",
    WK028910: "GM0289",
    WK028911: "GM0289",
    WK028912: "GM0289",
    WK029300: "GM0293",
    WK029400: "GM0294",
    WK029401: "GM0294",
    WK029600: "GM0296",
    WK029601: "GM0296",
    WK029602: "GM0296",
    WK029603: "GM0296",
    WK029604: "GM0296",
    WK029605: "GM0296",
    WK029606: "GM0296",
    WK029607: "GM0296",
    WK029608: "GM0296",
    WK029700: "GM0297",
    WK029701: "GM0297",
    WK029702: "GM0297",
    WK029703: "GM0297",
    WK029900: "GM0299",
    WK029901: "GM0299",
    WK029902: "GM0299",
    WK029903: "GM0299",
    WK029904: "GM0299",
    WK029905: "GM0299",
    WK029906: "GM0299",
    WK029907: "GM0299",
    WK029908: "GM0299",
    WK029909: "GM0299",
    WK029910: "GM0299",
    WK029911: "GM0299",
    WK030100: "GM0301",
    WK030101: "GM0301",
    WK030102: "GM0301",
    WK030103: "GM0301",
    WK030104: "GM0301",
    WK030105: "GM0301",
    WK030200: "GM0302",
    WK030201: "GM0302",
    WK030301: "GM0303",
    WK030302: "GM0303",
    WK030303: "GM0303",
    WK030304: "GM0303",
    WK030305: "GM0303",
    WK030306: "GM0303",
    WK030307: "GM0303",
    WK030701: "GM0307",
    WK030702: "GM0307",
    WK030703: "GM0307",
    WK030704: "GM0307",
    WK030705: "GM0307",
    WK030706: "GM0307",
    WK030707: "GM0307",
    WK030708: "GM0307",
    WK030709: "GM0307",
    WK030710: "GM0307",
    WK030711: "GM0307",
    WK030712: "GM0307",
    WK030713: "GM0307",
    WK030714: "GM0307",
    WK030715: "GM0307",
    WK030716: "GM0307",
    WK030717: "GM0307",
    WK030718: "GM0307",
    WK030719: "GM0307",
    WK030720: "GM0307",
    WK030721: "GM0307",
    WK030722: "GM0307",
    WK030723: "GM0307",
    WK030724: "GM0307",
    WK030725: "GM0307",
    WK030726: "GM0307",
    WK030727: "GM0307",
    WK030728: "GM0307",
    WK030729: "GM0307",
    WK030730: "GM0307",
    WK030731: "GM0307",
    WK030800: "GM0308",
    WK030801: "GM0308",
    WK030802: "GM0308",
    WK030804: "GM0308",
    WK030805: "GM0308",
    WK031001: "GM0310",
    WK031002: "GM0310",
    WK031003: "GM0310",
    WK031004: "GM0310",
    WK031005: "GM0310",
    WK031006: "GM0310",
    WK031007: "GM0310",
    WK031008: "GM0310",
    WK031009: "GM0310",
    WK031010: "GM0310",
    WK031200: "GM0312",
    WK031201: "GM0312",
    WK031202: "GM0312",
    WK031300: "GM0313",
    WK031702: "GM0317",
    WK032700: "GM0327",
    WK032701: "GM0327",
    WK032702: "GM0327",
    WK032703: "GM0327",
    WK032704: "GM0327",
    WK032705: "GM0327",
    WK033100: "GM0331",
    WK033500: "GM0335",
    WK033501: "GM0335",
    WK033502: "GM0335",
    WK033900: "GM0339",
    WK034000: "GM0340",
    WK034001: "GM0340",
    WK034002: "GM0340",
    WK034003: "GM0340",
    WK034004: "GM0340",
    WK034005: "GM0340",
    WK034006: "GM0340",
    WK034007: "GM0340",
    WK034008: "GM0340",
    WK034009: "GM0340",
    WK034010: "GM0340",
    WK034011: "GM0340",
    WK034012: "GM0340",
    WK034013: "GM0340",
    WK034201: "GM0342",
    WK034202: "GM0342",
    WK034203: "GM0342",
    WK034204: "GM0342",
    WK034205: "GM0342",
    WK034206: "GM0342",
    WK034207: "GM0342",
    WK034208: "GM0342",
    WK034401: "GM0344",
    WK034402: "GM0344",
    WK034403: "GM0344",
    WK034404: "GM0344",
    WK034405: "GM0344",
    WK034406: "GM0344",
    WK034407: "GM0344",
    WK034408: "GM0344",
    WK034409: "GM0344",
    WK034410: "GM0344",
    WK034500: "GM0345",
    WK034501: "GM0345",
    WK034502: "GM0345",
    WK034503: "GM0345",
    WK034504: "GM0345",
    WK034505: "GM0345",
    WK035100: "GM0351",
    WK035200: "GM0352",
    WK035201: "GM0352",
    WK035202: "GM0352",
    WK035203: "GM0352",
    WK035300: "GM0353",
    WK035501: "GM0355",
    WK035502: "GM0355",
    WK035503: "GM0355",
    WK035504: "GM0355",
    WK035505: "GM0355",
    WK035601: "GM0356",
    WK035602: "GM0356",
    WK035603: "GM0356",
    WK035604: "GM0356",
    WK035605: "GM0356",
    WK035606: "GM0356",
    WK035607: "GM0356",
    WK035608: "GM0356",
    WK035610: "GM0356",
    WK035611: "GM0356",
    WK035612: "GM0356",
    WK035613: "GM0356",
    WK035614: "GM0356",
    WK035615: "GM0356",
    WK035616: "GM0356",
    WK035618: "GM0356",
    WK035620: "GM0356",
    WK035621: "GM0356",
    WK035622: "GM0356",
    WK035623: "GM0356",
    WK035624: "GM0356",
    WK035625: "GM0356",
    WK035800: "GM0358",
    WK035801: "GM0358",
    WK035802: "GM0358",
    WK036101: "GM0361",
    WK036102: "GM0361",
    WK036103: "GM0361",
    WK036104: "GM0361",
    WK036105: "GM0361",
    WK036106: "GM0361",
    WK036107: "GM0361",
    WK036108: "GM0361",
    WK036109: "GM0361",
    WK036110: "GM0361",
    WK036111: "GM0361",
    WK036201: "GM0362",
    WK036202: "GM0362",
    WK036203: "GM0362",
    WK036204: "GM0362",
    WK036205: "GM0362",
    WK036206: "GM0362",
    WK036207: "GM0362",
    WK036208: "GM0362",
    WK036209: "GM0362",
    WK036210: "GM0362",
    WK036211: "GM0362",
    WK036212: "GM0362",
    WK036213: "GM0362",
    WK036214: "GM0362",
    WK036300: "GM0363",
    WK036301: "GM0363",
    WK036302: "GM0363",
    WK036303: "GM0363",
    WK036304: "GM0363",
    WK036305: "GM0363",
    WK036306: "GM0363",
    WK036307: "GM0363",
    WK036308: "GM0363",
    WK036309: "GM0363",
    WK036310: "GM0363",
    WK036311: "GM0363",
    WK036312: "GM0363",
    WK036313: "GM0363",
    WK036314: "GM0363",
    WK036315: "GM0363",
    WK036316: "GM0363",
    WK036317: "GM0363",
    WK036318: "GM0363",
    WK036319: "GM0363",
    WK036320: "GM0363",
    WK036321: "GM0363",
    WK036322: "GM0363",
    WK036323: "GM0363",
    WK036324: "GM0363",
    WK036325: "GM0363",
    WK036326: "GM0363",
    WK036327: "GM0363",
    WK036328: "GM0363",
    WK036329: "GM0363",
    WK036330: "GM0363",
    WK036331: "GM0363",
    WK036332: "GM0363",
    WK036333: "GM0363",
    WK036334: "GM0363",
    WK036335: "GM0363",
    WK036336: "GM0363",
    WK036337: "GM0363",
    WK036338: "GM0363",
    WK036339: "GM0363",
    WK036340: "GM0363",
    WK036341: "GM0363",
    WK036342: "GM0363",
    WK036343: "GM0363",
    WK036344: "GM0363",
    WK036345: "GM0363",
    WK036346: "GM0363",
    WK036347: "GM0363",
    WK036348: "GM0363",
    WK036349: "GM0363",
    WK036350: "GM0363",
    WK036351: "GM0363",
    WK036352: "GM0363",
    WK036353: "GM0363",
    WK036354: "GM0363",
    WK036355: "GM0363",
    WK036356: "GM0363",
    WK036357: "GM0363",
    WK036358: "GM0363",
    WK036359: "GM0363",
    WK036360: "GM0363",
    WK036361: "GM0363",
    WK036362: "GM0363",
    WK036363: "GM0363",
    WK036364: "GM0363",
    WK036365: "GM0363",
    WK036366: "GM0363",
    WK036367: "GM0363",
    WK036368: "GM0363",
    WK036369: "GM0363",
    WK036370: "GM0363",
    WK036371: "GM0363",
    WK036372: "GM0363",
    WK036373: "GM0363",
    WK036374: "GM0363",
    WK036375: "GM0363",
    WK036376: "GM0363",
    WK036377: "GM0363",
    WK036378: "GM0363",
    WK036379: "GM0363",
    WK036380: "GM0363",
    WK036381: "GM0363",
    WK036382: "GM0363",
    WK036383: "GM0363",
    WK036384: "GM0363",
    WK036385: "GM0363",
    WK036386: "GM0363",
    WK036387: "GM0363",
    WK036388: "GM0363",
    WK036389: "GM0363",
    WK036390: "GM0363",
    WK036391: "GM0363",
    WK036392: "GM0363",
    WK036393: "GM0363",
    WK036394: "GM0363",
    WK036395: "GM0363",
    WK036396: "GM0363",
    WK036397: "GM0363",
    WK036398: "GM0363",
    WK037000: "GM0370",
    WK037001: "GM0370",
    WK037301: "GM0373",
    WK037302: "GM0373",
    WK037303: "GM0373",
    WK037304: "GM0373",
    WK037305: "GM0373",
    WK037306: "GM0373",
    WK037307: "GM0373",
    WK037500: "GM0375",
    WK037501: "GM0375",
    WK037502: "GM0375",
    WK037503: "GM0375",
    WK037504: "GM0375",
    WK037505: "GM0375",
    WK037506: "GM0375",
    WK037507: "GM0375",
    WK037508: "GM0375",
    WK037509: "GM0375",
    WK037510: "GM0375",
    WK037601: "GM0376",
    WK037700: "GM0377",
    WK037701: "GM0377",
    WK037702: "GM0377",
    WK037703: "GM0377",
    WK037704: "GM0377",
    WK038300: "GM0383",
    WK038301: "GM0383",
    WK038302: "GM0383",
    WK038303: "GM0383",
    WK038304: "GM0383",
    WK038305: "GM0383",
    WK038306: "GM0383",
    WK038307: "GM0383",
    WK038401: "GM0384",
    WK038402: "GM0384",
    WK038403: "GM0384",
    WK038404: "GM0384",
    WK038405: "GM0384",
    WK038406: "GM0384",
    WK038407: "GM0384",
    WK038408: "GM0384",
    WK038500: "GM0385",
    WK038501: "GM0385",
    WK038502: "GM0385",
    WK038503: "GM0385",
    WK038504: "GM0385",
    WK038505: "GM0385",
    WK038506: "GM0385",
    WK038507: "GM0385",
    WK038508: "GM0385",
    WK038509: "GM0385",
    WK038800: "GM0388",
    WK038801: "GM0388",
    WK039201: "GM0392",
    WK039202: "GM0392",
    WK039203: "GM0392",
    WK039204: "GM0392",
    WK039205: "GM0392",
    WK039206: "GM0392",
    WK039207: "GM0392",
    WK039208: "GM0392",
    WK039209: "GM0392",
    WK039210: "GM0392",
    WK039211: "GM0392",
    WK039212: "GM0392",
    WK039213: "GM0392",
    WK039214: "GM0392",
    WK039215: "GM0392",
    WK039216: "GM0392",
    WK039217: "GM0392",
    WK039218: "GM0392",
    WK039219: "GM0392",
    WK039220: "GM0392",
    WK039221: "GM0392",
    WK039401: "GM0394",
    WK039402: "GM0394",
    WK039403: "GM0394",
    WK039404: "GM0394",
    WK039405: "GM0394",
    WK039406: "GM0394",
    WK039407: "GM0394",
    WK039408: "GM0394",
    WK039409: "GM0394",
    WK039410: "GM0394",
    WK039411: "GM0394",
    WK039412: "GM0394",
    WK039413: "GM0394",
    WK039415: "GM0394",
    WK039416: "GM0394",
    WK039420: "GM0394",
    WK039421: "GM0394",
    WK039422: "GM0394",
    WK039601: "GM0396",
    WK039602: "GM0396",
    WK039603: "GM0396",
    WK039604: "GM0396",
    WK039605: "GM0396",
    WK039606: "GM0396",
    WK039607: "GM0396",
    WK039608: "GM0396",
    WK039609: "GM0396",
    WK039610: "GM0396",
    WK039611: "GM0396",
    WK039700: "GM0397",
    WK039701: "GM0397",
    WK039702: "GM0397",
    WK039801: "GM0398",
    WK039802: "GM0398",
    WK039804: "GM0398",
    WK039806: "GM0398",
    WK039807: "GM0398",
    WK039808: "GM0398",
    WK039809: "GM0398",
    WK039810: "GM0398",
    WK039811: "GM0398",
    WK039812: "GM0398",
    WK039813: "GM0398",
    WK039814: "GM0398",
    WK039815: "GM0398",
    WK039816: "GM0398",
    WK039817: "GM0398",
    WK039818: "GM0398",
    WK039819: "GM0398",
    WK039820: "GM0398",
    WK039830: "GM0398",
    WK039840: "GM0398",
    WK039850: "GM0398",
    WK039901: "GM0399",
    WK039902: "GM0399",
    WK039903: "GM0399",
    WK039904: "GM0399",
    WK039905: "GM0399",
    WK039906: "GM0399",
    WK039907: "GM0399",
    WK039908: "GM0399",
    WK040001: "GM0400",
    WK040002: "GM0400",
    WK040003: "GM0400",
    WK040004: "GM0400",
    WK040005: "GM0400",
    WK040006: "GM0400",
    WK040007: "GM0400",
    WK040008: "GM0400",
    WK040201: "GM0402",
    WK040202: "GM0402",
    WK040203: "GM0402",
    WK040204: "GM0402",
    WK040205: "GM0402",
    WK040206: "GM0402",
    WK040207: "GM0402",
    WK040208: "GM0402",
    WK040209: "GM0402",
    WK040510: "GM0405",
    WK040511: "GM0405",
    WK040512: "GM0405",
    WK040513: "GM0405",
    WK040520: "GM0405",
    WK040521: "GM0405",
    WK040522: "GM0405",
    WK040530: "GM0405",
    WK040531: "GM0405",
    WK040532: "GM0405",
    WK040533: "GM0405",
    WK040534: "GM0405",
    WK040535: "GM0405",
    WK040536: "GM0405",
    WK040600: "GM0406",
    WK040601: "GM0406",
    WK040602: "GM0406",
    WK040603: "GM0406",
    WK040604: "GM0406",
    WK040605: "GM0406",
    WK040606: "GM0406",
    WK040607: "GM0406",
    WK040608: "GM0406",
    WK040609: "GM0406",
    WK040610: "GM0406",
    WK040611: "GM0406",
    WK040612: "GM0406",
    WK041500: "GM0415",
    WK041501: "GM0415",
    WK041600: "GM0416",
    WK041703: "GM0417",
    WK042001: "GM0420",
    WK042002: "GM0420",
    WK042003: "GM0420",
    WK042004: "GM0420",
    WK042005: "GM0420",
    WK042006: "GM0420",
    WK042007: "GM0420",
    WK042008: "GM0420",
    WK042009: "GM0420",
    WK042010: "GM0420",
    WK042011: "GM0420",
    WK042012: "GM0420",
    WK042013: "GM0420",
    WK042014: "GM0420",
    WK042015: "GM0420",
    WK042016: "GM0420",
    WK043100: "GM0431",
    WK043200: "GM0432",
    WK043201: "GM0432",
    WK043202: "GM0432",
    WK043700: "GM0437",
    WK043901: "GM0439",
    WK043902: "GM0439",
    WK043903: "GM0439",
    WK043904: "GM0439",
    WK043905: "GM0439",
    WK043906: "GM0439",
    WK043907: "GM0439",
    WK044800: "GM0448",
    WK044801: "GM0448",
    WK045000: "GM0450",
    WK045115: "GM0451",
    WK045125: "GM0451",
    WK045135: "GM0451",
    WK045145: "GM0451",
    WK045150: "GM0451",
    WK045155: "GM0451",
    WK045165: "GM0451",
    WK045175: "GM0451",
    WK045185: "GM0451",
    WK045190: "GM0451",
    WK045195: "GM0451",
    WK045300: "GM0453",
    WK045301: "GM0453",
    WK045302: "GM0453",
    WK045303: "GM0453",
    WK045304: "GM0453",
    WK045305: "GM0453",
    WK045306: "GM0453",
    WK045307: "GM0453",
    WK045308: "GM0453",
    WK045309: "GM0453",
    WK045700: "GM0457",
    WK045701: "GM0457",
    WK045702: "GM0457",
    WK045703: "GM0457",
    WK045704: "GM0457",
    WK045707: "GM0457",
    WK045708: "GM0457",
    WK045709: "GM0457",
    WK047301: "GM0473",
    WK047302: "GM0473",
    WK047303: "GM0473",
    WK047304: "GM0473",
    WK047911: "GM0479",
    WK047912: "GM0479",
    WK047913: "GM0479",
    WK047914: "GM0479",
    WK047915: "GM0479",
    WK047916: "GM0479",
    WK047921: "GM0479",
    WK047922: "GM0479",
    WK047923: "GM0479",
    WK047931: "GM0479",
    WK047932: "GM0479",
    WK047941: "GM0479",
    WK047942: "GM0479",
    WK047951: "GM0479",
    WK047961: "GM0479",
    WK047962: "GM0479",
    WK047971: "GM0479",
    WK047972: "GM0479",
    WK047981: "GM0479",
    WK048201: "GM0482",
    WK048202: "GM0482",
    WK048203: "GM0482",
    WK048204: "GM0482",
    WK048205: "GM0482",
    WK048206: "GM0482",
    WK048401: "GM0484",
    WK048402: "GM0484",
    WK048403: "GM0484",
    WK048404: "GM0484",
    WK048405: "GM0484",
    WK048406: "GM0484",
    WK048407: "GM0484",
    WK048408: "GM0484",
    WK048409: "GM0484",
    WK048431: "GM0484",
    WK048445: "GM0484",
    WK048470: "GM0484",
    WK048471: "GM0484",
    WK048491: "GM0484",
    WK048494: "GM0484",
    WK048496: "GM0484",
    WK048901: "GM0489",
    WK048902: "GM0489",
    WK048903: "GM0489",
    WK048904: "GM0489",
    WK048905: "GM0489",
    WK048906: "GM0489",
    WK048907: "GM0489",
    WK048908: "GM0489",
    WK048909: "GM0489",
    WK048910: "GM0489",
    WK048911: "GM0489",
    WK048912: "GM0489",
    WK048913: "GM0489",
    WK048914: "GM0489",
    WK048915: "GM0489",
    WK048916: "GM0489",
    WK048917: "GM0489",
    WK048918: "GM0489",
    WK048919: "GM0489",
    WK048920: "GM0489",
    WK048930: "GM0489",
    WK048931: "GM0489",
    WK048950: "GM0489",
    WK049800: "GM0498",
    WK049801: "GM0498",
    WK049802: "GM0498",
    WK049803: "GM0498",
    WK049804: "GM0498",
    WK049805: "GM0498",
    WK050100: "GM0501",
    WK050101: "GM0501",
    WK050102: "GM0501",
    WK050103: "GM0501",
    WK050201: "GM0502",
    WK050202: "GM0502",
    WK050203: "GM0502",
    WK050204: "GM0502",
    WK050205: "GM0502",
    WK050206: "GM0502",
    WK050207: "GM0502",
    WK050208: "GM0502",
    WK050209: "GM0502",
    WK050311: "GM0503",
    WK050312: "GM0503",
    WK050313: "GM0503",
    WK050314: "GM0503",
    WK050316: "GM0503",
    WK050322: "GM0503",
    WK050323: "GM0503",
    WK050324: "GM0503",
    WK050325: "GM0503",
    WK050326: "GM0503",
    WK050327: "GM0503",
    WK050328: "GM0503",
    WK050329: "GM0503",
    WK050501: "GM0505",
    WK050502: "GM0505",
    WK050503: "GM0505",
    WK050504: "GM0505",
    WK050505: "GM0505",
    WK050506: "GM0505",
    WK050507: "GM0505",
    WK050508: "GM0505",
    WK050509: "GM0505",
    WK050510: "GM0505",
    WK050511: "GM0505",
    WK050519: "GM0505",
    WK050598: "GM0505",
    WK051201: "GM0512",
    WK051202: "GM0512",
    WK051203: "GM0512",
    WK051204: "GM0512",
    WK051205: "GM0512",
    WK051206: "GM0512",
    WK051207: "GM0512",
    WK051208: "GM0512",
    WK051209: "GM0512",
    WK051210: "GM0512",
    WK051211: "GM0512",
    WK051212: "GM0512",
    WK051213: "GM0512",
    WK051214: "GM0512",
    WK051215: "GM0512",
    WK051216: "GM0512",
    WK051217: "GM0512",
    WK051218: "GM0512",
    WK051219: "GM0512",
    WK051220: "GM0512",
    WK051221: "GM0512",
    WK051301: "GM0513",
    WK051302: "GM0513",
    WK051303: "GM0513",
    WK051304: "GM0513",
    WK051305: "GM0513",
    WK051306: "GM0513",
    WK051307: "GM0513",
    WK051308: "GM0513",
    WK051309: "GM0513",
    WK051801: "GM0518",
    WK051802: "GM0518",
    WK051803: "GM0518",
    WK051804: "GM0518",
    WK051805: "GM0518",
    WK051806: "GM0518",
    WK051807: "GM0518",
    WK051808: "GM0518",
    WK051809: "GM0518",
    WK051810: "GM0518",
    WK051811: "GM0518",
    WK051812: "GM0518",
    WK051813: "GM0518",
    WK051814: "GM0518",
    WK051815: "GM0518",
    WK051816: "GM0518",
    WK051817: "GM0518",
    WK051818: "GM0518",
    WK051819: "GM0518",
    WK051820: "GM0518",
    WK051821: "GM0518",
    WK051822: "GM0518",
    WK051823: "GM0518",
    WK051824: "GM0518",
    WK051825: "GM0518",
    WK051826: "GM0518",
    WK051827: "GM0518",
    WK051828: "GM0518",
    WK051829: "GM0518",
    WK051830: "GM0518",
    WK051831: "GM0518",
    WK051832: "GM0518",
    WK051833: "GM0518",
    WK051834: "GM0518",
    WK051835: "GM0518",
    WK051836: "GM0518",
    WK051837: "GM0518",
    WK051838: "GM0518",
    WK051839: "GM0518",
    WK051840: "GM0518",
    WK051841: "GM0518",
    WK051842: "GM0518",
    WK051843: "GM0518",
    WK051844: "GM0518",
    WK052300: "GM0523",
    WK053000: "GM0530",
    WK053001: "GM0530",
    WK053002: "GM0530",
    WK053003: "GM0530",
    WK053004: "GM0530",
    WK053005: "GM0530",
    WK053006: "GM0530",
    WK053007: "GM0530",
    WK053008: "GM0530",
    WK053009: "GM0530",
    WK053010: "GM0530",
    WK053101: "GM0531",
    WK053102: "GM0531",
    WK053103: "GM0531",
    WK053104: "GM0531",
    WK053105: "GM0531",
    WK053200: "GM0532",
    WK053201: "GM0532",
    WK053401: "GM0534",
    WK053402: "GM0534",
    WK053403: "GM0534",
    WK053404: "GM0534",
    WK053405: "GM0534",
    WK053701: "GM0537",
    WK053702: "GM0537",
    WK053703: "GM0537",
    WK053705: "GM0537",
    WK053706: "GM0537",
    WK053707: "GM0537",
    WK053708: "GM0537",
    WK054200: "GM0542",
    WK054600: "GM0546",
    WK054601: "GM0546",
    WK054602: "GM0546",
    WK054603: "GM0546",
    WK054604: "GM0546",
    WK054605: "GM0546",
    WK054606: "GM0546",
    WK054607: "GM0546",
    WK054608: "GM0546",
    WK054609: "GM0546",
    WK054700: "GM0547",
    WK054701: "GM0547",
    WK054702: "GM0547",
    WK055301: "GM0553",
    WK055302: "GM0553",
    WK055303: "GM0553",
    WK055304: "GM0553",
    WK055601: "GM0556",
    WK055602: "GM0556",
    WK055603: "GM0556",
    WK055604: "GM0556",
    WK055605: "GM0556",
    WK055606: "GM0556",
    WK055607: "GM0556",
    WK055608: "GM0556",
    WK056900: "GM0569",
    WK056901: "GM0569",
    WK056902: "GM0569",
    WK056903: "GM0569",
    WK057500: "GM0575",
    WK057501: "GM0575",
    WK057502: "GM0575",
    WK057900: "GM0579",
    WK058901: "GM0589",
    WK058902: "GM0589",
    WK058903: "GM0589",
    WK058904: "GM0589",
    WK059000: "GM0590",
    WK059701: "GM0597",
    WK059702: "GM0597",
    WK059703: "GM0597",
    WK059704: "GM0597",
    WK059705: "GM0597",
    WK059706: "GM0597",
    WK059707: "GM0597",
    WK059708: "GM0597",
    WK059709: "GM0597",
    WK059710: "GM0597",
    WK059711: "GM0597",
    WK059712: "GM0597",
    WK060301: "GM0603",
    WK060302: "GM0603",
    WK060303: "GM0603",
    WK060304: "GM0603",
    WK060305: "GM0603",
    WK060306: "GM0603",
    WK060307: "GM0603",
    WK060308: "GM0603",
    WK060309: "GM0603",
    WK060310: "GM0603",
    WK060311: "GM0603",
    WK060600: "GM0606",
    WK060601: "GM0606",
    WK060602: "GM0606",
    WK060603: "GM0606",
    WK060604: "GM0606",
    WK060605: "GM0606",
    WK060606: "GM0606",
    WK060607: "GM0606",
    WK060608: "GM0606",
    WK060609: "GM0606",
    WK061001: "GM0610",
    WK061002: "GM0610",
    WK061003: "GM0610",
    WK061004: "GM0610",
    WK061301: "GM0613",
    WK061302: "GM0613",
    WK061303: "GM0613",
    WK061304: "GM0613",
    WK061305: "GM0613",
    WK061306: "GM0613",
    WK061307: "GM0613",
    WK061400: "GM0614",
    WK061401: "GM0614",
    WK062201: "GM0622",
    WK062202: "GM0622",
    WK062203: "GM0622",
    WK062204: "GM0622",
    WK062205: "GM0622",
    WK062206: "GM0622",
    WK062207: "GM0622",
    WK062208: "GM0622",
    WK062600: "GM0626",
    WK062701: "GM0627",
    WK062702: "GM0627",
    WK062703: "GM0627",
    WK062704: "GM0627",
    WK062900: "GM0629",
    WK062901: "GM0629",
    WK063201: "GM0632",
    WK063202: "GM0632",
    WK063203: "GM0632",
    WK063204: "GM0632",
    WK063205: "GM0632",
    WK063206: "GM0632",
    WK063207: "GM0632",
    WK063208: "GM0632",
    WK063700: "GM0637",
    WK063701: "GM0637",
    WK063702: "GM0637",
    WK063703: "GM0637",
    WK063704: "GM0637",
    WK063705: "GM0637",
    WK063706: "GM0637",
    WK063708: "GM0637",
    WK063709: "GM0637",
    WK063800: "GM0638",
    WK063801: "GM0638",
    WK063802: "GM0638",
    WK063803: "GM0638",
    WK063804: "GM0638",
    WK063809: "GM0638",
    WK063810: "GM0638",
    WK064201: "GM0642",
    WK064202: "GM0642",
    WK064203: "GM0642",
    WK064204: "GM0642",
    WK064205: "GM0642",
    WK064206: "GM0642",
    WK064207: "GM0642",
    WK064208: "GM0642",
    WK064209: "GM0642",
    WK065401: "GM0654",
    WK065402: "GM0654",
    WK065403: "GM0654",
    WK065404: "GM0654",
    WK065405: "GM0654",
    WK065406: "GM0654",
    WK065407: "GM0654",
    WK065408: "GM0654",
    WK065409: "GM0654",
    WK065410: "GM0654",
    WK065411: "GM0654",
    WK065412: "GM0654",
    WK065413: "GM0654",
    WK065414: "GM0654",
    WK065415: "GM0654",
    WK066401: "GM0664",
    WK066402: "GM0664",
    WK066403: "GM0664",
    WK066404: "GM0664",
    WK066405: "GM0664",
    WK066406: "GM0664",
    WK066407: "GM0664",
    WK066408: "GM0664",
    WK066409: "GM0664",
    WK066801: "GM0668",
    WK066802: "GM0668",
    WK066803: "GM0668",
    WK066804: "GM0668",
    WK066805: "GM0668",
    WK066806: "GM0668",
    WK066807: "GM0668",
    WK066808: "GM0668",
    WK067701: "GM0677",
    WK067702: "GM0677",
    WK067703: "GM0677",
    WK067704: "GM0677",
    WK067705: "GM0677",
    WK067706: "GM0677",
    WK067707: "GM0677",
    WK067708: "GM0677",
    WK067709: "GM0677",
    WK067710: "GM0677",
    WK067711: "GM0677",
    WK067712: "GM0677",
    WK067713: "GM0677",
    WK067714: "GM0677",
    WK067715: "GM0677",
    WK067800: "GM0678",
    WK067801: "GM0678",
    WK067802: "GM0678",
    WK070300: "GM0703",
    WK070301: "GM0703",
    WK070302: "GM0703",
    WK070303: "GM0703",
    WK070304: "GM0703",
    WK070305: "GM0703",
    WK070306: "GM0703",
    WK071501: "GM0715",
    WK071504: "GM0715",
    WK071507: "GM0715",
    WK071510: "GM0715",
    WK071513: "GM0715",
    WK071516: "GM0715",
    WK071519: "GM0715",
    WK071522: "GM0715",
    WK071525: "GM0715",
    WK071528: "GM0715",
    WK071531: "GM0715",
    WK071534: "GM0715",
    WK071537: "GM0715",
    WK071540: "GM0715",
    WK071543: "GM0715",
    WK071546: "GM0715",
    WK071549: "GM0715",
    WK071552: "GM0715",
    WK071555: "GM0715",
    WK071558: "GM0715",
    WK071561: "GM0715",
    WK071564: "GM0715",
    WK071567: "GM0715",
    WK071570: "GM0715",
    WK071573: "GM0715",
    WK071576: "GM0715",
    WK071579: "GM0715",
    WK071582: "GM0715",
    WK071585: "GM0715",
    WK071588: "GM0715",
    WK071600: "GM0716",
    WK071601: "GM0716",
    WK071602: "GM0716",
    WK071603: "GM0716",
    WK071604: "GM0716",
    WK071605: "GM0716",
    WK071606: "GM0716",
    WK071607: "GM0716",
    WK071700: "GM0717",
    WK071701: "GM0717",
    WK071702: "GM0717",
    WK071703: "GM0717",
    WK071704: "GM0717",
    WK071705: "GM0717",
    WK071706: "GM0717",
    WK071707: "GM0717",
    WK071708: "GM0717",
    WK071709: "GM0717",
    WK071710: "GM0717",
    WK071711: "GM0717",
    WK071712: "GM0717",
    WK071801: "GM0718",
    WK071802: "GM0718",
    WK071803: "GM0718",
    WK071804: "GM0718",
    WK071805: "GM0718",
    WK071806: "GM0718",
    WK071807: "GM0718",
    WK071808: "GM0718",
    WK073601: "GM0736",
    WK073602: "GM0736",
    WK073603: "GM0736",
    WK073604: "GM0736",
    WK073605: "GM0736",
    WK073606: "GM0736",
    WK073607: "GM0736",
    WK073608: "GM0736",
    WK073700: "GM0737",
    WK073701: "GM0737",
    WK073702: "GM0737",
    WK073703: "GM0737",
    WK073704: "GM0737",
    WK073705: "GM0737",
    WK073706: "GM0737",
    WK073707: "GM0737",
    WK073708: "GM0737",
    WK073709: "GM0737",
    WK074400: "GM0744",
    WK074401: "GM0744",
    WK074402: "GM0744",
    WK074800: "GM0748",
    WK074801: "GM0748",
    WK074802: "GM0748",
    WK074803: "GM0748",
    WK074804: "GM0748",
    WK074805: "GM0748",
    WK075500: "GM0755",
    WK075501: "GM0755",
    WK075600: "GM0756",
    WK075601: "GM0756",
    WK075602: "GM0756",
    WK075603: "GM0756",
    WK075604: "GM0756",
    WK075605: "GM0756",
    WK075606: "GM0756",
    WK075607: "GM0756",
    WK075608: "GM0756",
    WK075609: "GM0756",
    WK075700: "GM0757",
    WK075701: "GM0757",
    WK075702: "GM0757",
    WK076200: "GM0762",
    WK076201: "GM0762",
    WK076202: "GM0762",
    WK076203: "GM0762",
    WK076204: "GM0762",
    WK076500: "GM0765",
    WK076501: "GM0765",
    WK076601: "GM0766",
    WK076602: "GM0766",
    WK077000: "GM0770",
    WK077001: "GM0770",
    WK077002: "GM0770",
    WK077003: "GM0770",
    WK077004: "GM0770",
    WK077005: "GM0770",
    WK077211: "GM0772",
    WK077221: "GM0772",
    WK077222: "GM0772",
    WK077223: "GM0772",
    WK077231: "GM0772",
    WK077232: "GM0772",
    WK077233: "GM0772",
    WK077241: "GM0772",
    WK077242: "GM0772",
    WK077243: "GM0772",
    WK077251: "GM0772",
    WK077252: "GM0772",
    WK077253: "GM0772",
    WK077254: "GM0772",
    WK077261: "GM0772",
    WK077262: "GM0772",
    WK077263: "GM0772",
    WK077271: "GM0772",
    WK077272: "GM0772",
    WK077273: "GM0772",
    WK077900: "GM0779",
    WK077901: "GM0779",
    WK077902: "GM0779",
    WK078400: "GM0784",
    WK078401: "GM0784",
    WK078402: "GM0784",
    WK078403: "GM0784",
    WK078501: "GM0785",
    WK078502: "GM0785",
    WK078503: "GM0785",
    WK078504: "GM0785",
    WK078505: "GM0785",
    WK078506: "GM0785",
    WK078507: "GM0785",
    WK078508: "GM0785",
    WK078509: "GM0785",
    WK078510: "GM0785",
    WK078511: "GM0785",
    WK078512: "GM0785",
    WK078513: "GM0785",
    WK078514: "GM0785",
    WK078520: "GM0785",
    WK078521: "GM0785",
    WK078522: "GM0785",
    WK078523: "GM0785",
    WK078605: "GM0786",
    WK078607: "GM0786",
    WK078608: "GM0786",
    WK078613: "GM0786",
    WK078801: "GM0788",
    WK078802: "GM0788",
    WK078803: "GM0788",
    WK078804: "GM0788",
    WK079410: "GM0794",
    WK079411: "GM0794",
    WK079412: "GM0794",
    WK079413: "GM0794",
    WK079414: "GM0794",
    WK079415: "GM0794",
    WK079416: "GM0794",
    WK079417: "GM0794",
    WK079418: "GM0794",
    WK079419: "GM0794",
    WK079421: "GM0794",
    WK079429: "GM0794",
    WK079601: "GM0796",
    WK079602: "GM0796",
    WK079603: "GM0796",
    WK079604: "GM0796",
    WK079605: "GM0796",
    WK079606: "GM0796",
    WK079607: "GM0796",
    WK079608: "GM0796",
    WK079609: "GM0796",
    WK079610: "GM0796",
    WK079611: "GM0796",
    WK079612: "GM0796",
    WK079613: "GM0796",
    WK079614: "GM0796",
    WK079701: "GM0797",
    WK079702: "GM0797",
    WK079703: "GM0797",
    WK079704: "GM0797",
    WK079705: "GM0797",
    WK079706: "GM0797",
    WK079707: "GM0797",
    WK079708: "GM0797",
    WK079709: "GM0797",
    WK079710: "GM0797",
    WK079711: "GM0797",
    WK079800: "GM0798",
    WK079801: "GM0798",
    WK079802: "GM0798",
    WK079803: "GM0798",
    WK079804: "GM0798",
    WK080910: "GM0809",
    WK080920: "GM0809",
    WK080930: "GM0809",
    WK080940: "GM0809",
    WK081509: "GM0815",
    WK081510: "GM0815",
    WK081512: "GM0815",
    WK081515: "GM0815",
    WK082000: "GM0820",
    WK082001: "GM0820",
    WK082002: "GM0820",
    WK082300: "GM0823",
    WK082301: "GM0823",
    WK082302: "GM0823",
    WK082303: "GM0823",
    WK082401: "GM0824",
    WK082402: "GM0824",
    WK082403: "GM0824",
    WK082404: "GM0824",
    WK082405: "GM0824",
    WK082406: "GM0824",
    WK082407: "GM0824",
    WK082408: "GM0824",
    WK082409: "GM0824",
    WK082410: "GM0824",
    WK082411: "GM0824",
    WK082412: "GM0824",
    WK082413: "GM0824",
    WK082414: "GM0824",
    WK082415: "GM0824",
    WK082600: "GM0826",
    WK082601: "GM0826",
    WK082602: "GM0826",
    WK082603: "GM0826",
    WK082604: "GM0826",
    WK082605: "GM0826",
    WK082606: "GM0826",
    WK082607: "GM0826",
    WK082608: "GM0826",
    WK082609: "GM0826",
    WK082610: "GM0826",
    WK082611: "GM0826",
    WK082612: "GM0826",
    WK082613: "GM0826",
    WK084000: "GM0840",
    WK084001: "GM0840",
    WK084002: "GM0840",
    WK084003: "GM0840",
    WK084004: "GM0840",
    WK084500: "GM0845",
    WK084501: "GM0845",
    WK084502: "GM0845",
    WK084503: "GM0845",
    WK084504: "GM0845",
    WK084700: "GM0847",
    WK084701: "GM0847",
    WK084702: "GM0847",
    WK084703: "GM0847",
    WK084709: "GM0847",
    WK084800: "GM0848",
    WK084801: "GM0848",
    WK085100: "GM0851",
    WK085101: "GM0851",
    WK085102: "GM0851",
    WK085103: "GM0851",
    WK085104: "GM0851",
    WK085200: "GM0852",
    WK085201: "GM0852",
    WK085202: "GM0852",
    WK085203: "GM0852",
    WK085204: "GM0852",
    WK085205: "GM0852",
    WK085510: "GM0855",
    WK085511: "GM0855",
    WK085512: "GM0855",
    WK085513: "GM0855",
    WK085514: "GM0855",
    WK085515: "GM0855",
    WK085516: "GM0855",
    WK085517: "GM0855",
    WK085518: "GM0855",
    WK085519: "GM0855",
    WK085520: "GM0855",
    WK085521: "GM0855",
    WK085522: "GM0855",
    WK085523: "GM0855",
    WK085524: "GM0855",
    WK085525: "GM0855",
    WK085526: "GM0855",
    WK085527: "GM0855",
    WK085528: "GM0855",
    WK085529: "GM0855",
    WK085530: "GM0855",
    WK085531: "GM0855",
    WK085532: "GM0855",
    WK085533: "GM0855",
    WK085534: "GM0855",
    WK085535: "GM0855",
    WK085536: "GM0855",
    WK085537: "GM0855",
    WK085538: "GM0855",
    WK085539: "GM0855",
    WK085540: "GM0855",
    WK085541: "GM0855",
    WK085542: "GM0855",
    WK085543: "GM0855",
    WK085544: "GM0855",
    WK085545: "GM0855",
    WK085546: "GM0855",
    WK085547: "GM0855",
    WK085548: "GM0855",
    WK085549: "GM0855",
    WK085550: "GM0855",
    WK085551: "GM0855",
    WK085552: "GM0855",
    WK085553: "GM0855",
    WK085554: "GM0855",
    WK085555: "GM0855",
    WK085556: "GM0855",
    WK085557: "GM0855",
    WK085558: "GM0855",
    WK085559: "GM0855",
    WK085560: "GM0855",
    WK085561: "GM0855",
    WK085562: "GM0855",
    WK085563: "GM0855",
    WK085564: "GM0855",
    WK085565: "GM0855",
    WK085566: "GM0855",
    WK085567: "GM0855",
    WK085601: "GM0856",
    WK085602: "GM0856",
    WK085603: "GM0856",
    WK085800: "GM0858",
    WK085801: "GM0858",
    WK085802: "GM0858",
    WK086100: "GM0861",
    WK086101: "GM0861",
    WK086102: "GM0861",
    WK086500: "GM0865",
    WK086501: "GM0865",
    WK086502: "GM0865",
    WK086600: "GM0866",
    WK086700: "GM0867",
    WK086701: "GM0867",
    WK086702: "GM0867",
    WK087300: "GM0873",
    WK087301: "GM0873",
    WK087302: "GM0873",
    WK087303: "GM0873",
    WK087900: "GM0879",
    WK087901: "GM0879",
    WK087902: "GM0879",
    WK087903: "GM0879",
    WK087904: "GM0879",
    WK088000: "GM0880",
    WK088001: "GM0880",
    WK088002: "GM0880",
    WK088200: "GM0882",
    WK088201: "GM0882",
    WK088202: "GM0882",
    WK088800: "GM0888",
    WK088801: "GM0888",
    WK088900: "GM0889",
    WK088901: "GM0889",
    WK089301: "GM0893",
    WK089302: "GM0893",
    WK089303: "GM0893",
    WK089304: "GM0893",
    WK089305: "GM0893",
    WK089306: "GM0893",
    WK089307: "GM0893",
    WK089901: "GM0899",
    WK089902: "GM0899",
    WK089903: "GM0899",
    WK089904: "GM0899",
    WK089905: "GM0899",
    WK090700: "GM0907",
    WK090701: "GM0907",
    WK090702: "GM0907",
    WK090703: "GM0907",
    WK090704: "GM0907",
    WK091710: "GM0917",
    WK091711: "GM0917",
    WK091712: "GM0917",
    WK091713: "GM0917",
    WK091714: "GM0917",
    WK091720: "GM0917",
    WK091721: "GM0917",
    WK091722: "GM0917",
    WK091723: "GM0917",
    WK091724: "GM0917",
    WK091730: "GM0917",
    WK091731: "GM0917",
    WK091732: "GM0917",
    WK091733: "GM0917",
    WK091734: "GM0917",
    WK091735: "GM0917",
    WK091736: "GM0917",
    WK091737: "GM0917",
    WK091738: "GM0917",
    WK091739: "GM0917",
    WK091740: "GM0917",
    WK091741: "GM0917",
    WK091742: "GM0917",
    WK092800: "GM0928",
    WK092801: "GM0928",
    WK092802: "GM0928",
    WK093500: "GM0935",
    WK093501: "GM0935",
    WK093502: "GM0935",
    WK093503: "GM0935",
    WK093504: "GM0935",
    WK093505: "GM0935",
    WK093506: "GM0935",
    WK093800: "GM0938",
    WK093801: "GM0938",
    WK093802: "GM0938",
    WK094400: "GM0944",
    WK094401: "GM0944",
    WK094601: "GM0946",
    WK094602: "GM0946",
    WK094603: "GM0946",
    WK094604: "GM0946",
    WK094605: "GM0946",
    WK095700: "GM0957",
    WK095701: "GM0957",
    WK095702: "GM0957",
    WK095703: "GM0957",
    WK095704: "GM0957",
    WK095705: "GM0957",
    WK095706: "GM0957",
    WK095707: "GM0957",
    WK095708: "GM0957",
    WK095709: "GM0957",
    WK096500: "GM0965",
    WK096501: "GM0965",
    WK097100: "GM0971",
    WK097101: "GM0971",
    WK097102: "GM0971",
    WK098100: "GM0981",
    WK098101: "GM0981",
    WK098311: "GM0983",
    WK098312: "GM0983",
    WK098313: "GM0983",
    WK098314: "GM0983",
    WK098315: "GM0983",
    WK098316: "GM0983",
    WK098321: "GM0983",
    WK098322: "GM0983",
    WK098323: "GM0983",
    WK098324: "GM0983",
    WK098325: "GM0983",
    WK098326: "GM0983",
    WK098327: "GM0983",
    WK098328: "GM0983",
    WK098331: "GM0983",
    WK098332: "GM0983",
    WK098335: "GM0983",
    WK098341: "GM0983",
    WK098343: "GM0983",
    WK098344: "GM0983",
    WK098351: "GM0983",
    WK098401: "GM0984",
    WK098402: "GM0984",
    WK098403: "GM0984",
    WK098404: "GM0984",
    WK098405: "GM0984",
    WK098406: "GM0984",
    WK098407: "GM0984",
    WK098408: "GM0984",
    WK098409: "GM0984",
    WK098410: "GM0984",
    WK098411: "GM0984",
    WK098412: "GM0984",
    WK098413: "GM0984",
    WK098414: "GM0984",
    WK098415: "GM0984",
    WK098416: "GM0984",
    WK098417: "GM0984",
    WK098418: "GM0984",
    WK098419: "GM0984",
    WK098420: "GM0984",
    WK098421: "GM0984",
    WK098422: "GM0984",
    WK098423: "GM0984",
    WK098601: "GM0986",
    WK098602: "GM0986",
    WK098603: "GM0986",
    WK098604: "GM0986",
    WK098605: "GM0986",
    WK098609: "GM0986",
    WK098801: "GM0988",
    WK098802: "GM0988",
    WK098803: "GM0988",
    WK098811: "GM0988",
    WK098812: "GM0988",
    WK098813: "GM0988",
    WK098814: "GM0988",
    WK098821: "GM0988",
    WK098822: "GM0988",
    WK098823: "GM0988",
    WK098824: "GM0988",
    WK098825: "GM0988",
    WK098831: "GM0988",
    WK098832: "GM0988",
    WK098833: "GM0988",
    WK098834: "GM0988",
    WK099400: "GM0994",
    WK099401: "GM0994",
    WK099402: "GM0994",
    WK099403: "GM0994",
    WK099404: "GM0994",
    WK099405: "GM0994",
    WK150700: "GM1507",
    WK150701: "GM1507",
    WK150702: "GM1507",
    WK150703: "GM1507",
    WK150704: "GM1507",
    WK150705: "GM1507",
    WK150706: "GM1507",
    WK150707: "GM1507",
    WK150708: "GM1507",
    WK150709: "GM1507",
    WK150710: "GM1507",
    WK150711: "GM1507",
    WK150712: "GM1507",
    WK150713: "GM1507",
    WK150714: "GM1507",
    WK150715: "GM1507",
    WK150900: "GM1509",
    WK150901: "GM1509",
    WK150902: "GM1509",
    WK150903: "GM1509",
    WK158100: "GM1581",
    WK158101: "GM1581",
    WK158102: "GM1581",
    WK158103: "GM1581",
    WK158104: "GM1581",
    WK158601: "GM1586",
    WK158602: "GM1586",
    WK158603: "GM1586",
    WK158604: "GM1586",
    WK158605: "GM1586",
    WK158606: "GM1586",
    WK158607: "GM1586",
    WK159801: "GM1598",
    WK159802: "GM1598",
    WK159803: "GM1598",
    WK159804: "GM1598",
    WK159805: "GM1598",
    WK159806: "GM1598",
    WK159807: "GM1598",
    WK159808: "GM1598",
    WK159809: "GM1598",
    WK159810: "GM1598",
    WK164000: "GM1640",
    WK164001: "GM1640",
    WK164002: "GM1640",
    WK164003: "GM1640",
    WK164004: "GM1640",
    WK164005: "GM1640",
    WK164006: "GM1640",
    WK164007: "GM1640",
    WK164008: "GM1640",
    WK164009: "GM1640",
    WK164010: "GM1640",
    WK164011: "GM1640",
    WK164012: "GM1640",
    WK164013: "GM1640",
    WK164014: "GM1640",
    WK164015: "GM1640",
    WK164100: "GM1641",
    WK164101: "GM1641",
    WK164102: "GM1641",
    WK164103: "GM1641",
    WK164104: "GM1641",
    WK164105: "GM1641",
    WK164106: "GM1641",
    WK164107: "GM1641",
    WK165200: "GM1652",
    WK165201: "GM1652",
    WK165202: "GM1652",
    WK165203: "GM1652",
    WK165204: "GM1652",
    WK165205: "GM1652",
    WK165206: "GM1652",
    WK165501: "GM1655",
    WK165502: "GM1655",
    WK165503: "GM1655",
    WK165504: "GM1655",
    WK165505: "GM1655",
    WK165800: "GM1658",
    WK165801: "GM1658",
    WK165802: "GM1658",
    WK165803: "GM1658",
    WK165900: "GM1659",
    WK165901: "GM1659",
    WK165902: "GM1659",
    WK165903: "GM1659",
    WK166700: "GM1667",
    WK166701: "GM1667",
    WK166702: "GM1667",
    WK166703: "GM1667",
    WK166901: "GM1669",
    WK166902: "GM1669",
    WK166903: "GM1669",
    WK166904: "GM1669",
    WK166905: "GM1669",
    WK166906: "GM1669",
    WK167600: "GM1676",
    WK167601: "GM1676",
    WK167602: "GM1676",
    WK167603: "GM1676",
    WK167604: "GM1676",
    WK167605: "GM1676",
    WK167606: "GM1676",
    WK167607: "GM1676",
    WK167608: "GM1676",
    WK167609: "GM1676",
    WK167610: "GM1676",
    WK167611: "GM1676",
    WK167612: "GM1676",
    WK167613: "GM1676",
    WK167614: "GM1676",
    WK167615: "GM1676",
    WK168000: "GM1680",
    WK168001: "GM1680",
    WK168002: "GM1680",
    WK168003: "GM1680",
    WK168004: "GM1680",
    WK168005: "GM1680",
    WK168006: "GM1680",
    WK168007: "GM1680",
    WK168008: "GM1680",
    WK168009: "GM1680",
    WK168011: "GM1680",
    WK168012: "GM1680",
    WK168013: "GM1680",
    WK168014: "GM1680",
    WK168015: "GM1680",
    WK168016: "GM1680",
    WK168017: "GM1680",
    WK168018: "GM1680",
    WK168019: "GM1680",
    WK168020: "GM1680",
    WK168021: "GM1680",
    WK168100: "GM1681",
    WK168101: "GM1681",
    WK168102: "GM1681",
    WK168103: "GM1681",
    WK168104: "GM1681",
    WK168105: "GM1681",
    WK168106: "GM1681",
    WK168107: "GM1681",
    WK168108: "GM1681",
    WK168109: "GM1681",
    WK168110: "GM1681",
    WK168111: "GM1681",
    WK168112: "GM1681",
    WK168113: "GM1681",
    WK168114: "GM1681",
    WK168115: "GM1681",
    WK168116: "GM1681",
    WK168401: "GM1684",
    WK168402: "GM1684",
    WK168403: "GM1684",
    WK168404: "GM1684",
    WK168406: "GM1684",
    WK168411: "GM1684",
    WK168414: "GM1684",
    WK168500: "GM1685",
    WK168501: "GM1685",
    WK168502: "GM1685",
    WK168509: "GM1685",
    WK169000: "GM1690",
    WK169001: "GM1690",
    WK169002: "GM1690",
    WK169003: "GM1690",
    WK169004: "GM1690",
    WK169005: "GM1690",
    WK169006: "GM1690",
    WK169007: "GM1690",
    WK169008: "GM1690",
    WK169009: "GM1690",
    WK169010: "GM1690",
    WK169011: "GM1690",
    WK169012: "GM1690",
    WK169013: "GM1690",
    WK169500: "GM1695",
    WK169501: "GM1695",
    WK169502: "GM1695",
    WK169503: "GM1695",
    WK169504: "GM1695",
    WK169505: "GM1695",
    WK169600: "GM1696",
    WK169601: "GM1696",
    WK169602: "GM1696",
    WK169603: "GM1696",
    WK169604: "GM1696",
    WK169605: "GM1696",
    WK169606: "GM1696",
    WK169900: "GM1699",
    WK169901: "GM1699",
    WK169903: "GM1699",
    WK169904: "GM1699",
    WK169906: "GM1699",
    WK170000: "GM1700",
    WK170001: "GM1700",
    WK170002: "GM1700",
    WK170003: "GM1700",
    WK170004: "GM1700",
    WK170100: "GM1701",
    WK170101: "GM1701",
    WK170102: "GM1701",
    WK170103: "GM1701",
    WK170104: "GM1701",
    WK170105: "GM1701",
    WK170106: "GM1701",
    WK170107: "GM1701",
    WK170108: "GM1701",
    WK170109: "GM1701",
    WK170110: "GM1701",
    WK170111: "GM1701",
    WK170112: "GM1701",
    WK170113: "GM1701",
    WK170114: "GM1701",
    WK170115: "GM1701",
    WK170116: "GM1701",
    WK170117: "GM1701",
    WK170200: "GM1702",
    WK170201: "GM1702",
    WK170202: "GM1702",
    WK170203: "GM1702",
    WK170204: "GM1702",
    WK170205: "GM1702",
    WK170500: "GM1705",
    WK170501: "GM1705",
    WK170502: "GM1705",
    WK170600: "GM1706",
    WK170601: "GM1706",
    WK170602: "GM1706",
    WK170603: "GM1706",
    WK170604: "GM1706",
    WK170605: "GM1706",
    WK170800: "GM1708",
    WK170802: "GM1708",
    WK170803: "GM1708",
    WK170804: "GM1708",
    WK170805: "GM1708",
    WK170806: "GM1708",
    WK170807: "GM1708",
    WK170808: "GM1708",
    WK170810: "GM1708",
    WK170811: "GM1708",
    WK170816: "GM1708",
    WK170817: "GM1708",
    WK170818: "GM1708",
    WK170819: "GM1708",
    WK170820: "GM1708",
    WK170821: "GM1708",
    WK170822: "GM1708",
    WK170823: "GM1708",
    WK170824: "GM1708",
    WK170825: "GM1708",
    WK170826: "GM1708",
    WK170827: "GM1708",
    WK170828: "GM1708",
    WK170829: "GM1708",
    WK170830: "GM1708",
    WK170831: "GM1708",
    WK170832: "GM1708",
    WK170833: "GM1708",
    WK170834: "GM1708",
    WK170835: "GM1708",
    WK170836: "GM1708",
    WK170900: "GM1709",
    WK170901: "GM1709",
    WK170902: "GM1709",
    WK170903: "GM1709",
    WK170904: "GM1709",
    WK170905: "GM1709",
    WK170906: "GM1709",
    WK170907: "GM1709",
    WK170908: "GM1709",
    WK170909: "GM1709",
    WK170910: "GM1709",
    WK171101: "GM1711",
    WK171102: "GM1711",
    WK171103: "GM1711",
    WK171104: "GM1711",
    WK171105: "GM1711",
    WK171106: "GM1711",
    WK171107: "GM1711",
    WK171108: "GM1711",
    WK171109: "GM1711",
    WK171110: "GM1711",
    WK171111: "GM1711",
    WK171400: "GM1714",
    WK171401: "GM1714",
    WK171402: "GM1714",
    WK171403: "GM1714",
    WK171404: "GM1714",
    WK171405: "GM1714",
    WK171406: "GM1714",
    WK171407: "GM1714",
    WK171408: "GM1714",
    WK171409: "GM1714",
    WK171410: "GM1714",
    WK171411: "GM1714",
    WK171412: "GM1714",
    WK171413: "GM1714",
    WK171414: "GM1714",
    WK171900: "GM1719",
    WK171901: "GM1719",
    WK171902: "GM1719",
    WK171903: "GM1719",
    WK171904: "GM1719",
    WK171905: "GM1719",
    WK172101: "GM1721",
    WK172102: "GM1721",
    WK172103: "GM1721",
    WK172104: "GM1721",
    WK172105: "GM1721",
    WK172106: "GM1721",
    WK172300: "GM1723",
    WK172301: "GM1723",
    WK172302: "GM1723",
    WK172308: "GM1723",
    WK172309: "GM1723",
    WK172400: "GM1724",
    WK172401: "GM1724",
    WK172402: "GM1724",
    WK172403: "GM1724",
    WK172404: "GM1724",
    WK172405: "GM1724",
    WK172406: "GM1724",
    WK172900: "GM1729",
    WK172901: "GM1729",
    WK172902: "GM1729",
    WK172903: "GM1729",
    WK172904: "GM1729",
    WK172905: "GM1729",
    WK172906: "GM1729",
    WK173000: "GM1730",
    WK173001: "GM1730",
    WK173002: "GM1730",
    WK173003: "GM1730",
    WK173004: "GM1730",
    WK173005: "GM1730",
    WK173006: "GM1730",
    WK173007: "GM1730",
    WK173008: "GM1730",
    WK173009: "GM1730",
    WK173010: "GM1730",
    WK173011: "GM1730",
    WK173012: "GM1730",
    WK173013: "GM1730",
    WK173014: "GM1730",
    WK173015: "GM1730",
    WK173100: "GM1731",
    WK173101: "GM1731",
    WK173102: "GM1731",
    WK173103: "GM1731",
    WK173104: "GM1731",
    WK173105: "GM1731",
    WK173106: "GM1731",
    WK173107: "GM1731",
    WK173108: "GM1731",
    WK173109: "GM1731",
    WK173110: "GM1731",
    WK173111: "GM1731",
    WK173112: "GM1731",
    WK173113: "GM1731",
    WK173114: "GM1731",
    WK173115: "GM1731",
    WK173116: "GM1731",
    WK173500: "GM1735",
    WK173501: "GM1735",
    WK173502: "GM1735",
    WK173503: "GM1735",
    WK173504: "GM1735",
    WK173505: "GM1735",
    WK173506: "GM1735",
    WK173507: "GM1735",
    WK173508: "GM1735",
    WK173509: "GM1735",
    WK174000: "GM1740",
    WK174001: "GM1740",
    WK174002: "GM1740",
    WK174200: "GM1742",
    WK174201: "GM1742",
    WK174202: "GM1742",
    WK174203: "GM1742",
    WK174204: "GM1742",
    WK174205: "GM1742",
    WK174207: "GM1742",
    WK174208: "GM1742",
    WK177100: "GM1771",
    WK177101: "GM1771",
    WK177301: "GM1773",
    WK177302: "GM1773",
    WK177303: "GM1773",
    WK177304: "GM1773",
    WK177305: "GM1773",
    WK177306: "GM1773",
    WK177307: "GM1773",
    WK177308: "GM1773",
    WK177309: "GM1773",
    WK177310: "GM1773",
    WK177311: "GM1773",
    WK177312: "GM1773",
    WK177410: "GM1774",
    WK177411: "GM1774",
    WK177412: "GM1774",
    WK177413: "GM1774",
    WK177414: "GM1774",
    WK177415: "GM1774",
    WK177416: "GM1774",
    WK177417: "GM1774",
    WK177418: "GM1774",
    WK177419: "GM1774",
    WK177420: "GM1774",
    WK177421: "GM1774",
    WK178301: "GM1783",
    WK178302: "GM1783",
    WK178303: "GM1783",
    WK178304: "GM1783",
    WK178305: "GM1783",
    WK178306: "GM1783",
    WK178307: "GM1783",
    WK178308: "GM1783",
    WK178309: "GM1783",
    WK178310: "GM1783",
    WK184200: "GM1842",
    WK184201: "GM1842",
    WK187600: "GM1876",
    WK187601: "GM1876",
    WK187602: "GM1876",
    WK187603: "GM1876",
    WK187604: "GM1876",
    WK188300: "GM1883",
    WK188301: "GM1883",
    WK188302: "GM1883",
    WK188303: "GM1883",
    WK188304: "GM1883",
    WK188305: "GM1883",
    WK188306: "GM1883",
    WK188307: "GM1883",
    WK188400: "GM1884",
    WK188401: "GM1884",
    WK188402: "GM1884",
    WK188403: "GM1884",
    WK188404: "GM1884",
    WK189100: "GM1891",
    WK189101: "GM1891",
    WK189102: "GM1891",
    WK189103: "GM1891",
    WK189201: "GM1892",
    WK189202: "GM1892",
    WK189203: "GM1892",
    WK189204: "GM1892",
    WK189400: "GM1894",
    WK189401: "GM1894",
    WK189402: "GM1894",
    WK189403: "GM1894",
    WK189404: "GM1894",
    WK189405: "GM1894",
    WK189500: "GM1895",
    WK189501: "GM1895",
    WK189502: "GM1895",
    WK189503: "GM1895",
    WK189504: "GM1895",
    WK189505: "GM1895",
    WK189506: "GM1895",
    WK189507: "GM1895",
    WK189508: "GM1895",
    WK189509: "GM1895",
    WK189510: "GM1895",
    WK189600: "GM1896",
    WK189601: "GM1896",
    WK189602: "GM1896",
    WK189603: "GM1896",
    WK189604: "GM1896",
    WK189605: "GM1896",
    WK190000: "GM1900",
    WK190001: "GM1900",
    WK190002: "GM1900",
    WK190003: "GM1900",
    WK190004: "GM1900",
    WK190005: "GM1900",
    WK190006: "GM1900",
    WK190007: "GM1900",
    WK190008: "GM1900",
    WK190009: "GM1900",
    WK190010: "GM1900",
    WK190011: "GM1900",
    WK190012: "GM1900",
    WK190013: "GM1900",
    WK190014: "GM1900",
    WK190015: "GM1900",
    WK190016: "GM1900",
    WK190017: "GM1900",
    WK190018: "GM1900",
    WK190019: "GM1900",
    WK190020: "GM1900",
    WK190101: "GM1901",
    WK190102: "GM1901",
    WK190103: "GM1901",
    WK190104: "GM1901",
    WK190105: "GM1901",
    WK190106: "GM1901",
    WK190107: "GM1901",
    WK190108: "GM1901",
    WK190109: "GM1901",
    WK190300: "GM1903",
    WK190301: "GM1903",
    WK190302: "GM1903",
    WK190303: "GM1903",
    WK190304: "GM1903",
    WK190305: "GM1903",
    WK190401: "GM1904",
    WK190402: "GM1904",
    WK190403: "GM1904",
    WK190404: "GM1904",
    WK190405: "GM1904",
    WK190406: "GM1904",
    WK190407: "GM1904",
    WK190408: "GM1904",
    WK190409: "GM1904",
    WK190410: "GM1904",
    WK190411: "GM1904",
    WK190412: "GM1904",
    WK191100: "GM1911",
    WK191101: "GM1911",
    WK191102: "GM1911",
    WK191103: "GM1911",
    WK191104: "GM1911",
    WK191105: "GM1911",
    WK191106: "GM1911",
    WK191107: "GM1911",
    WK191601: "GM1916",
    WK191602: "GM1916",
    WK191603: "GM1916",
    WK191604: "GM1916",
    WK191605: "GM1916",
    WK191606: "GM1916",
    WK191607: "GM1916",
    WK191608: "GM1916",
    WK191609: "GM1916",
    WK191610: "GM1916",
    WK191611: "GM1916",
    WK191612: "GM1916",
    WK191613: "GM1916",
    WK192400: "GM1924",
    WK192401: "GM1924",
    WK192402: "GM1924",
    WK192403: "GM1924",
    WK192404: "GM1924",
    WK192405: "GM1924",
    WK192406: "GM1924",
    WK192407: "GM1924",
    WK192408: "GM1924",
    WK192409: "GM1924",
    WK192410: "GM1924",
    WK192411: "GM1924",
    WK192412: "GM1924",
    WK192413: "GM1924",
    WK192601: "GM1926",
    WK192602: "GM1926",
    WK192603: "GM1926",
    WK193001: "GM1930",
    WK193002: "GM1930",
    WK193003: "GM1930",
    WK193004: "GM1930",
    WK193005: "GM1930",
    WK193006: "GM1930",
    WK193007: "GM1930",
    WK193008: "GM1930",
    WK193009: "GM1930",
    WK193010: "GM1930",
    WK193011: "GM1930",
    WK193012: "GM1930",
    WK193013: "GM1930",
    WK193014: "GM1930",
    WK193015: "GM1930",
    WK193016: "GM1930",
    WK193017: "GM1930",
    WK193025: "GM1930",
    WK193027: "GM1930",
    WK193029: "GM1930",
    WK193031: "GM1930",
    WK193033: "GM1930",
    WK193050: "GM1930",
    WK193100: "GM1931",
    WK193101: "GM1931",
    WK193102: "GM1931",
    WK193103: "GM1931",
    WK193104: "GM1931",
    WK193105: "GM1931",
    WK193106: "GM1931",
    WK193107: "GM1931",
    WK193108: "GM1931",
    WK193110: "GM1931",
    WK193111: "GM1931",
    WK193112: "GM1931",
    WK194001: "GM1940",
    WK194002: "GM1940",
    WK194003: "GM1940",
    WK194004: "GM1940",
    WK194005: "GM1940",
    WK194006: "GM1940",
    WK194007: "GM1940",
    WK194008: "GM1940",
    WK194009: "GM1940",
    WK194010: "GM1940",
    WK194011: "GM1940",
    WK194012: "GM1940",
    WK194013: "GM1940",
    WK194014: "GM1940",
    WK194015: "GM1940",
    WK194016: "GM1940",
    WK194017: "GM1940",
    WK194018: "GM1940",
    WK194019: "GM1940",
    WK194020: "GM1940",
    WK194021: "GM1940",
    WK194022: "GM1940",
    WK194023: "GM1940",
    WK194024: "GM1940",
    WK194025: "GM1940",
    WK194026: "GM1940",
    WK194027: "GM1940",
    WK194028: "GM1940",
    WK194029: "GM1940",
    WK194030: "GM1940",
    WK194031: "GM1940",
    WK194032: "GM1940",
    WK194033: "GM1940",
    WK194034: "GM1940",
    WK194035: "GM1940",
    WK194036: "GM1940",
    WK194037: "GM1940",
    WK194038: "GM1940",
    WK194039: "GM1940",
    WK194040: "GM1940",
    WK194041: "GM1940",
    WK194042: "GM1940",
    WK194043: "GM1940",
    WK194044: "GM1940",
    WK194045: "GM1940",
    WK194046: "GM1940",
    WK194047: "GM1940",
    WK194048: "GM1940",
    WK194049: "GM1940",
    WK194050: "GM1940",
    WK194051: "GM1940",
    WK194200: "GM1942",
    WK194201: "GM1942",
    WK194202: "GM1942",
    WK194203: "GM1942",
    WK194204: "GM1942",
    WK194205: "GM1942",
    WK194500: "GM1945",
    WK194501: "GM1945",
    WK194502: "GM1945",
    WK194503: "GM1945",
    WK194504: "GM1945",
    WK194505: "GM1945",
    WK194506: "GM1945",
    WK194507: "GM1945",
    WK194508: "GM1945",
    WK194509: "GM1945",
    WK194510: "GM1945",
    WK194511: "GM1945",
    WK194512: "GM1945",
    WK194801: "GM1948",
    WK194802: "GM1948",
    WK194803: "GM1948",
    WK194804: "GM1948",
    WK194915: "GM1949",
    WK194916: "GM1949",
    WK194917: "GM1949",
    WK194918: "GM1949",
    WK194919: "GM1949",
    WK194920: "GM1949",
    WK194921: "GM1949",
    WK194922: "GM1949",
    WK194923: "GM1949",
    WK194924: "GM1949",
    WK194925: "GM1949",
    WK194926: "GM1949",
    WK194927: "GM1949",
    WK194928: "GM1949",
    WK194929: "GM1949",
    WK194930: "GM1949",
    WK194931: "GM1949",
    WK194932: "GM1949",
    WK194933: "GM1949",
    WK194934: "GM1949",
    WK194935: "GM1949",
    WK194936: "GM1949",
    WK194937: "GM1949",
    WK194938: "GM1949",
    WK194939: "GM1949",
    WK194940: "GM1949",
    WK194941: "GM1949",
    WK194942: "GM1949",
    WK194943: "GM1949",
    WK194944: "GM1949",
    WK194945: "GM1949",
    WK194946: "GM1949",
    WK194947: "GM1949",
    WK194948: "GM1949",
    WK194949: "GM1949",
    WK194950: "GM1949",
    WK194951: "GM1949",
    WK194952: "GM1949",
    WK194953: "GM1949",
    WK194954: "GM1949",
    WK194955: "GM1949",
    WK195000: "GM1950",
    WK195001: "GM1950",
    WK195002: "GM1950",
    WK195003: "GM1950",
    WK195004: "GM1950",
    WK195005: "GM1950",
    WK195006: "GM1950",
    WK195007: "GM1950",
    WK195008: "GM1950",
    WK195009: "GM1950",
    WK195201: "GM1952",
    WK195202: "GM1952",
    WK195203: "GM1952",
    WK195204: "GM1952",
    WK195205: "GM1952",
    WK195206: "GM1952",
    WK195207: "GM1952",
    WK195208: "GM1952",
    WK195209: "GM1952",
    WK195210: "GM1952",
    WK195211: "GM1952",
    WK195212: "GM1952",
    WK195213: "GM1952",
    WK195214: "GM1952",
    WK195215: "GM1952",
    WK195216: "GM1952",
    WK195217: "GM1952",
    WK195218: "GM1952",
    WK195219: "GM1952",
    WK195220: "GM1952",
    WK195221: "GM1952",
    WK195400: "GM1954",
    WK195401: "GM1954",
    WK195402: "GM1954",
    WK195403: "GM1954",
    WK195404: "GM1954",
    WK195405: "GM1954",
    WK195406: "GM1954",
    WK195500: "GM1955",
    WK195501: "GM1955",
    WK195502: "GM1955",
    WK195901: "GM1959",
    WK195902: "GM1959",
    WK195903: "GM1959",
    WK195904: "GM1959",
    WK195905: "GM1959",
    WK195906: "GM1959",
    WK195907: "GM1959",
    WK195908: "GM1959",
    WK195909: "GM1959",
    WK195910: "GM1959",
    WK195911: "GM1959",
    WK195912: "GM1959",
    WK195913: "GM1959",
    WK195914: "GM1959",
    WK195915: "GM1959",
    WK195916: "GM1959",
    WK195917: "GM1959",
    WK195918: "GM1959",
    WK195919: "GM1959",
    WK196000: "GM1960",
    WK196001: "GM1960",
    WK196002: "GM1960",
    WK196003: "GM1960",
    WK196004: "GM1960",
    WK196005: "GM1960",
    WK196006: "GM1960",
    WK196007: "GM1960",
    WK196008: "GM1960",
    WK196009: "GM1960",
    WK196010: "GM1960",
    WK196011: "GM1960",
    WK196100: "GM1961",
    WK196101: "GM1961",
    WK196102: "GM1961",
    WK196103: "GM1961",
    WK196104: "GM1961",
    WK196105: "GM1961",
    WK196106: "GM1961",
    WK196107: "GM1961",
    WK196108: "GM1961",
    WK196109: "GM1961",
    WK196110: "GM1961",
    WK196300: "GM1963",
    WK196301: "GM1963",
    WK196302: "GM1963",
    WK196303: "GM1963",
    WK196304: "GM1963",
    WK196305: "GM1963",
    WK196306: "GM1963",
    WK196307: "GM1963",
    WK196308: "GM1963",
    WK196309: "GM1963",
    WK196310: "GM1963",
    WK196311: "GM1963",
    WK196312: "GM1963",
    WK196313: "GM1963",
    WK196600: "GM1966",
    WK196601: "GM1966",
    WK196602: "GM1966",
    WK196603: "GM1966",
    WK196604: "GM1966",
    WK196605: "GM1966",
    WK196606: "GM1966",
    WK196607: "GM1966",
    WK196608: "GM1966",
    WK196609: "GM1966",
    WK196610: "GM1966",
    WK196611: "GM1966",
    WK196612: "GM1966",
    WK196900: "GM1969",
    WK196901: "GM1969",
    WK196902: "GM1969",
    WK196903: "GM1969",
    WK196904: "GM1969",
    WK196905: "GM1969",
    WK196906: "GM1969",
    WK196907: "GM1969",
    WK196908: "GM1969",
    WK196909: "GM1969",
    WK196910: "GM1969",
    WK196911: "GM1969",
    WK196912: "GM1969",
    WK196913: "GM1969",
    WK196914: "GM1969",
    WK196915: "GM1969",
    WK196916: "GM1969",
    WK196917: "GM1969",
    WK197000: "GM1970",
    WK197001: "GM1970",
    WK197002: "GM1970",
    WK197003: "GM1970",
    WK197004: "GM1970",
    WK197005: "GM1970",
    WK197006: "GM1970",
    WK197007: "GM1970",
    WK197008: "GM1970",
    WK197009: "GM1970",
    WK197010: "GM1970",
    WK197011: "GM1970",
    WK197012: "GM1970",
    WK197013: "GM1970",
    WK197014: "GM1970",
    WK197801: "GM1978",
    WK197802: "GM1978",
    WK197803: "GM1978",
    WK197804: "GM1978",
    WK197805: "GM1978",
    WK197806: "GM1978",
    WK197807: "GM1978",
    WK197808: "GM1978",
    WK197809: "GM1978",
    WK197810: "GM1978",
    WK197811: "GM1978",
    WK197812: "GM1978",
    WK197813: "GM1978",
    WK197814: "GM1978",
    WK197815: "GM1978",
    WK197816: "GM1978",
    WK197817: "GM1978",
    WK197818: "GM1978",
    WK197819: "GM1978",
    WK197820: "GM1978",
    WK001400: "GM0014",
    WK001401: "GM0014",
    WK001402: "GM0014",
    WK001403: "GM0014",
    WK001404: "GM0014",
    WK001405: "GM0014",
    WK001406: "GM0014",
    WK001407: "GM0014",
    WK001408: "GM0014",
    WK001409: "GM0014",
    WK001410: "GM0014",
    WK001411: "GM0014",
    WK001412: "GM0014",
    WK001413: "GM0014",
    WK001414: "GM0014",
    WK001415: "GM0014",
    WK001416: "GM0014",
    WK001417: "GM0014",
    WK001418: "GM0014",
    WK001419: "GM0014",
    WK003401: "GM0034",
    WK003402: "GM0034",
    WK003403: "GM0034",
    WK003404: "GM0034",
    WK003405: "GM0034",
    WK003406: "GM0034",
    WK008010: "GM0080",
    WK008011: "GM0080",
    WK008020: "GM0080",
    WK008030: "GM0080",
    WK008031: "GM0080",
    WK008032: "GM0080",
    WK008040: "GM0080",
    WK008041: "GM0080",
    WK008050: "GM0080",
    WK008051: "GM0080",
    WK008060: "GM0080",
    WK008061: "GM0080",
    WK008062: "GM0080",
    WK008063: "GM0080",
    WK008070: "GM0080",
    WK008071: "GM0080",
    WK008072: "GM0080",
    WK008073: "GM0080",
    WK008074: "GM0080",
    WK008080: "GM0080",
    WK008081: "GM0080",
    WK008082: "GM0080",
    WK017701: "GM0177",
    WK017702: "GM0177",
    WK017703: "GM0177",
    WK017704: "GM0177",
    WK017705: "GM0177",
    WK017706: "GM0177",
    WK017707: "GM0177",
    WK017708: "GM0177",
    WK017709: "GM0177",
    WK032110: "GM0321",
    WK032111: "GM0321",
    WK032112: "GM0321",
    WK032113: "GM0321",
    WK032120: "GM0321",
    WK032130: "GM0321",
    WK032131: "GM0321",
    WK032140: "GM0321",
    WK032141: "GM0321",
    WK032150: "GM0321",
    WK032151: "GM0321",
    WK044101: "GM0441",
    WK044103: "GM0441",
    WK044104: "GM0441",
    WK044105: "GM0441",
    WK044106: "GM0441",
    WK044107: "GM0441",
    WK044108: "GM0441",
    WK044109: "GM0441",
    WK044110: "GM0441",
    WK044111: "GM0441",
    WK044112: "GM0441",
    WK044113: "GM0441",
    WK044114: "GM0441",
    WK044115: "GM0441",
    WK044116: "GM0441",
    WK044117: "GM0441",
    WK059901: "GM0599",
    WK059903: "GM0599",
    WK059904: "GM0599",
    WK059905: "GM0599",
    WK059906: "GM0599",
    WK059908: "GM0599",
    WK059910: "GM0599",
    WK059912: "GM0599",
    WK059913: "GM0599",
    WK059914: "GM0599",
    WK059915: "GM0599",
    WK059916: "GM0599",
    WK059917: "GM0599",
    WK059918: "GM0599",
    WK059919: "GM0599",
    WK059921: "GM0599",
    WK059922: "GM0599",
    WK059923: "GM0599",
    WK059924: "GM0599",
    WK059925: "GM0599",
    WK059926: "GM0599",
    WK059927: "GM0599",
    WK068700: "GM0687",
    WK068710: "GM0687",
    WK068711: "GM0687",
    WK068712: "GM0687",
    WK068713: "GM0687",
    WK068714: "GM0687",
    WK068715: "GM0687",
    WK068716: "GM0687",
    WK068717: "GM0687",
    WK068718: "GM0687",
    WK068719: "GM0687",
    WK068720: "GM0687",
    WK068729: "GM0687",
    WK068730: "GM0687",
    WK068731: "GM0687",
    WK068732: "GM0687",
    WK068739: "GM0687",
    WK068740: "GM0687",
    WK068749: "GM0687",
    WK068750: "GM0687",
    WK068751: "GM0687",
    WK068752: "GM0687",
    WK068753: "GM0687",
    WK068759: "GM0687",
    WK074301: "GM0743",
    WK074302: "GM0743",
    WK074303: "GM0743",
    WK074304: "GM0743",
    WK074305: "GM0743",
    WK074306: "GM0743",
    WK074307: "GM0743",
    WK074308: "GM0743",
    WK074309: "GM0743",
    WK074310: "GM0743",
    WK074311: "GM0743",
    WK075300: "GM0753",
    WK075800: "GM0758",
    WK075801: "GM0758",
    WK075802: "GM0758",
    WK075803: "GM0758",
    WK075804: "GM0758",
    WK075805: "GM0758",
    WK075806: "GM0758",
    WK075807: "GM0758",
    WK075808: "GM0758",
    WK075809: "GM0758",
    WK075810: "GM0758",
    WK077700: "GM0777",
    WK077701: "GM0777",
    WK077702: "GM0777",
    WK077710: "GM0777",
    WK077711: "GM0777",
    WK077712: "GM0777",
    WK077720: "GM0777",
    WK077721: "GM0777",
    WK077722: "GM0777",
    WK077723: "GM0777",
    WK082800: "GM0828",
    WK082801: "GM0828",
    WK082802: "GM0828",
    WK082803: "GM0828",
    WK082804: "GM0828",
    WK082805: "GM0828",
    WK082806: "GM0828",
    WK082807: "GM0828",
    WK082808: "GM0828",
    WK082809: "GM0828",
    WK082810: "GM0828",
    WK082811: "GM0828",
    WK082812: "GM0828",
    WK082813: "GM0828",
    WK082814: "GM0828",
    WK082815: "GM0828",
    WK082816: "GM0828",
    WK082817: "GM0828",
    WK082818: "GM0828",
    WK082819: "GM0828",
    WK082820: "GM0828",
    WK082821: "GM0828",
    WK082822: "GM0828",
    WK099501: "GM0995",
    WK099502: "GM0995",
    WK099503: "GM0995",
    WK099504: "GM0995",
    WK099505: "GM0995",
    WK099506: "GM0995",
    WK099507: "GM0995",
    WK099508: "GM0995",
    WK099509: "GM0995",
    WK099510: "GM0995",
    WK099511: "GM0995",
    WK152501: "GM1525",
    WK152502: "GM1525",
    WK152503: "GM1525",
    WK152504: "GM1525",
    WK152511: "GM1525",
    WK152512: "GM1525",
    WK152513: "GM1525",
    WK152514: "GM1525",
    WK152515: "GM1525",
    WK152521: "GM1525",
    WK152522: "GM1525",
    WK162111: "GM1621",
    WK162112: "GM1621",
    WK162113: "GM1621",
    WK162114: "GM1621",
    WK162115: "GM1621",
    WK162121: "GM1621",
    WK162122: "GM1621",
    WK162123: "GM1621",
    WK162124: "GM1621",
    WK162125: "GM1621",
    WK162126: "GM1621",
    WK162127: "GM1621",
    WK162128: "GM1621",
    WK162131: "GM1621",
    WK162132: "GM1621",
    WK162133: "GM1621",
    WK167400: "GM1674",
    WK167401: "GM1674",
    WK167402: "GM1674",
    WK167403: "GM1674",
    WK167404: "GM1674",
    WK167405: "GM1674",
    WK167406: "GM1674",
    WK167407: "GM1674",
    WK167408: "GM1674",
    WK167410: "GM1674",
    WK167411: "GM1674",
    WK167412: "GM1674",
    WK167413: "GM1674",
    WK167414: "GM1674",
    WK172800: "GM1728",
    WK172801: "GM1728",
    WK172802: "GM1728",
    WK172803: "GM1728",
    WK172804: "GM1728",
    WK173401: "GM1734",
    WK173402: "GM1734",
    WK173403: "GM1734",
    WK173404: "GM1734",
    WK173405: "GM1734",
    WK173406: "GM1734",
    WK173407: "GM1734",
    WK173408: "GM1734",
    WK173409: "GM1734",
    WK173410: "GM1734",
    WK173411: "GM1734",
    WK173412: "GM1734",
    WK173413: "GM1734",
    WK173414: "GM1734",
    WK173415: "GM1734",
    WK173416: "GM1734",
    WK173417: "GM1734",
    WK173418: "GM1734",
    WK185900: "GM1859",
    WK185901: "GM1859",
    WK185902: "GM1859",
    WK185903: "GM1859",
    WK185904: "GM1859",
};
