export default [
    {
        regionType: "municipality",
        title: "Groningen",
        population: 235371,
        code: "GM0014",
    },
    {
        regionType: "municipality",
        title: "Almere",
        population: 217420,
        code: "GM0034",
    },
    {
        regionType: "municipality",
        title: "Stadskanaal",
        population: 31890,
        code: "GM0037",
    },
    {
        regionType: "municipality",
        title: "Veendam",
        population: 27478,
        code: "GM0047",
    },
    {
        regionType: "municipality",
        title: "Zeewolde",
        population: 23300,
        code: "GM0050",
    },
    {
        regionType: "municipality",
        title: "Achtkarspelen",
        population: 27964,
        code: "GM0059",
    },
    {
        regionType: "municipality",
        title: "Ameland",
        population: 3773,
        code: "GM0060",
    },
    {
        regionType: "municipality",
        title: "Harlingen",
        population: 15887,
        code: "GM0072",
    },
    {
        regionType: "municipality",
        title: "Heerenveen",
        population: 51073,
        code: "GM0074",
    },
    {
        regionType: "municipality",
        title: "Leeuwarden",
        population: 125606,
        code: "GM0080",
    },
    {
        regionType: "municipality",
        title: "Ooststellingwerf",
        population: 25680,
        code: "GM0085",
    },
    {
        regionType: "municipality",
        title: "Opsterland",
        population: 29808,
        code: "GM0086",
    },
    {
        regionType: "municipality",
        title: "Schiermonnikoog",
        population: 950,
        code: "GM0088",
    },
    {
        regionType: "municipality",
        title: "Smallingerland",
        population: 55999,
        code: "GM0090",
    },
    {
        regionType: "municipality",
        title: "Terschelling",
        population: 4960,
        code: "GM0093",
    },
    {
        regionType: "municipality",
        title: "Vlieland",
        population: 1239,
        code: "GM0096",
    },
    {
        regionType: "municipality",
        title: "Weststellingwerf",
        population: 26243,
        code: "GM0098",
    },
    {
        regionType: "municipality",
        title: "Assen",
        population: 69070,
        code: "GM0106",
    },
    {
        regionType: "municipality",
        title: "Coevorden",
        population: 35548,
        code: "GM0109",
    },
    {
        regionType: "municipality",
        title: "Emmen",
        population: 107665,
        code: "GM0114",
    },
    {
        regionType: "municipality",
        title: "Hoogeveen",
        population: 55979,
        code: "GM0118",
    },
    {
        regionType: "municipality",
        title: "Meppel",
        population: 34747,
        code: "GM0119",
    },
    {
        regionType: "municipality",
        title: "Almelo",
        population: 73122,
        code: "GM0141",
    },
    {
        regionType: "municipality",
        title: "Borne",
        population: 23912,
        code: "GM0147",
    },
    {
        regionType: "municipality",
        title: "Dalfsen",
        population: 29109,
        code: "GM0148",
    },
    {
        regionType: "municipality",
        title: "Deventer",
        population: 101433,
        code: "GM0150",
    },
    {
        regionType: "municipality",
        title: "Enschede",
        population: 160695,
        code: "GM0153",
    },
    {
        regionType: "municipality",
        title: "Haaksbergen",
        population: 24263,
        code: "GM0158",
    },
    {
        regionType: "municipality",
        title: "Hardenberg",
        population: 61805,
        code: "GM0160",
    },
    {
        regionType: "municipality",
        title: "Hellendoorn",
        population: 35965,
        code: "GM0163",
    },
    {
        regionType: "municipality",
        title: "Hengelo",
        population: 81440,
        code: "GM0164",
    },
    {
        regionType: "municipality",
        title: "Kampen",
        population: 54763,
        code: "GM0166",
    },
    {
        regionType: "municipality",
        title: "Losser",
        population: 23042,
        code: "GM0168",
    },
    {
        regionType: "municipality",
        title: "Noordoostpolder",
        population: 48022,
        code: "GM0171",
    },
    {
        regionType: "municipality",
        title: "Oldenzaal",
        population: 31742,
        code: "GM0173",
    },
    {
        regionType: "municipality",
        title: "Ommen",
        population: 18446,
        code: "GM0175",
    },
    {
        regionType: "municipality",
        title: "Raalte",
        population: 38178,
        code: "GM0177",
    },
    {
        regionType: "municipality",
        title: "Staphorst",
        population: 17304,
        code: "GM0180",
    },
    {
        regionType: "municipality",
        title: "Tubbergen",
        population: 21388,
        code: "GM0183",
    },
    {
        regionType: "municipality",
        title: "Urk",
        population: 21430,
        code: "GM0184",
    },
    {
        regionType: "municipality",
        title: "Wierden",
        population: 24578,
        code: "GM0189",
    },
    {
        regionType: "municipality",
        title: "Zwolle",
        population: 130523,
        code: "GM0193",
    },
    {
        regionType: "municipality",
        title: "Aalten",
        population: 27123,
        code: "GM0197",
    },
    {
        regionType: "municipality",
        title: "Apeldoorn",
        population: 165453,
        code: "GM0200",
    },
    {
        regionType: "municipality",
        title: "Arnhem",
        population: 163981,
        code: "GM0202",
    },
    {
        regionType: "municipality",
        title: "Barneveld",
        population: 60517,
        code: "GM0203",
    },
    {
        regionType: "municipality",
        title: "Beuningen",
        population: 26223,
        code: "GM0209",
    },
    {
        regionType: "municipality",
        title: "Brummen",
        population: 20908,
        code: "GM0213",
    },
    {
        regionType: "municipality",
        title: "Buren",
        population: 27184,
        code: "GM0214",
    },
    {
        regionType: "municipality",
        title: "Culemborg",
        population: 29428,
        code: "GM0216",
    },
    {
        regionType: "municipality",
        title: "Doesburg",
        population: 11064,
        code: "GM0221",
    },
    {
        regionType: "municipality",
        title: "Doetinchem",
        population: 58559,
        code: "GM0222",
    },
    {
        regionType: "municipality",
        title: "Druten",
        population: 19188,
        code: "GM0225",
    },
    {
        regionType: "municipality",
        title: "Duiven",
        population: 24946,
        code: "GM0226",
    },
    {
        regionType: "municipality",
        title: "Ede",
        population: 119827,
        code: "GM0228",
    },
    {
        regionType: "municipality",
        title: "Elburg",
        population: 23718,
        code: "GM0230",
    },
    {
        regionType: "municipality",
        title: "Epe",
        population: 33265,
        code: "GM0232",
    },
    {
        regionType: "municipality",
        title: "Ermelo",
        population: 27304,
        code: "GM0233",
    },
    {
        regionType: "municipality",
        title: "Harderwijk",
        population: 48797,
        code: "GM0243",
    },
    {
        regionType: "municipality",
        title: "Hattem",
        population: 12320,
        code: "GM0244",
    },
    {
        regionType: "municipality",
        title: "Heerde",
        population: 18955,
        code: "GM0246",
    },
    {
        regionType: "municipality",
        title: "Heumen",
        population: 16921,
        code: "GM0252",
    },
    {
        regionType: "municipality",
        title: "Lochem",
        population: 34131,
        code: "GM0262",
    },
    {
        regionType: "municipality",
        title: "Maasdriel",
        population: 25548,
        code: "GM0263",
    },
    {
        regionType: "municipality",
        title: "Nijkerk",
        population: 44134,
        code: "GM0267",
    },
    {
        regionType: "municipality",
        title: "Nijmegen",
        population: 179059,
        code: "GM0268",
    },
    {
        regionType: "municipality",
        title: "Oldebroek",
        population: 23898,
        code: "GM0269",
    },
    {
        regionType: "municipality",
        title: "Putten",
        population: 24550,
        code: "GM0273",
    },
    {
        regionType: "municipality",
        title: "Renkum",
        population: 31406,
        code: "GM0274",
    },
    {
        regionType: "municipality",
        title: "Rheden",
        population: 43466,
        code: "GM0275",
    },
    {
        regionType: "municipality",
        title: "Rozendaal",
        population: 1754,
        code: "GM0277",
    },
    {
        regionType: "municipality",
        title: "Scherpenzeel",
        population: 10326,
        code: "GM0279",
    },
    {
        regionType: "municipality",
        title: "Tiel",
        population: 42122,
        code: "GM0281",
    },
    {
        regionType: "municipality",
        title: "Voorst",
        population: 24975,
        code: "GM0285",
    },
    {
        regionType: "municipality",
        title: "Wageningen",
        population: 40066,
        code: "GM0289",
    },
    {
        regionType: "municipality",
        title: "Westervoort",
        population: 14970,
        code: "GM0293",
    },
    {
        regionType: "municipality",
        title: "Winterswijk",
        population: 29064,
        code: "GM0294",
    },
    {
        regionType: "municipality",
        title: "Wijchen",
        population: 41371,
        code: "GM0296",
    },
    {
        regionType: "municipality",
        title: "Zaltbommel",
        population: 29896,
        code: "GM0297",
    },
    {
        regionType: "municipality",
        title: "Zevenaar",
        population: 44610,
        code: "GM0299",
    },
    {
        regionType: "municipality",
        title: "Zutphen",
        population: 48428,
        code: "GM0301",
    },
    {
        regionType: "municipality",
        title: "Nunspeet",
        population: 28168,
        code: "GM0302",
    },
    {
        regionType: "municipality",
        title: "Dronten",
        population: 42926,
        code: "GM0303",
    },
    {
        regionType: "municipality",
        title: "Amersfoort",
        population: 158397,
        code: "GM0307",
    },
    {
        regionType: "municipality",
        title: "Baarn",
        population: 24785,
        code: "GM0308",
    },
    {
        regionType: "municipality",
        title: "De Bilt",
        population: 43518,
        code: "GM0310",
    },
    {
        regionType: "municipality",
        title: "Bunnik",
        population: 15547,
        code: "GM0312",
    },
    {
        regionType: "municipality",
        title: "Bunschoten",
        population: 22315,
        code: "GM0313",
    },
    {
        regionType: "municipality",
        title: "Eemnes",
        population: 9439,
        code: "GM0317",
    },
    {
        regionType: "municipality",
        title: "Houten",
        population: 50329,
        code: "GM0321",
    },
    {
        regionType: "municipality",
        title: "Leusden",
        population: 30698,
        code: "GM0327",
    },
    {
        regionType: "municipality",
        title: "Lopik",
        population: 14537,
        code: "GM0331",
    },
    {
        regionType: "municipality",
        title: "Montfoort",
        population: 13845,
        code: "GM0335",
    },
    {
        regionType: "municipality",
        title: "Renswoude",
        population: 5653,
        code: "GM0339",
    },
    {
        regionType: "municipality",
        title: "Rhenen",
        population: 20284,
        code: "GM0340",
    },
    {
        regionType: "municipality",
        title: "Soest",
        population: 47105,
        code: "GM0342",
    },
    {
        regionType: "municipality",
        title: "Utrecht",
        population: 361639,
        code: "GM0344",
    },
    {
        regionType: "municipality",
        title: "Veenendaal",
        population: 67582,
        code: "GM0345",
    },
    {
        regionType: "municipality",
        title: "Woudenberg",
        population: 13824,
        code: "GM0351",
    },
    {
        regionType: "municipality",
        title: "Wijk bij Duurstede",
        population: 23928,
        code: "GM0352",
    },
    {
        regionType: "municipality",
        title: "IJsselstein",
        population: 33547,
        code: "GM0353",
    },
    {
        regionType: "municipality",
        title: "Zeist",
        population: 65665,
        code: "GM0355",
    },
    {
        regionType: "municipality",
        title: "Nieuwegein",
        population: 64466,
        code: "GM0356",
    },
    {
        regionType: "municipality",
        title: "Aalsmeer",
        population: 32374,
        code: "GM0358",
    },
    {
        regionType: "municipality",
        title: "Alkmaar",
        population: 110405,
        code: "GM0361",
    },
    {
        regionType: "municipality",
        title: "Amstelveen",
        population: 92297,
        code: "GM0362",
    },
    {
        regionType: "municipality",
        title: "Amsterdam",
        population: 880909,
        code: "GM0363",
    },
    {
        regionType: "municipality",
        title: "Bergen (NH.)",
        population: 29729,
        code: "GM0373",
    },
    {
        regionType: "municipality",
        title: "Beverwijk",
        population: 42103,
        code: "GM0375",
    },
    {
        regionType: "municipality",
        title: "Blaricum",
        population: 12342,
        code: "GM0376",
    },
    {
        regionType: "municipality",
        title: "Bloemendaal",
        population: 23715,
        code: "GM0377",
    },
    {
        regionType: "municipality",
        title: "Castricum",
        population: 36234,
        code: "GM0383",
    },
    {
        regionType: "municipality",
        title: "Diemen",
        population: 31818,
        code: "GM0384",
    },
    {
        regionType: "municipality",
        title: "Edam-Volendam",
        population: 36479,
        code: "GM0385",
    },
    {
        regionType: "municipality",
        title: "Enkhuizen",
        population: 18652,
        code: "GM0388",
    },
    {
        regionType: "municipality",
        title: "Haarlem",
        population: 162779,
        code: "GM0392",
    },
    {
        regionType: "municipality",
        title: "Haarlemmermeer",
        population: 158929,
        code: "GM0394",
    },
    {
        regionType: "municipality",
        title: "Heemskerk",
        population: 39177,
        code: "GM0396",
    },
    {
        regionType: "municipality",
        title: "Heemstede",
        population: 27647,
        code: "GM0397",
    },
    {
        regionType: "municipality",
        title: "Heiloo",
        population: 24370,
        code: "GM0399",
    },
    {
        regionType: "municipality",
        title: "Den Helder",
        population: 56423,
        code: "GM0400",
    },
    {
        regionType: "municipality",
        title: "Hilversum",
        population: 91732,
        code: "GM0402",
    },
    {
        regionType: "municipality",
        title: "Hoorn",
        population: 74140,
        code: "GM0405",
    },
    {
        regionType: "municipality",
        title: "Huizen",
        population: 40940,
        code: "GM0406",
    },
    {
        regionType: "municipality",
        title: "Landsmeer",
        population: 11571,
        code: "GM0415",
    },
    {
        regionType: "municipality",
        title: "Laren",
        population: 11524,
        code: "GM0417",
    },
    {
        regionType: "municipality",
        title: "Medemblik",
        population: 45413,
        code: "GM0420",
    },
    {
        regionType: "municipality",
        title: "Oostzaan",
        population: 9659,
        code: "GM0431",
    },
    {
        regionType: "municipality",
        title: "Opmeer",
        population: 12084,
        code: "GM0432",
    },
    {
        regionType: "municipality",
        title: "Ouder-Amstel",
        population: 14195,
        code: "GM0437",
    },
    {
        regionType: "municipality",
        title: "Purmerend",
        population: 92125,
        code: "GM0439",
    },
    {
        regionType: "municipality",
        title: "Schagen",
        population: 46816,
        code: "GM0441",
    },
    {
        regionType: "municipality",
        title: "Texel",
        population: 13702,
        code: "GM0448",
    },
    {
        regionType: "municipality",
        title: "Uitgeest",
        population: 13593,
        code: "GM0450",
    },
    {
        regionType: "municipality",
        title: "Uithoorn",
        population: 30937,
        code: "GM0451",
    },
    {
        regionType: "municipality",
        title: "Velsen",
        population: 68497,
        code: "GM0453",
    },
    {
        regionType: "municipality",
        title: "Weesp",
        population: 20733,
        code: "GM0457",
    },
    {
        regionType: "municipality",
        title: "Zandvoort",
        population: 17101,
        code: "GM0473",
    },
    {
        regionType: "municipality",
        title: "Zaanstad",
        population: 156989,
        code: "GM0479",
    },
    {
        regionType: "municipality",
        title: "Alblasserdam",
        population: 20093,
        code: "GM0482",
    },
    {
        regionType: "municipality",
        title: "Alphen aan den Rijn",
        population: 112940,
        code: "GM0484",
    },
    {
        regionType: "municipality",
        title: "Barendrecht",
        population: 48751,
        code: "GM0489",
    },
    {
        regionType: "municipality",
        title: "Drechterland",
        population: 19969,
        code: "GM0498",
    },
    {
        regionType: "municipality",
        title: "Brielle",
        population: 17674,
        code: "GM0501",
    },
    {
        regionType: "municipality",
        title: "Capelle aan den IJssel",
        population: 67133,
        code: "GM0502",
    },
    {
        regionType: "municipality",
        title: "Delft",
        population: 104616,
        code: "GM0503",
    },
    {
        regionType: "municipality",
        title: "Dordrecht",
        population: 119445,
        code: "GM0505",
    },
    {
        regionType: "municipality",
        title: "Gorinchem",
        population: 37650,
        code: "GM0512",
    },
    {
        regionType: "municipality",
        title: "Gouda",
        population: 74108,
        code: "GM0513",
    },
    {
        regionType: "municipality",
        title: "'s-Gravenhage",
        population: 553008,
        code: "GM0518",
    },
    {
        regionType: "municipality",
        title: "Hardinxveld-Giessendam",
        population: 18521,
        code: "GM0523",
    },
    {
        regionType: "municipality",
        title: "Hellevoetsluis",
        population: 40559,
        code: "GM0530",
    },
    {
        regionType: "municipality",
        title: "Hendrik-Ido-Ambacht",
        population: 31612,
        code: "GM0531",
    },
    {
        regionType: "municipality",
        title: "Stede Broec",
        population: 21927,
        code: "GM0532",
    },
    {
        regionType: "municipality",
        title: "Hillegom",
        population: 22314,
        code: "GM0534",
    },
    {
        regionType: "municipality",
        title: "Katwijk",
        population: 65981,
        code: "GM0537",
    },
    {
        regionType: "municipality",
        title: "Krimpen aan den IJssel",
        population: 29410,
        code: "GM0542",
    },
    {
        regionType: "municipality",
        title: "Leiden",
        population: 125030,
        code: "GM0546",
    },
    {
        regionType: "municipality",
        title: "Leiderdorp",
        population: 27500,
        code: "GM0547",
    },
    {
        regionType: "municipality",
        title: "Lisse",
        population: 23004,
        code: "GM0553",
    },
    {
        regionType: "municipality",
        title: "Maassluis",
        population: 33857,
        code: "GM0556",
    },
    {
        regionType: "municipality",
        title: "Nieuwkoop",
        population: 29308,
        code: "GM0569",
    },
    {
        regionType: "municipality",
        title: "Noordwijk",
        population: 44348,
        code: "GM0575",
    },
    {
        regionType: "municipality",
        title: "Oegstgeest",
        population: 25390,
        code: "GM0579",
    },
    {
        regionType: "municipality",
        title: "Oudewater",
        population: 10163,
        code: "GM0589",
    },
    {
        regionType: "municipality",
        title: "Papendrecht",
        population: 32217,
        code: "GM0590",
    },
    {
        regionType: "municipality",
        title: "Ridderkerk",
        population: 47033,
        code: "GM0597",
    },
    {
        regionType: "municipality",
        title: "Rotterdam",
        population: 654714,
        code: "GM0599",
    },
    {
        regionType: "municipality",
        title: "Rijswijk",
        population: 56704,
        code: "GM0603",
    },
    {
        regionType: "municipality",
        title: "Schiedam",
        population: 79696,
        code: "GM0606",
    },
    {
        regionType: "municipality",
        title: "Sliedrecht",
        population: 25834,
        code: "GM0610",
    },
    {
        regionType: "municipality",
        title: "Albrandswaard",
        population: 25897,
        code: "GM0613",
    },
    {
        regionType: "municipality",
        title: "Westvoorne",
        population: 14963,
        code: "GM0614",
    },
    {
        regionType: "municipality",
        title: "Vlaardingen",
        population: 74249,
        code: "GM0622",
    },
    {
        regionType: "municipality",
        title: "Voorschoten",
        population: 25616,
        code: "GM0626",
    },
    {
        regionType: "municipality",
        title: "Waddinxveen",
        population: 31203,
        code: "GM0627",
    },
    {
        regionType: "municipality",
        title: "Wassenaar",
        population: 27036,
        code: "GM0629",
    },
    {
        regionType: "municipality",
        title: "Woerden",
        population: 52911,
        code: "GM0632",
    },
    {
        regionType: "municipality",
        title: "Zoetermeer",
        population: 125576,
        code: "GM0637",
    },
    {
        regionType: "municipality",
        title: "Zoeterwoude",
        population: 9292,
        code: "GM0638",
    },
    {
        regionType: "municipality",
        title: "Zwijndrecht",
        population: 44793,
        code: "GM0642",
    },
    {
        regionType: "municipality",
        title: "Borsele",
        population: 22839,
        code: "GM0654",
    },
    {
        regionType: "municipality",
        title: "Goes",
        population: 38971,
        code: "GM0664",
    },
    {
        regionType: "municipality",
        title: "West Maas en Waal",
        population: 19687,
        code: "GM0668",
    },
    {
        regionType: "municipality",
        title: "Hulst",
        population: 27545,
        code: "GM0677",
    },
    {
        regionType: "municipality",
        title: "Kapelle",
        population: 13015,
        code: "GM0678",
    },
    {
        regionType: "municipality",
        title: "Middelburg",
        population: 49189,
        code: "GM0687",
    },
    {
        regionType: "municipality",
        title: "Reimerswaal",
        population: 22926,
        code: "GM0703",
    },
    {
        regionType: "municipality",
        title: "Terneuzen",
        population: 54518,
        code: "GM0715",
    },
    {
        regionType: "municipality",
        title: "Tholen",
        population: 26384,
        code: "GM0716",
    },
    {
        regionType: "municipality",
        title: "Veere",
        population: 21860,
        code: "GM0717",
    },
    {
        regionType: "municipality",
        title: "Vlissingen",
        population: 44531,
        code: "GM0718",
    },
    {
        regionType: "municipality",
        title: "De Ronde Venen",
        population: 44921,
        code: "GM0736",
    },
    {
        regionType: "municipality",
        title: "Tytsjerksteradiel",
        population: 32296,
        code: "GM0737",
    },
    {
        regionType: "municipality",
        title: "Asten",
        population: 17072,
        code: "GM0743",
    },
    {
        regionType: "municipality",
        title: "Baarle-Nassau",
        population: 6951,
        code: "GM0744",
    },
    {
        regionType: "municipality",
        title: "Bergen op Zoom",
        population: 67878,
        code: "GM0748",
    },
    {
        regionType: "municipality",
        title: "Best",
        population: 30519,
        code: "GM0753",
    },
    {
        regionType: "municipality",
        title: "Boekel",
        population: 11043,
        code: "GM0755",
    },
    {
        regionType: "municipality",
        title: "Boxtel",
        population: 33131,
        code: "GM0757",
    },
    {
        regionType: "municipality",
        title: "Breda",
        population: 184853,
        code: "GM0758",
    },
    {
        regionType: "municipality",
        title: "Deurne",
        population: 32595,
        code: "GM0762",
    },
    {
        regionType: "municipality",
        title: "Pekela",
        population: 12195,
        code: "GM0765",
    },
    {
        regionType: "municipality",
        title: "Dongen",
        population: 26497,
        code: "GM0766",
    },
    {
        regionType: "municipality",
        title: "Eersel",
        population: 19802,
        code: "GM0770",
    },
    {
        regionType: "municipality",
        title: "Eindhoven",
        population: 238238,
        code: "GM0772",
    },
    {
        regionType: "municipality",
        title: "Etten-Leur",
        population: 44033,
        code: "GM0777",
    },
    {
        regionType: "municipality",
        title: "Geertruidenberg",
        population: 21920,
        code: "GM0779",
    },
    {
        regionType: "municipality",
        title: "Gilze en Rijen",
        population: 26631,
        code: "GM0784",
    },
    {
        regionType: "municipality",
        title: "Goirle",
        population: 24033,
        code: "GM0785",
    },
    {
        regionType: "municipality",
        title: "Helmond",
        population: 93386,
        code: "GM0794",
    },
    {
        regionType: "municipality",
        title: "'s-Hertogenbosch",
        population: 156405,
        code: "GM0796",
    },
    {
        regionType: "municipality",
        title: "Heusden",
        population: 45530,
        code: "GM0797",
    },
    {
        regionType: "municipality",
        title: "Hilvarenbeek",
        population: 15785,
        code: "GM0798",
    },
    {
        regionType: "municipality",
        title: "Loon op Zand",
        population: 23779,
        code: "GM0809",
    },
    {
        regionType: "municipality",
        title: "Nuenen, Gerwen en Nederwetten",
        population: 23815,
        code: "GM0820",
    },
    {
        regionType: "municipality",
        title: "Oirschot",
        population: 19050,
        code: "GM0823",
    },
    {
        regionType: "municipality",
        title: "Oisterwijk",
        population: 32554,
        code: "GM0824",
    },
    {
        regionType: "municipality",
        title: "Oosterhout",
        population: 56466,
        code: "GM0826",
    },
    {
        regionType: "municipality",
        title: "Oss",
        population: 93146,
        code: "GM0828",
    },
    {
        regionType: "municipality",
        title: "Rucphen",
        population: 23376,
        code: "GM0840",
    },
    {
        regionType: "municipality",
        title: "Sint-Michielsgestel",
        population: 29649,
        code: "GM0845",
    },
    {
        regionType: "municipality",
        title: "Someren",
        population: 19659,
        code: "GM0847",
    },
    {
        regionType: "municipality",
        title: "Son en Breugel",
        population: 17719,
        code: "GM0848",
    },
    {
        regionType: "municipality",
        title: "Steenbergen",
        population: 24412,
        code: "GM0851",
    },
    {
        regionType: "municipality",
        title: "Waterland",
        population: 17315,
        code: "GM0852",
    },
    {
        regionType: "municipality",
        title: "Tilburg",
        population: 224167,
        code: "GM0855",
    },
    {
        regionType: "municipality",
        title: "Valkenswaard",
        population: 31246,
        code: "GM0858",
    },
    {
        regionType: "municipality",
        title: "Veldhoven",
        population: 45819,
        code: "GM0861",
    },
    {
        regionType: "municipality",
        title: "Vught",
        population: 31721,
        code: "GM0865",
    },
    {
        regionType: "municipality",
        title: "Waalre",
        population: 17591,
        code: "GM0866",
    },
    {
        regionType: "municipality",
        title: "Waalwijk",
        population: 49385,
        code: "GM0867",
    },
    {
        regionType: "municipality",
        title: "Woensdrecht",
        population: 22138,
        code: "GM0873",
    },
    {
        regionType: "municipality",
        title: "Zundert",
        population: 22364,
        code: "GM0879",
    },
    {
        regionType: "municipality",
        title: "Wormerland",
        population: 16365,
        code: "GM0880",
    },
    {
        regionType: "municipality",
        title: "Landgraaf",
        population: 37100,
        code: "GM0882",
    },
    {
        regionType: "municipality",
        title: "Beek",
        population: 15833,
        code: "GM0888",
    },
    {
        regionType: "municipality",
        title: "Beesel",
        population: 13467,
        code: "GM0889",
    },
    {
        regionType: "municipality",
        title: "Bergen (L.)",
        population: 13132,
        code: "GM0893",
    },
    {
        regionType: "municipality",
        title: "Brunssum",
        population: 27673,
        code: "GM0899",
    },
    {
        regionType: "municipality",
        title: "Gennep",
        population: 17210,
        code: "GM0907",
    },
    {
        regionType: "municipality",
        title: "Heerlen",
        population: 86932,
        code: "GM0917",
    },
    {
        regionType: "municipality",
        title: "Kerkrade",
        population: 45355,
        code: "GM0928",
    },
    {
        regionType: "municipality",
        title: "Maastricht",
        population: 121728,
        code: "GM0935",
    },
    {
        regionType: "municipality",
        title: "Meerssen",
        population: 18615,
        code: "GM0938",
    },
    {
        regionType: "municipality",
        title: "Mook en Middelaar",
        population: 7989,
        code: "GM0944",
    },
    {
        regionType: "municipality",
        title: "Nederweert",
        population: 17312,
        code: "GM0946",
    },
    {
        regionType: "municipality",
        title: "Roermond",
        population: 59091,
        code: "GM0957",
    },
    {
        regionType: "municipality",
        title: "Simpelveld",
        population: 10432,
        code: "GM0965",
    },
    {
        regionType: "municipality",
        title: "Stein",
        population: 24815,
        code: "GM0971",
    },
    {
        regionType: "municipality",
        title: "Vaals",
        population: 10120,
        code: "GM0981",
    },
    {
        regionType: "municipality",
        title: "Venlo",
        population: 102324,
        code: "GM0983",
    },
    {
        regionType: "municipality",
        title: "Venray",
        population: 43951,
        code: "GM0984",
    },
    {
        regionType: "municipality",
        title: "Voerendaal",
        population: 12422,
        code: "GM0986",
    },
    {
        regionType: "municipality",
        title: "Weert",
        population: 50263,
        code: "GM0988",
    },
    {
        regionType: "municipality",
        title: "Valkenburg aan de Geul",
        population: 16228,
        code: "GM0994",
    },
    {
        regionType: "municipality",
        title: "Lelystad",
        population: 80954,
        code: "GM0995",
    },
    {
        regionType: "municipality",
        title: "Horst aan de Maas",
        population: 42988,
        code: "GM1507",
    },
    {
        regionType: "municipality",
        title: "Oude IJsselstreek",
        population: 39368,
        code: "GM1509",
    },
    {
        regionType: "municipality",
        title: "Teylingen",
        population: 37921,
        code: "GM1525",
    },
    {
        regionType: "municipality",
        title: "Utrechtse Heuvelrug",
        population: 50045,
        code: "GM1581",
    },
    {
        regionType: "municipality",
        title: "Oost Gelre",
        population: 29619,
        code: "GM1586",
    },
    {
        regionType: "municipality",
        title: "Koggenland",
        population: 23037,
        code: "GM1598",
    },
    {
        regionType: "municipality",
        title: "Lansingerland",
        population: 64053,
        code: "GM1621",
    },
    {
        regionType: "municipality",
        title: "Leudal",
        population: 35972,
        code: "GM1640",
    },
    {
        regionType: "municipality",
        title: "Maasgouw",
        population: 24046,
        code: "GM1641",
    },
    {
        regionType: "municipality",
        title: "Gemert-Bakel",
        population: 31052,
        code: "GM1652",
    },
    {
        regionType: "municipality",
        title: "Halderberge",
        population: 30738,
        code: "GM1655",
    },
    {
        regionType: "municipality",
        title: "Heeze-Leende",
        population: 16460,
        code: "GM1658",
    },
    {
        regionType: "municipality",
        title: "Laarbeek",
        population: 22934,
        code: "GM1659",
    },
    {
        regionType: "municipality",
        title: "Reusel-De Mierden",
        population: 13259,
        code: "GM1667",
    },
    {
        regionType: "municipality",
        title: "Roerdalen",
        population: 20575,
        code: "GM1669",
    },
    {
        regionType: "municipality",
        title: "Roosendaal",
        population: 77285,
        code: "GM1674",
    },
    {
        regionType: "municipality",
        title: "Schouwen-Duiveland",
        population: 34223,
        code: "GM1676",
    },
    {
        regionType: "municipality",
        title: "Aa en Hunze",
        population: 25563,
        code: "GM1680",
    },
    {
        regionType: "municipality",
        title: "Borger-Odoorn",
        population: 25678,
        code: "GM1681",
    },
    {
        regionType: "municipality",
        title: "De Wolden",
        population: 24518,
        code: "GM1690",
    },
    {
        regionType: "municipality",
        title: "Noord-Beveland",
        population: 7668,
        code: "GM1695",
    },
    {
        regionType: "municipality",
        title: "Wijdemeren",
        population: 24511,
        code: "GM1696",
    },
    {
        regionType: "municipality",
        title: "Noordenveld",
        population: 31253,
        code: "GM1699",
    },
    {
        regionType: "municipality",
        title: "Twenterand",
        population: 33709,
        code: "GM1700",
    },
    {
        regionType: "municipality",
        title: "Westerveld",
        population: 19855,
        code: "GM1701",
    },
    {
        regionType: "municipality",
        title: "Lingewaard",
        population: 46996,
        code: "GM1705",
    },
    {
        regionType: "municipality",
        title: "Cranendonck",
        population: 20617,
        code: "GM1706",
    },
    {
        regionType: "municipality",
        title: "Steenwijkerland",
        population: 44637,
        code: "GM1708",
    },
    {
        regionType: "municipality",
        title: "Moerdijk",
        population: 37293,
        code: "GM1709",
    },
    {
        regionType: "municipality",
        title: "Echt-Susteren",
        population: 31773,
        code: "GM1711",
    },
    {
        regionType: "municipality",
        title: "Sluis",
        population: 23206,
        code: "GM1714",
    },
    {
        regionType: "municipality",
        title: "Drimmelen",
        population: 27416,
        code: "GM1719",
    },
    {
        regionType: "municipality",
        title: "Bernheze",
        population: 31661,
        code: "GM1721",
    },
    {
        regionType: "municipality",
        title: "Alphen-Chaam",
        population: 10404,
        code: "GM1723",
    },
    {
        regionType: "municipality",
        title: "Bergeijk",
        population: 18863,
        code: "GM1724",
    },
    {
        regionType: "municipality",
        title: "Bladel",
        population: 20676,
        code: "GM1728",
    },
    {
        regionType: "municipality",
        title: "Gulpen-Wittem",
        population: 14172,
        code: "GM1729",
    },
    {
        regionType: "municipality",
        title: "Tynaarlo",
        population: 34172,
        code: "GM1730",
    },
    {
        regionType: "municipality",
        title: "Midden-Drenthe",
        population: 33653,
        code: "GM1731",
    },
    {
        regionType: "municipality",
        title: "Overbetuwe",
        population: 48329,
        code: "GM1734",
    },
    {
        regionType: "municipality",
        title: "Hof van Twente",
        population: 35083,
        code: "GM1735",
    },
    {
        regionType: "municipality",
        title: "Neder-Betuwe",
        population: 24975,
        code: "GM1740",
    },
    {
        regionType: "municipality",
        title: "Rijssen-Holten",
        population: 38268,
        code: "GM1742",
    },
    {
        regionType: "municipality",
        title: "Geldrop-Mierlo",
        population: 40128,
        code: "GM1771",
    },
    {
        regionType: "municipality",
        title: "Olst-Wijhe",
        population: 18479,
        code: "GM1773",
    },
    {
        regionType: "municipality",
        title: "Dinkelland",
        population: 26586,
        code: "GM1774",
    },
    {
        regionType: "municipality",
        title: "Westland",
        population: 112575,
        code: "GM1783",
    },
    {
        regionType: "municipality",
        title: "Midden-Delfland",
        population: 19527,
        code: "GM1842",
    },
    {
        regionType: "municipality",
        title: "Berkelland",
        population: 43848,
        code: "GM1859",
    },
    {
        regionType: "municipality",
        title: "Bronckhorst",
        population: 36105,
        code: "GM1876",
    },
    {
        regionType: "municipality",
        title: "Sittard-Geleen",
        population: 91830,
        code: "GM1883",
    },
    {
        regionType: "municipality",
        title: "Kaag en Braassem",
        population: 27870,
        code: "GM1884",
    },
    {
        regionType: "municipality",
        title: "Dantumadiel",
        population: 18968,
        code: "GM1891",
    },
    {
        regionType: "municipality",
        title: "Zuidplas",
        population: 45712,
        code: "GM1892",
    },
    {
        regionType: "municipality",
        title: "Peel en Maas",
        population: 44177,
        code: "GM1894",
    },
    {
        regionType: "municipality",
        title: "Oldambt",
        population: 38453,
        code: "GM1895",
    },
    {
        regionType: "municipality",
        title: "Zwartewaterland",
        population: 23003,
        code: "GM1896",
    },
    {
        regionType: "municipality",
        title: "Súdwest-Fryslân",
        population: 90245,
        code: "GM1900",
    },
    {
        regionType: "municipality",
        title: "Bodegraven-Reeuwijk",
        population: 35660,
        code: "GM1901",
    },
    {
        regionType: "municipality",
        title: "Eijsden-Margraten",
        population: 25901,
        code: "GM1903",
    },
    {
        regionType: "municipality",
        title: "Stichtse Vecht",
        population: 65215,
        code: "GM1904",
    },
    {
        regionType: "municipality",
        title: "Hollands Kroon",
        population: 48864,
        code: "GM1911",
    },
    {
        regionType: "municipality",
        title: "Leidschendam-Voorburg",
        population: 76547,
        code: "GM1916",
    },
    {
        regionType: "municipality",
        title: "Goeree-Overflakkee",
        population: 51007,
        code: "GM1924",
    },
    {
        regionType: "municipality",
        title: "Pijnacker-Nootdorp",
        population: 56325,
        code: "GM1926",
    },
    {
        regionType: "municipality",
        title: "Nissewaard",
        population: 86205,
        code: "GM1930",
    },
    {
        regionType: "municipality",
        title: "Krimpenerwaard",
        population: 56981,
        code: "GM1931",
    },
    {
        regionType: "municipality",
        title: "De Fryske Marren",
        population: 51741,
        code: "GM1940",
    },
    {
        regionType: "municipality",
        title: "Gooise Meren",
        population: 58906,
        code: "GM1942",
    },
    {
        regionType: "municipality",
        title: "Berg en Dal",
        population: 34991,
        code: "GM1945",
    },
    {
        regionType: "municipality",
        title: "Meierijstad",
        population: 82564,
        code: "GM1948",
    },
    {
        regionType: "municipality",
        title: "Waadhoeke",
        population: 46360,
        code: "GM1949",
    },
    {
        regionType: "municipality",
        title: "Westerwolde",
        population: 26898,
        code: "GM1950",
    },
    {
        regionType: "municipality",
        title: "Midden-Groningen",
        population: 60984,
        code: "GM1952",
    },
    {
        regionType: "municipality",
        title: "Beekdaelen",
        population: 35992,
        code: "GM1954",
    },
    {
        regionType: "municipality",
        title: "Montferland",
        population: 36299,
        code: "GM1955",
    },
    {
        regionType: "municipality",
        title: "Altena",
        population: 56929,
        code: "GM1959",
    },
    {
        regionType: "municipality",
        title: "West Betuwe",
        population: 51906,
        code: "GM1960",
    },
    {
        regionType: "municipality",
        title: "Vijfheerenlanden",
        population: 58554,
        code: "GM1961",
    },
    {
        regionType: "municipality",
        title: "Hoeksche Waard",
        population: 88573,
        code: "GM1963",
    },
    {
        regionType: "municipality",
        title: "Het Hogeland",
        population: 47920,
        code: "GM1966",
    },
    {
        regionType: "municipality",
        title: "Westerkwartier",
        population: 64192,
        code: "GM1969",
    },
    {
        regionType: "municipality",
        title: "Noardeast-Fryslân",
        population: 45512,
        code: "GM1970",
    },
    {
        regionType: "municipality",
        title: "Molenlanden",
        population: 44621,
        code: "GM1978",
    },
    {
        regionType: "municipality",
        title: "Eemsdelta",
        population: 45352,
        code: "GM1979",
    },
    {
        regionType: "municipality",
        title: "Dijk en Waard",
        population: 87776,
        code: "GM1980",
    },
    {
        regionType: "municipality",
        title: "Land van Cuijk",
        population: 90836,
        code: "GM1982",
    },
    {
        regionType: "municipality",
        title: "Maashorst",
        population: 58351,
        code: "GM1991",
    },
];
