export default {
    GM0014: "GG0111",
    GM0034: "GG2209",
    GM0037: "GG0111",
    GM0047: "GG0111",
    GM0050: "GG2209",
    GM0059: "GG7206",
    GM0060: "GG7206",
    GM0072: "GG7206",
    GM0074: "GG7206",
    GM0080: "GG7206",
    GM0085: "GG7206",
    GM0086: "GG7206",
    GM0088: "GG7206",
    GM0090: "GG7206",
    GM0093: "GG7206",
    GM0096: "GG7206",
    GM0098: "GG7206",
    GM0106: "GG0706",
    GM0109: "GG0706",
    GM0114: "GG0706",
    GM0118: "GG0706",
    GM0119: "GG0706",
    GM0141: "GG1106",
    GM0147: "GG1106",
    GM0148: "GG1009",
    GM0150: "GG1009",
    GM0153: "GG1106",
    GM0158: "GG1106",
    GM0160: "GG1009",
    GM0163: "GG1106",
    GM0164: "GG1106",
    GM0166: "GG1009",
    GM0168: "GG1106",
    GM0171: "GG2209",
    GM0173: "GG1106",
    GM0175: "GG1009",
    GM0177: "GG1009",
    GM0180: "GG1009",
    GM0183: "GG1106",
    GM0184: "GG2209",
    GM0189: "GG1106",
    GM0193: "GG1009",
    GM0197: "GG1413",
    GM0200: "GG1413",
    GM0202: "GG1911",
    GM0203: "GG1911",
    GM0209: "GG2014",
    GM0213: "GG1413",
    GM0214: "GG2014",
    GM0216: "GG2014",
    GM0221: "GG1911",
    GM0222: "GG1413",
    GM0225: "GG2014",
    GM0226: "GG1911",
    GM0228: "GG1911",
    GM0230: "GG1413",
    GM0232: "GG1413",
    GM0233: "GG1413",
    GM0243: "GG1413",
    GM0244: "GG1413",
    GM0246: "GG1413",
    GM0252: "GG2014",
    GM0262: "GG1413",
    GM0263: "GG2014",
    GM0267: "GG1911",
    GM0268: "GG2014",
    GM0269: "GG1413",
    GM0273: "GG1413",
    GM0274: "GG1911",
    GM0275: "GG1911",
    GM0277: "GG1911",
    GM0279: "GG1911",
    GM0281: "GG2014",
    GM0285: "GG1413",
    GM0289: "GG1911",
    GM0293: "GG1911",
    GM0294: "GG1413",
    GM0296: "GG2014",
    GM0297: "GG2014",
    GM0299: "GG1911",
    GM0301: "GG1413",
    GM0302: "GG1413",
    GM0303: "GG2209",
    GM0307: "GG2514",
    GM0308: "GG2514",
    GM0310: "GG2514",
    GM0312: "GG2514",
    GM0313: "GG2514",
    GM0317: "GG2514",
    GM0321: "GG2514",
    GM0327: "GG2514",
    GM0331: "GG2514",
    GM0335: "GG2514",
    GM0339: "GG2514",
    GM0340: "GG2514",
    GM0342: "GG2514",
    GM0344: "GG2514",
    GM0345: "GG2514",
    GM0351: "GG2514",
    GM0352: "GG2514",
    GM0353: "GG2514",
    GM0355: "GG2514",
    GM0356: "GG2514",
    GM0358: "GG3406",
    GM0361: "GG2707",
    GM0362: "GG3406",
    GM0363: "GG3406",
    GM0373: "GG2707",
    GM0375: "GG3109",
    GM0376: "GG3606",
    GM0377: "GG3109",
    GM0383: "GG2707",
    GM0384: "GG3406",
    GM0385: "GG7306",
    GM0388: "GG2707",
    GM0392: "GG3109",
    GM0394: "GG3109",
    GM0396: "GG3109",
    GM0397: "GG3109",
    GM0399: "GG2707",
    GM0400: "GG2707",
    GM0402: "GG3606",
    GM0405: "GG2707",
    GM0406: "GG3606",
    GM0415: "GG7306",
    GM0417: "GG3606",
    GM0420: "GG2707",
    GM0431: "GG7306",
    GM0432: "GG2707",
    GM0437: "GG3406",
    GM0439: "GG7306",
    GM0441: "GG2707",
    GM0448: "GG2707",
    GM0450: "GG3109",
    GM0451: "GG3406",
    GM0453: "GG3109",
    GM0457: "GG3606",
    GM0473: "GG3109",
    GM0479: "GG7306",
    GM0482: "GG4816",
    GM0484: "GG4506",
    GM0489: "GG4607",
    GM0498: "GG2707",
    GM0501: "GG4607",
    GM0502: "GG4607",
    GM0503: "GG7014",
    GM0505: "GG4816",
    GM0512: "GG4816",
    GM0513: "GG4506",
    GM0518: "GG7014",
    GM0523: "GG4816",
    GM0530: "GG4607",
    GM0531: "GG4816",
    GM0532: "GG2707",
    GM0534: "GG4506",
    GM0537: "GG4506",
    GM0542: "GG4607",
    GM0546: "GG4506",
    GM0547: "GG4506",
    GM0553: "GG4506",
    GM0556: "GG4607",
    GM0569: "GG4506",
    GM0575: "GG4506",
    GM0579: "GG4506",
    GM0589: "GG2514",
    GM0590: "GG4816",
    GM0597: "GG4607",
    GM0599: "GG4607",
    GM0603: "GG7014",
    GM0606: "GG4607",
    GM0610: "GG4816",
    GM0613: "GG4607",
    GM0614: "GG4607",
    GM0622: "GG4607",
    GM0626: "GG4506",
    GM0627: "GG4506",
    GM0629: "GG7014",
    GM0632: "GG2514",
    GM0637: "GG7014",
    GM0638: "GG4506",
    GM0642: "GG4816",
    GM0654: "GG5006",
    GM0664: "GG5006",
    GM0668: "GG2014",
    GM0677: "GG5006",
    GM0678: "GG5006",
    GM0687: "GG5006",
    GM0703: "GG5006",
    GM0715: "GG5006",
    GM0716: "GG5006",
    GM0717: "GG5006",
    GM0718: "GG5006",
    GM0736: "GG2514",
    GM0737: "GG7206",
    GM0743: "GG5608",
    GM0744: "GG5206",
    GM0748: "GG5206",
    GM0753: "GG5608",
    GM0755: "GG5406",
    GM0757: "GG5406",
    GM0758: "GG5206",
    GM0762: "GG5608",
    GM0765: "GG0111",
    GM0766: "GG5406",
    GM0770: "GG5608",
    GM0772: "GG5608",
    GM0777: "GG5206",
    GM0779: "GG5206",
    GM0784: "GG5406",
    GM0785: "GG5406",
    GM0794: "GG5608",
    GM0796: "GG5406",
    GM0797: "GG5406",
    GM0798: "GG5406",
    GM0809: "GG5406",
    GM0820: "GG5608",
    GM0823: "GG5608",
    GM0824: "GG5406",
    GM0826: "GG5206",
    GM0828: "GG5406",
    GM0840: "GG5206",
    GM0845: "GG5406",
    GM0847: "GG5608",
    GM0848: "GG5608",
    GM0851: "GG5206",
    GM0852: "GG7306",
    GM0855: "GG5406",
    GM0858: "GG5608",
    GM0861: "GG5608",
    GM0865: "GG5406",
    GM0866: "GG5608",
    GM0867: "GG5406",
    GM0873: "GG5206",
    GM0879: "GG5206",
    GM0880: "GG7306",
    GM0882: "GG6106",
    GM0888: "GG6106",
    GM0889: "GG6011",
    GM0893: "GG6011",
    GM0899: "GG6106",
    GM0907: "GG6011",
    GM0917: "GG6106",
    GM0928: "GG6106",
    GM0935: "GG6106",
    GM0938: "GG6106",
    GM0944: "GG6011",
    GM0946: "GG6011",
    GM0957: "GG6011",
    GM0965: "GG6106",
    GM0971: "GG6106",
    GM0981: "GG6106",
    GM0983: "GG6011",
    GM0984: "GG6011",
    GM0986: "GG6106",
    GM0988: "GG6011",
    GM0994: "GG6106",
    GM0995: "GG2209",
    GM1507: "GG6011",
    GM1509: "GG1413",
    GM1525: "GG4506",
    GM1581: "GG2514",
    GM1586: "GG1413",
    GM1598: "GG2707",
    GM1621: "GG4607",
    GM1640: "GG6011",
    GM1641: "GG6011",
    GM1652: "GG5608",
    GM1655: "GG5206",
    GM1658: "GG5608",
    GM1659: "GG5608",
    GM1667: "GG5608",
    GM1669: "GG6011",
    GM1674: "GG5206",
    GM1676: "GG5006",
    GM1680: "GG0706",
    GM1681: "GG0706",
    GM1690: "GG0706",
    GM1695: "GG5006",
    GM1696: "GG3606",
    GM1699: "GG0706",
    GM1700: "GG1106",
    GM1701: "GG0706",
    GM1705: "GG1911",
    GM1706: "GG5608",
    GM1708: "GG1009",
    GM1709: "GG5206",
    GM1711: "GG6011",
    GM1714: "GG5006",
    GM1719: "GG5206",
    GM1721: "GG5406",
    GM1723: "GG5206",
    GM1724: "GG5608",
    GM1728: "GG5608",
    GM1729: "GG6106",
    GM1730: "GG0706",
    GM1731: "GG0706",
    GM1734: "GG1911",
    GM1735: "GG1106",
    GM1740: "GG2014",
    GM1742: "GG1106",
    GM1771: "GG5608",
    GM1773: "GG1009",
    GM1774: "GG1106",
    GM1783: "GG7014",
    GM1842: "GG7014",
    GM1859: "GG1413",
    GM1876: "GG1413",
    GM1883: "GG6106",
    GM1884: "GG4506",
    GM1891: "GG7206",
    GM1892: "GG4506",
    GM1894: "GG6011",
    GM1895: "GG0111",
    GM1896: "GG1009",
    GM1900: "GG7206",
    GM1901: "GG4506",
    GM1903: "GG6106",
    GM1904: "GG2514",
    GM1911: "GG2707",
    GM1916: "GG7014",
    GM1924: "GG4607",
    GM1926: "GG7014",
    GM1930: "GG4607",
    GM1931: "GG4506",
    GM1940: "GG7206",
    GM1942: "GG3606",
    GM1945: "GG2014",
    GM1948: "GG5406",
    GM1949: "GG7206",
    GM1950: "GG0111",
    GM1952: "GG0111",
    GM1954: "GG6106",
    GM1955: "GG1413",
    GM1959: "GG5206",
    GM1960: "GG2014",
    GM1961: "GG2514",
    GM1963: "GG4816",
    GM1966: "GG0111",
    GM1969: "GG0111",
    GM1970: "GG7206",
    GM1978: "GG4816",
    GM1979: "GG0111",
    GM1980: "GG2707",
    GM1982: "GG5406",
    GM1991: "GG5406",
};
