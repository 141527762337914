export default {
    GM0014: "PV20",
    GM0034: "PV24",
    GM0037: "PV20",
    GM0047: "PV20",
    GM0050: "PV24",
    GM0059: "PV21",
    GM0060: "PV21",
    GM0072: "PV21",
    GM0074: "PV21",
    GM0080: "PV21",
    GM0085: "PV21",
    GM0086: "PV21",
    GM0088: "PV21",
    GM0090: "PV21",
    GM0093: "PV21",
    GM0096: "PV21",
    GM0098: "PV21",
    GM0106: "PV22",
    GM0109: "PV22",
    GM0114: "PV22",
    GM0118: "PV22",
    GM0119: "PV22",
    GM0141: "PV23",
    GM0147: "PV23",
    GM0148: "PV23",
    GM0150: "PV23",
    GM0153: "PV23",
    GM0158: "PV23",
    GM0160: "PV23",
    GM0163: "PV23",
    GM0164: "PV23",
    GM0166: "PV23",
    GM0168: "PV23",
    GM0171: "PV24",
    GM0173: "PV23",
    GM0175: "PV23",
    GM0177: "PV23",
    GM0180: "PV23",
    GM0183: "PV23",
    GM0184: "PV24",
    GM0189: "PV23",
    GM0193: "PV23",
    GM0197: "PV25",
    GM0200: "PV25",
    GM0202: "PV25",
    GM0203: "PV25",
    GM0209: "PV25",
    GM0213: "PV25",
    GM0214: "PV25",
    GM0216: "PV25",
    GM0221: "PV25",
    GM0222: "PV25",
    GM0225: "PV25",
    GM0226: "PV25",
    GM0228: "PV25",
    GM0230: "PV25",
    GM0232: "PV25",
    GM0233: "PV25",
    GM0243: "PV25",
    GM0244: "PV25",
    GM0246: "PV25",
    GM0252: "PV25",
    GM0262: "PV25",
    GM0263: "PV25",
    GM0267: "PV25",
    GM0268: "PV25",
    GM0269: "PV25",
    GM0273: "PV25",
    GM0274: "PV25",
    GM0275: "PV25",
    GM0277: "PV25",
    GM0279: "PV25",
    GM0281: "PV25",
    GM0285: "PV25",
    GM0289: "PV25",
    GM0293: "PV25",
    GM0294: "PV25",
    GM0296: "PV25",
    GM0297: "PV25",
    GM0299: "PV25",
    GM0301: "PV25",
    GM0302: "PV25",
    GM0303: "PV24",
    GM0307: "PV26",
    GM0308: "PV26",
    GM0310: "PV26",
    GM0312: "PV26",
    GM0313: "PV26",
    GM0317: "PV26",
    GM0321: "PV26",
    GM0327: "PV26",
    GM0331: "PV26",
    GM0335: "PV26",
    GM0339: "PV26",
    GM0340: "PV26",
    GM0342: "PV26",
    GM0344: "PV26",
    GM0345: "PV26",
    GM0351: "PV26",
    GM0352: "PV26",
    GM0353: "PV26",
    GM0355: "PV26",
    GM0356: "PV26",
    GM0358: "PV27",
    GM0361: "PV27",
    GM0362: "PV27",
    GM0363: "PV27",
    GM0373: "PV27",
    GM0375: "PV27",
    GM0376: "PV27",
    GM0377: "PV27",
    GM0383: "PV27",
    GM0384: "PV27",
    GM0385: "PV27",
    GM0388: "PV27",
    GM0392: "PV27",
    GM0394: "PV27",
    GM0396: "PV27",
    GM0397: "PV27",
    GM0399: "PV27",
    GM0400: "PV27",
    GM0402: "PV27",
    GM0405: "PV27",
    GM0406: "PV27",
    GM0415: "PV27",
    GM0417: "PV27",
    GM0420: "PV27",
    GM0431: "PV27",
    GM0432: "PV27",
    GM0437: "PV27",
    GM0439: "PV27",
    GM0441: "PV27",
    GM0448: "PV27",
    GM0450: "PV27",
    GM0451: "PV27",
    GM0453: "PV27",
    GM0457: "PV27",
    GM0473: "PV27",
    GM0479: "PV27",
    GM0482: "PV28",
    GM0484: "PV28",
    GM0489: "PV28",
    GM0498: "PV27",
    GM0501: "PV28",
    GM0502: "PV28",
    GM0503: "PV28",
    GM0505: "PV28",
    GM0512: "PV28",
    GM0513: "PV28",
    GM0518: "PV28",
    GM0523: "PV28",
    GM0530: "PV28",
    GM0531: "PV28",
    GM0532: "PV27",
    GM0534: "PV28",
    GM0537: "PV28",
    GM0542: "PV28",
    GM0546: "PV28",
    GM0547: "PV28",
    GM0553: "PV28",
    GM0556: "PV28",
    GM0569: "PV28",
    GM0575: "PV28",
    GM0579: "PV28",
    GM0589: "PV26",
    GM0590: "PV28",
    GM0597: "PV28",
    GM0599: "PV28",
    GM0603: "PV28",
    GM0606: "PV28",
    GM0610: "PV28",
    GM0613: "PV28",
    GM0614: "PV28",
    GM0622: "PV28",
    GM0626: "PV28",
    GM0627: "PV28",
    GM0629: "PV28",
    GM0632: "PV26",
    GM0637: "PV28",
    GM0638: "PV28",
    GM0642: "PV28",
    GM0654: "PV29",
    GM0664: "PV29",
    GM0668: "PV25",
    GM0677: "PV29",
    GM0678: "PV29",
    GM0687: "PV29",
    GM0703: "PV29",
    GM0715: "PV29",
    GM0716: "PV29",
    GM0717: "PV29",
    GM0718: "PV29",
    GM0736: "PV26",
    GM0737: "PV21",
    GM0743: "PV30",
    GM0744: "PV30",
    GM0748: "PV30",
    GM0753: "PV30",
    GM0755: "PV30",
    GM0757: "PV30",
    GM0758: "PV30",
    GM0762: "PV30",
    GM0765: "PV20",
    GM0766: "PV30",
    GM0770: "PV30",
    GM0772: "PV30",
    GM0777: "PV30",
    GM0779: "PV30",
    GM0784: "PV30",
    GM0785: "PV30",
    GM0794: "PV30",
    GM0796: "PV30",
    GM0797: "PV30",
    GM0798: "PV30",
    GM0809: "PV30",
    GM0820: "PV30",
    GM0823: "PV30",
    GM0824: "PV30",
    GM0826: "PV30",
    GM0828: "PV30",
    GM0840: "PV30",
    GM0845: "PV30",
    GM0847: "PV30",
    GM0848: "PV30",
    GM0851: "PV30",
    GM0852: "PV27",
    GM0855: "PV30",
    GM0858: "PV30",
    GM0861: "PV30",
    GM0865: "PV30",
    GM0866: "PV30",
    GM0867: "PV30",
    GM0873: "PV30",
    GM0879: "PV30",
    GM0880: "PV27",
    GM0882: "PV31",
    GM0888: "PV31",
    GM0889: "PV31",
    GM0893: "PV31",
    GM0899: "PV31",
    GM0907: "PV31",
    GM0917: "PV31",
    GM0928: "PV31",
    GM0935: "PV31",
    GM0938: "PV31",
    GM0944: "PV31",
    GM0946: "PV31",
    GM0957: "PV31",
    GM0965: "PV31",
    GM0971: "PV31",
    GM0981: "PV31",
    GM0983: "PV31",
    GM0984: "PV31",
    GM0986: "PV31",
    GM0988: "PV31",
    GM0994: "PV31",
    GM0995: "PV24",
    GM1507: "PV31",
    GM1509: "PV25",
    GM1525: "PV28",
    GM1581: "PV26",
    GM1586: "PV25",
    GM1598: "PV27",
    GM1621: "PV28",
    GM1640: "PV31",
    GM1641: "PV31",
    GM1652: "PV30",
    GM1655: "PV30",
    GM1658: "PV30",
    GM1659: "PV30",
    GM1667: "PV30",
    GM1669: "PV31",
    GM1674: "PV30",
    GM1676: "PV29",
    GM1680: "PV22",
    GM1681: "PV22",
    GM1690: "PV22",
    GM1695: "PV29",
    GM1696: "PV27",
    GM1699: "PV22",
    GM1700: "PV23",
    GM1701: "PV22",
    GM1705: "PV25",
    GM1706: "PV30",
    GM1708: "PV23",
    GM1709: "PV30",
    GM1711: "PV31",
    GM1714: "PV29",
    GM1719: "PV30",
    GM1721: "PV30",
    GM1723: "PV30",
    GM1724: "PV30",
    GM1728: "PV30",
    GM1729: "PV31",
    GM1730: "PV22",
    GM1731: "PV22",
    GM1734: "PV25",
    GM1735: "PV23",
    GM1740: "PV25",
    GM1742: "PV23",
    GM1771: "PV30",
    GM1773: "PV23",
    GM1774: "PV23",
    GM1783: "PV28",
    GM1842: "PV28",
    GM1859: "PV25",
    GM1876: "PV25",
    GM1883: "PV31",
    GM1884: "PV28",
    GM1891: "PV21",
    GM1892: "PV28",
    GM1894: "PV31",
    GM1895: "PV20",
    GM1896: "PV23",
    GM1900: "PV21",
    GM1901: "PV28",
    GM1903: "PV31",
    GM1904: "PV26",
    GM1911: "PV27",
    GM1916: "PV28",
    GM1924: "PV28",
    GM1926: "PV28",
    GM1930: "PV28",
    GM1931: "PV28",
    GM1940: "PV21",
    GM1942: "PV27",
    GM1945: "PV25",
    GM1948: "PV30",
    GM1949: "PV21",
    GM1950: "PV20",
    GM1952: "PV20",
    GM1954: "PV31",
    GM1955: "PV25",
    GM1959: "PV30",
    GM1960: "PV25",
    GM1961: "PV26",
    GM1963: "PV28",
    GM1966: "PV20",
    GM1969: "PV20",
    GM1970: "PV21",
    GM1978: "PV28",
    GM1979: "PV20",
    GM1980: "PV27",
    GM1982: "PV30",
    GM1991: "PV30",
};
