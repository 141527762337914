let languages = [
    {
        id: 1,
        name: "English",
        iso_code: "en",
        flag: "/flags/gb.png",
        flag_code: "GB",
    },
    {
        id: 2,
        name: "Nederlands",
        iso_code: "nl",
        flag: "/flags/nl.png",
        flag_code: "NL",
    },
];

export default languages;
