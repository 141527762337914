<script>
export default {
    name: "trend-section",
};
</script>

<template>
    <div class="trend-section">
        <slot />
    </div>
</template>

<style lang="scss">
.trend-section {
    margin-bottom: 16px;
}
</style>
