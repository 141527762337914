export default {
    GG0111: [
        [
            { x: 7.093, y: 52.838 },
            { x: 7.072, y: 52.845 },
            { x: 7.04, y: 52.873 },
            { x: 7.015, y: 52.873 },
            { x: 7.046, y: 52.916 },
            { x: 7.016, y: 52.925 },
            { x: 6.937, y: 52.992 },
            { x: 6.737, y: 53.119 },
            { x: 6.695, y: 53.121 },
            { x: 6.637, y: 53.113 },
            { x: 6.611, y: 53.138 },
            { x: 6.609, y: 53.138 },
            { x: 6.564, y: 53.158 },
            { x: 6.548, y: 53.181 },
            { x: 6.53, y: 53.195 },
            { x: 6.486, y: 53.204 },
            { x: 6.448, y: 53.196 },
            { x: 6.443, y: 53.188 },
            { x: 6.408, y: 53.178 },
            { x: 6.383, y: 53.15 },
            { x: 6.344, y: 53.087 },
            { x: 6.315, y: 53.094 },
            { x: 6.262, y: 53.114 },
            { x: 6.206, y: 53.115 },
            { x: 6.176, y: 53.135 },
            { x: 6.177, y: 53.167 },
            { x: 6.2, y: 53.195 },
            { x: 6.23, y: 53.218 },
            { x: 6.218, y: 53.242 },
            { x: 6.256, y: 53.271 },
            { x: 6.254, y: 53.289 },
            { x: 6.279, y: 53.303 },
            { x: 6.287, y: 53.341 },
            { x: 6.253, y: 53.349 },
            { x: 6.232, y: 53.367 },
            { x: 6.217, y: 53.364 },
            { x: 6.197, y: 53.391 },
            { x: 6.191, y: 53.411 },
            { x: 6.261, y: 53.414 },
            { x: 6.313, y: 53.398 },
            { x: 6.368, y: 53.416 },
            { x: 6.396, y: 53.421 },
            { x: 6.567, y: 53.435 },
            { x: 6.646, y: 53.455 },
            { x: 6.744, y: 53.466 },
            { x: 6.798, y: 53.455 },
            { x: 6.814, y: 53.463 },
            { x: 6.863, y: 53.451 },
            { x: 6.882, y: 53.44 },
            { x: 6.874, y: 53.408 },
            { x: 6.888, y: 53.396 },
            { x: 6.897, y: 53.356 },
            { x: 6.93, y: 53.335 },
            { x: 6.928, y: 53.328 },
            { x: 7.027, y: 53.302 },
            { x: 7.084, y: 53.298 },
            { x: 7.078, y: 53.267 },
            { x: 7.104, y: 53.252 },
            { x: 7.159, y: 53.246 },
            { x: 7.207, y: 53.237 },
            { x: 7.218, y: 53.198 },
            { x: 7.179, y: 53.139 },
            { x: 7.183, y: 53.122 },
            { x: 7.203, y: 53.113 },
            { x: 7.199, y: 53.081 },
            { x: 7.213, y: 53.011 },
            { x: 7.182, y: 52.942 },
            { x: 7.104, y: 52.864 },
            { x: 7.087, y: 52.85 },
            { x: 7.093, y: 52.838 },
        ],
        [
            { x: 6.499, y: 53.55 },
            { x: 6.51, y: 53.54 },
            { x: 6.473, y: 53.524 },
            { x: 6.459, y: 53.541 },
            { x: 6.478, y: 53.554 },
            { x: 6.499, y: 53.55 },
        ],
    ],
    GG0706: [
        [
            { x: 6.609, y: 53.138 },
            { x: 6.611, y: 53.138 },
            { x: 6.637, y: 53.113 },
            { x: 6.695, y: 53.121 },
            { x: 6.737, y: 53.119 },
            { x: 6.937, y: 52.992 },
            { x: 7.016, y: 52.925 },
            { x: 7.046, y: 52.916 },
            { x: 7.015, y: 52.873 },
            { x: 7.04, y: 52.873 },
            { x: 7.072, y: 52.845 },
            { x: 7.093, y: 52.838 },
            { x: 7.072, y: 52.81 },
            { x: 7.064, y: 52.723 },
            { x: 7.055, y: 52.644 },
            { x: 7.042, y: 52.633 },
            { x: 6.975, y: 52.646 },
            { x: 6.939, y: 52.638 },
            { x: 6.897, y: 52.651 },
            { x: 6.822, y: 52.648 },
            { x: 6.777, y: 52.652 },
            { x: 6.742, y: 52.645 },
            { x: 6.71, y: 52.628 },
            { x: 6.708, y: 52.649 },
            { x: 6.615, y: 52.674 },
            { x: 6.553, y: 52.666 },
            { x: 6.514, y: 52.646 },
            { x: 6.519, y: 52.614 },
            { x: 6.464, y: 52.624 },
            { x: 6.453, y: 52.614 },
            { x: 6.406, y: 52.621 },
            { x: 6.384, y: 52.612 },
            { x: 6.364, y: 52.643 },
            { x: 6.326, y: 52.66 },
            { x: 6.323, y: 52.67 },
            { x: 6.273, y: 52.665 },
            { x: 6.248, y: 52.676 },
            { x: 6.202, y: 52.685 },
            { x: 6.183, y: 52.675 },
            { x: 6.163, y: 52.68 },
            { x: 6.12, y: 52.75 },
            { x: 6.156, y: 52.763 },
            { x: 6.202, y: 52.794 },
            { x: 6.137, y: 52.839 },
            { x: 6.12, y: 52.854 },
            { x: 6.207, y: 52.891 },
            { x: 6.257, y: 52.928 },
            { x: 6.303, y: 52.925 },
            { x: 6.333, y: 52.906 },
            { x: 6.393, y: 52.933 },
            { x: 6.428, y: 52.972 },
            { x: 6.363, y: 53.034 },
            { x: 6.368, y: 53.067 },
            { x: 6.305, y: 53.081 },
            { x: 6.315, y: 53.094 },
            { x: 6.344, y: 53.087 },
            { x: 6.383, y: 53.15 },
            { x: 6.408, y: 53.178 },
            { x: 6.443, y: 53.188 },
            { x: 6.448, y: 53.196 },
            { x: 6.486, y: 53.204 },
            { x: 6.53, y: 53.195 },
            { x: 6.548, y: 53.181 },
            { x: 6.564, y: 53.158 },
            { x: 6.609, y: 53.138 },
        ],
    ],
    GG1009: [
        [
            { x: 6.71, y: 52.628 },
            { x: 6.727, y: 52.615 },
            { x: 6.719, y: 52.589 },
            { x: 6.767, y: 52.564 },
            { x: 6.726, y: 52.563 },
            { x: 6.716, y: 52.549 },
            { x: 6.681, y: 52.553 },
            { x: 6.705, y: 52.521 },
            { x: 6.698, y: 52.486 },
            { x: 6.669, y: 52.495 },
            { x: 6.641, y: 52.471 },
            { x: 6.611, y: 52.467 },
            { x: 6.601, y: 52.479 },
            { x: 6.554, y: 52.488 },
            { x: 6.505, y: 52.491 },
            { x: 6.504, y: 52.482 },
            { x: 6.451, y: 52.469 },
            { x: 6.438, y: 52.444 },
            { x: 6.39, y: 52.428 },
            { x: 6.405, y: 52.416 },
            { x: 6.389, y: 52.38 },
            { x: 6.343, y: 52.37 },
            { x: 6.334, y: 52.351 },
            { x: 6.356, y: 52.318 },
            { x: 6.327, y: 52.302 },
            { x: 6.329, y: 52.281 },
            { x: 6.371, y: 52.26 },
            { x: 6.382, y: 52.246 },
            { x: 6.351, y: 52.242 },
            { x: 6.339, y: 52.227 },
            { x: 6.293, y: 52.231 },
            { x: 6.251, y: 52.227 },
            { x: 6.148, y: 52.228 },
            { x: 6.124, y: 52.251 },
            { x: 6.13, y: 52.261 },
            { x: 6.099, y: 52.3 },
            { x: 6.084, y: 52.302 },
            { x: 6.071, y: 52.322 },
            { x: 6.082, y: 52.372 },
            { x: 6.124, y: 52.381 },
            { x: 6.13, y: 52.399 },
            { x: 6.111, y: 52.408 },
            { x: 6.119, y: 52.433 },
            { x: 6.105, y: 52.443 },
            { x: 6.102, y: 52.466 },
            { x: 6.071, y: 52.482 },
            { x: 6.054, y: 52.5 },
            { x: 6.028, y: 52.51 },
            { x: 6.008, y: 52.504 },
            { x: 5.965, y: 52.476 },
            { x: 5.926, y: 52.474 },
            { x: 5.91, y: 52.498 },
            { x: 5.887, y: 52.517 },
            { x: 5.866, y: 52.519 },
            { x: 5.857, y: 52.544 },
            { x: 5.857, y: 52.546 },
            { x: 5.824, y: 52.58 },
            { x: 5.843, y: 52.611 },
            { x: 5.896, y: 52.618 },
            { x: 5.918, y: 52.613 },
            { x: 5.99, y: 52.626 },
            { x: 6.019, y: 52.643 },
            { x: 5.984, y: 52.657 },
            { x: 5.934, y: 52.676 },
            { x: 5.965, y: 52.694 },
            { x: 5.943, y: 52.715 },
            { x: 5.922, y: 52.751 },
            { x: 5.85, y: 52.785 },
            { x: 5.819, y: 52.788 },
            { x: 5.795, y: 52.807 },
            { x: 5.82, y: 52.817 },
            { x: 5.844, y: 52.805 },
            { x: 5.876, y: 52.801 },
            { x: 5.924, y: 52.824 },
            { x: 5.93, y: 52.835 },
            { x: 5.972, y: 52.842 },
            { x: 5.997, y: 52.817 },
            { x: 6.031, y: 52.815 },
            { x: 6.12, y: 52.854 },
            { x: 6.137, y: 52.839 },
            { x: 6.202, y: 52.794 },
            { x: 6.156, y: 52.763 },
            { x: 6.12, y: 52.75 },
            { x: 6.163, y: 52.68 },
            { x: 6.183, y: 52.675 },
            { x: 6.202, y: 52.685 },
            { x: 6.248, y: 52.676 },
            { x: 6.273, y: 52.665 },
            { x: 6.323, y: 52.67 },
            { x: 6.326, y: 52.66 },
            { x: 6.364, y: 52.643 },
            { x: 6.384, y: 52.612 },
            { x: 6.406, y: 52.621 },
            { x: 6.453, y: 52.614 },
            { x: 6.464, y: 52.624 },
            { x: 6.519, y: 52.614 },
            { x: 6.514, y: 52.646 },
            { x: 6.553, y: 52.666 },
            { x: 6.615, y: 52.674 },
            { x: 6.708, y: 52.649 },
            { x: 6.71, y: 52.628 },
        ],
    ],
    GG1106: [
        [
            { x: 6.698, y: 52.486 },
            { x: 6.753, y: 52.464 },
            { x: 6.775, y: 52.46 },
            { x: 6.854, y: 52.46 },
            { x: 6.862, y: 52.451 },
            { x: 6.942, y: 52.435 },
            { x: 6.962, y: 52.445 },
            { x: 6.978, y: 52.466 },
            { x: 6.994, y: 52.465 },
            { x: 7.011, y: 52.429 },
            { x: 7.036, y: 52.403 },
            { x: 7.059, y: 52.399 },
            { x: 7.072, y: 52.373 },
            { x: 7.072, y: 52.352 },
            { x: 7.056, y: 52.338 },
            { x: 7.026, y: 52.292 },
            { x: 7.028, y: 52.278 },
            { x: 7.061, y: 52.235 },
            { x: 7.019, y: 52.225 },
            { x: 6.989, y: 52.227 },
            { x: 6.951, y: 52.181 },
            { x: 6.916, y: 52.178 },
            { x: 6.882, y: 52.156 },
            { x: 6.873, y: 52.133 },
            { x: 6.855, y: 52.12 },
            { x: 6.761, y: 52.119 },
            { x: 6.716, y: 52.118 },
            { x: 6.663, y: 52.13 },
            { x: 6.674, y: 52.143 },
            { x: 6.671, y: 52.166 },
            { x: 6.645, y: 52.174 },
            { x: 6.612, y: 52.163 },
            { x: 6.59, y: 52.182 },
            { x: 6.555, y: 52.177 },
            { x: 6.479, y: 52.183 },
            { x: 6.429, y: 52.223 },
            { x: 6.416, y: 52.242 },
            { x: 6.382, y: 52.246 },
            { x: 6.371, y: 52.26 },
            { x: 6.329, y: 52.281 },
            { x: 6.327, y: 52.302 },
            { x: 6.356, y: 52.318 },
            { x: 6.334, y: 52.351 },
            { x: 6.343, y: 52.37 },
            { x: 6.389, y: 52.38 },
            { x: 6.405, y: 52.416 },
            { x: 6.39, y: 52.428 },
            { x: 6.438, y: 52.444 },
            { x: 6.451, y: 52.469 },
            { x: 6.504, y: 52.482 },
            { x: 6.505, y: 52.491 },
            { x: 6.554, y: 52.488 },
            { x: 6.601, y: 52.479 },
            { x: 6.611, y: 52.467 },
            { x: 6.641, y: 52.471 },
            { x: 6.669, y: 52.495 },
            { x: 6.698, y: 52.486 },
        ],
    ],
    GG1413: [
        [
            { x: 6.382, y: 52.246 },
            { x: 6.416, y: 52.242 },
            { x: 6.429, y: 52.223 },
            { x: 6.479, y: 52.183 },
            { x: 6.555, y: 52.177 },
            { x: 6.59, y: 52.182 },
            { x: 6.612, y: 52.163 },
            { x: 6.645, y: 52.174 },
            { x: 6.671, y: 52.166 },
            { x: 6.674, y: 52.143 },
            { x: 6.663, y: 52.13 },
            { x: 6.716, y: 52.118 },
            { x: 6.761, y: 52.119 },
            { x: 6.751, y: 52.085 },
            { x: 6.735, y: 52.075 },
            { x: 6.696, y: 52.07 },
            { x: 6.688, y: 52.04 },
            { x: 6.714, y: 52.04 },
            { x: 6.753, y: 52.028 },
            { x: 6.827, y: 51.994 },
            { x: 6.829, y: 51.964 },
            { x: 6.799, y: 51.959 },
            { x: 6.794, y: 51.935 },
            { x: 6.77, y: 51.916 },
            { x: 6.722, y: 51.896 },
            { x: 6.693, y: 51.915 },
            { x: 6.675, y: 51.916 },
            { x: 6.638, y: 51.904 },
            { x: 6.586, y: 51.894 },
            { x: 6.556, y: 51.882 },
            { x: 6.501, y: 51.868 },
            { x: 6.473, y: 51.854 },
            { x: 6.451, y: 51.865 },
            { x: 6.429, y: 51.865 },
            { x: 6.408, y: 51.854 },
            { x: 6.407, y: 51.828 },
            { x: 6.363, y: 51.835 },
            { x: 6.345, y: 51.851 },
            { x: 6.306, y: 51.849 },
            { x: 6.301, y: 51.865 },
            { x: 6.28, y: 51.874 },
            { x: 6.262, y: 51.868 },
            { x: 6.215, y: 51.868 },
            { x: 6.184, y: 51.883 },
            { x: 6.168, y: 51.901 },
            { x: 6.091, y: 51.939 },
            { x: 6.09, y: 51.968 },
            { x: 6.129, y: 51.978 },
            { x: 6.16, y: 51.981 },
            { x: 6.161, y: 52.014 },
            { x: 6.177, y: 52.019 },
            { x: 6.176, y: 52.039 },
            { x: 6.135, y: 52.035 },
            { x: 6.111, y: 52.042 },
            { x: 6.132, y: 52.056 },
            { x: 6.103, y: 52.078 },
            { x: 6.068, y: 52.088 },
            { x: 6.024, y: 52.067 },
            { x: 5.99, y: 52.079 },
            { x: 5.956, y: 52.073 },
            { x: 5.946, y: 52.099 },
            { x: 5.883, y: 52.106 },
            { x: 5.865, y: 52.126 },
            { x: 5.86, y: 52.149 },
            { x: 5.81, y: 52.151 },
            { x: 5.792, y: 52.197 },
            { x: 5.751, y: 52.194 },
            { x: 5.731, y: 52.209 },
            { x: 5.742, y: 52.22 },
            { x: 5.726, y: 52.243 },
            { x: 5.684, y: 52.241 },
            { x: 5.681, y: 52.225 },
            { x: 5.633, y: 52.208 },
            { x: 5.55, y: 52.202 },
            { x: 5.516, y: 52.234 },
            { x: 5.484, y: 52.239 },
            { x: 5.479, y: 52.258 },
            { x: 5.527, y: 52.264 },
            { x: 5.562, y: 52.315 },
            { x: 5.611, y: 52.346 },
            { x: 5.614, y: 52.362 },
            { x: 5.615, y: 52.363 },
            { x: 5.653, y: 52.365 },
            { x: 5.678, y: 52.379 },
            { x: 5.7, y: 52.381 },
            { x: 5.74, y: 52.398 },
            { x: 5.81, y: 52.435 },
            { x: 5.817, y: 52.457 },
            { x: 5.84, y: 52.466 },
            { x: 5.866, y: 52.519 },
            { x: 5.887, y: 52.517 },
            { x: 5.91, y: 52.498 },
            { x: 5.926, y: 52.474 },
            { x: 5.965, y: 52.476 },
            { x: 6.008, y: 52.504 },
            { x: 6.028, y: 52.51 },
            { x: 6.054, y: 52.5 },
            { x: 6.071, y: 52.482 },
            { x: 6.102, y: 52.466 },
            { x: 6.105, y: 52.443 },
            { x: 6.119, y: 52.433 },
            { x: 6.111, y: 52.408 },
            { x: 6.13, y: 52.399 },
            { x: 6.124, y: 52.381 },
            { x: 6.082, y: 52.372 },
            { x: 6.071, y: 52.322 },
            { x: 6.084, y: 52.302 },
            { x: 6.099, y: 52.3 },
            { x: 6.13, y: 52.261 },
            { x: 6.124, y: 52.251 },
            { x: 6.148, y: 52.228 },
            { x: 6.251, y: 52.227 },
            { x: 6.293, y: 52.231 },
            { x: 6.339, y: 52.227 },
            { x: 6.351, y: 52.242 },
            { x: 6.382, y: 52.246 },
        ],
    ],
    GG1911: [
        [
            { x: 5.479, y: 52.258 },
            { x: 5.484, y: 52.239 },
            { x: 5.516, y: 52.234 },
            { x: 5.55, y: 52.202 },
            { x: 5.633, y: 52.208 },
            { x: 5.681, y: 52.225 },
            { x: 5.684, y: 52.241 },
            { x: 5.726, y: 52.243 },
            { x: 5.742, y: 52.22 },
            { x: 5.731, y: 52.209 },
            { x: 5.751, y: 52.194 },
            { x: 5.792, y: 52.197 },
            { x: 5.81, y: 52.151 },
            { x: 5.86, y: 52.149 },
            { x: 5.865, y: 52.126 },
            { x: 5.883, y: 52.106 },
            { x: 5.946, y: 52.099 },
            { x: 5.956, y: 52.073 },
            { x: 5.99, y: 52.079 },
            { x: 6.024, y: 52.067 },
            { x: 6.068, y: 52.088 },
            { x: 6.103, y: 52.078 },
            { x: 6.132, y: 52.056 },
            { x: 6.111, y: 52.042 },
            { x: 6.135, y: 52.035 },
            { x: 6.176, y: 52.039 },
            { x: 6.177, y: 52.019 },
            { x: 6.161, y: 52.014 },
            { x: 6.16, y: 51.981 },
            { x: 6.129, y: 51.978 },
            { x: 6.09, y: 51.968 },
            { x: 6.091, y: 51.939 },
            { x: 6.168, y: 51.901 },
            { x: 6.157, y: 51.905 },
            { x: 6.11, y: 51.895 },
            { x: 6.137, y: 51.886 },
            { x: 6.145, y: 51.87 },
            { x: 6.167, y: 51.862 },
            { x: 6.167, y: 51.841 },
            { x: 6.107, y: 51.848 },
            { x: 6.063, y: 51.865 },
            { x: 6.017, y: 51.881 },
            { x: 5.994, y: 51.876 },
            { x: 5.981, y: 51.86 },
            { x: 5.955, y: 51.857 },
            { x: 5.921, y: 51.875 },
            { x: 5.881, y: 51.869 },
            { x: 5.871, y: 51.889 },
            { x: 5.857, y: 51.895 },
            { x: 5.832, y: 51.872 },
            { x: 5.726, y: 51.887 },
            { x: 5.707, y: 51.893 },
            { x: 5.706, y: 51.908 },
            { x: 5.681, y: 51.918 },
            { x: 5.661, y: 51.944 },
            { x: 5.63, y: 51.936 },
            { x: 5.606, y: 51.943 },
            { x: 5.627, y: 51.952 },
            { x: 5.626, y: 51.974 },
            { x: 5.614, y: 51.99 },
            { x: 5.591, y: 52.003 },
            { x: 5.587, y: 52.031 },
            { x: 5.558, y: 52.049 },
            { x: 5.563, y: 52.078 },
            { x: 5.554, y: 52.1 },
            { x: 5.523, y: 52.078 },
            { x: 5.484, y: 52.07 },
            { x: 5.459, y: 52.08 },
            { x: 5.486, y: 52.094 },
            { x: 5.499, y: 52.147 },
            { x: 5.48, y: 52.166 },
            { x: 5.44, y: 52.171 },
            { x: 5.439, y: 52.202 },
            { x: 5.393, y: 52.221 },
            { x: 5.404, y: 52.247 },
            { x: 5.467, y: 52.256 },
            { x: 5.47, y: 52.263 },
            { x: 5.471, y: 52.264 },
            { x: 5.479, y: 52.258 },
        ],
    ],
    GG2014: [
        [
            { x: 5.606, y: 51.943 },
            { x: 5.63, y: 51.936 },
            { x: 5.661, y: 51.944 },
            { x: 5.681, y: 51.918 },
            { x: 5.706, y: 51.908 },
            { x: 5.707, y: 51.893 },
            { x: 5.726, y: 51.887 },
            { x: 5.832, y: 51.872 },
            { x: 5.857, y: 51.895 },
            { x: 5.871, y: 51.889 },
            { x: 5.881, y: 51.869 },
            { x: 5.921, y: 51.875 },
            { x: 5.955, y: 51.857 },
            { x: 5.981, y: 51.86 },
            { x: 5.994, y: 51.876 },
            { x: 6.017, y: 51.881 },
            { x: 6.063, y: 51.865 },
            { x: 6.036, y: 51.843 },
            { x: 5.987, y: 51.831 },
            { x: 5.963, y: 51.837 },
            { x: 5.945, y: 51.824 },
            { x: 5.979, y: 51.798 },
            { x: 5.991, y: 51.766 },
            { x: 5.953, y: 51.748 },
            { x: 5.915, y: 51.753 },
            { x: 5.893, y: 51.778 },
            { x: 5.868, y: 51.776 },
            { x: 5.864, y: 51.758 },
            { x: 5.78, y: 51.752 },
            { x: 5.745, y: 51.759 },
            { x: 5.732, y: 51.772 },
            { x: 5.657, y: 51.798 },
            { x: 5.635, y: 51.82 },
            { x: 5.589, y: 51.83 },
            { x: 5.558, y: 51.828 },
            { x: 5.541, y: 51.816 },
            { x: 5.486, y: 51.829 },
            { x: 5.474, y: 51.815 },
            { x: 5.439, y: 51.81 },
            { x: 5.402, y: 51.821 },
            { x: 5.369, y: 51.79 },
            { x: 5.354, y: 51.755 },
            { x: 5.296, y: 51.737 },
            { x: 5.245, y: 51.734 },
            { x: 5.216, y: 51.743 },
            { x: 5.163, y: 51.743 },
            { x: 5.128, y: 51.738 },
            { x: 5.138, y: 51.751 },
            { x: 5.138, y: 51.773 },
            { x: 5.096, y: 51.788 },
            { x: 5.067, y: 51.781 },
            { x: 5.048, y: 51.798 },
            { x: 5.016, y: 51.808 },
            { x: 5, y: 51.821 },
            { x: 5.026, y: 51.819 },
            { x: 5.027, y: 51.859 },
            { x: 5.059, y: 51.858 },
            { x: 5.056, y: 51.873 },
            { x: 5.081, y: 51.876 },
            { x: 5.09, y: 51.889 },
            { x: 5.113, y: 51.888 },
            { x: 5.132, y: 51.914 },
            { x: 5.18, y: 51.967 },
            { x: 5.206, y: 51.959 },
            { x: 5.247, y: 51.977 },
            { x: 5.27, y: 51.965 },
            { x: 5.333, y: 51.957 },
            { x: 5.348, y: 51.968 },
            { x: 5.383, y: 51.969 },
            { x: 5.442, y: 51.986 },
            { x: 5.486, y: 51.984 },
            { x: 5.54, y: 51.967 },
            { x: 5.561, y: 51.955 },
            { x: 5.606, y: 51.943 },
        ],
    ],
    GG2209: [
        [
            { x: 5.795, y: 52.807 },
            { x: 5.819, y: 52.788 },
            { x: 5.85, y: 52.785 },
            { x: 5.922, y: 52.751 },
            { x: 5.943, y: 52.715 },
            { x: 5.965, y: 52.694 },
            { x: 5.934, y: 52.676 },
            { x: 5.984, y: 52.657 },
            { x: 5.964, y: 52.639 },
            { x: 5.832, y: 52.613 },
            { x: 5.663, y: 52.613 },
            { x: 5.655, y: 52.597 },
            { x: 5.756, y: 52.582 },
            { x: 5.813, y: 52.577 },
            { x: 5.857, y: 52.546 },
            { x: 5.857, y: 52.544 },
            { x: 5.857, y: 52.518 },
            { x: 5.848, y: 52.493 },
            { x: 5.817, y: 52.457 },
            { x: 5.797, y: 52.439 },
            { x: 5.747, y: 52.415 },
            { x: 5.72, y: 52.416 },
            { x: 5.661, y: 52.398 },
            { x: 5.625, y: 52.38 },
            { x: 5.615, y: 52.363 },
            { x: 5.614, y: 52.362 },
            { x: 5.564, y: 52.368 },
            { x: 5.538, y: 52.35 },
            { x: 5.553, y: 52.321 },
            { x: 5.529, y: 52.275 },
            { x: 5.471, y: 52.264 },
            { x: 5.47, y: 52.263 },
            { x: 5.41, y: 52.253 },
            { x: 5.314, y: 52.303 },
            { x: 5.313, y: 52.303 },
            { x: 5.268, y: 52.322 },
            { x: 5.221, y: 52.334 },
            { x: 5.184, y: 52.335 },
            { x: 5.139, y: 52.326 },
            { x: 5.138, y: 52.326 },
            { x: 5.125, y: 52.381 },
            { x: 5.162, y: 52.4 },
            { x: 5.258, y: 52.435 },
            { x: 5.435, y: 52.511 },
            { x: 5.426, y: 52.53 },
            { x: 5.446, y: 52.561 },
            { x: 5.467, y: 52.572 },
            { x: 5.455, y: 52.594 },
            { x: 5.399, y: 52.649 },
            { x: 5.36, y: 52.674 },
            { x: 5.305, y: 52.69 },
            { x: 5.287, y: 52.69 },
            { x: 5.287, y: 52.691 },
            { x: 5.302, y: 52.692 },
            { x: 5.354, y: 52.678 },
            { x: 5.361, y: 52.674 },
            { x: 5.4, y: 52.649 },
            { x: 5.456, y: 52.594 },
            { x: 5.469, y: 52.57 },
            { x: 5.456, y: 52.55 },
            { x: 5.501, y: 52.556 },
            { x: 5.559, y: 52.592 },
            { x: 5.628, y: 52.605 },
            { x: 5.652, y: 52.617 },
            { x: 5.616, y: 52.651 },
            { x: 5.593, y: 52.663 },
            { x: 5.595, y: 52.763 },
            { x: 5.66, y: 52.828 },
            { x: 5.713, y: 52.838 },
            { x: 5.749, y: 52.84 },
            { x: 5.795, y: 52.807 },
        ],
    ],
    GG2514: [
        [
            { x: 5.022, y: 52.302 },
            { x: 5.043, y: 52.282 },
            { x: 5.027, y: 52.264 },
            { x: 5.058, y: 52.235 },
            { x: 5.042, y: 52.231 },
            { x: 5.033, y: 52.21 },
            { x: 5.036, y: 52.21 },
            { x: 5.046, y: 52.166 },
            { x: 5.121, y: 52.181 },
            { x: 5.193, y: 52.178 },
            { x: 5.217, y: 52.205 },
            { x: 5.223, y: 52.225 },
            { x: 5.266, y: 52.282 },
            { x: 5.306, y: 52.278 },
            { x: 5.357, y: 52.27 },
            { x: 5.404, y: 52.247 },
            { x: 5.393, y: 52.221 },
            { x: 5.439, y: 52.202 },
            { x: 5.44, y: 52.171 },
            { x: 5.48, y: 52.166 },
            { x: 5.499, y: 52.147 },
            { x: 5.486, y: 52.094 },
            { x: 5.459, y: 52.08 },
            { x: 5.484, y: 52.07 },
            { x: 5.523, y: 52.078 },
            { x: 5.554, y: 52.1 },
            { x: 5.563, y: 52.078 },
            { x: 5.558, y: 52.049 },
            { x: 5.587, y: 52.031 },
            { x: 5.591, y: 52.003 },
            { x: 5.614, y: 51.99 },
            { x: 5.626, y: 51.974 },
            { x: 5.627, y: 51.952 },
            { x: 5.606, y: 51.943 },
            { x: 5.561, y: 51.955 },
            { x: 5.54, y: 51.967 },
            { x: 5.486, y: 51.984 },
            { x: 5.442, y: 51.986 },
            { x: 5.383, y: 51.969 },
            { x: 5.348, y: 51.968 },
            { x: 5.333, y: 51.957 },
            { x: 5.27, y: 51.965 },
            { x: 5.247, y: 51.977 },
            { x: 5.206, y: 51.959 },
            { x: 5.18, y: 51.967 },
            { x: 5.132, y: 51.914 },
            { x: 5.113, y: 51.888 },
            { x: 5.09, y: 51.889 },
            { x: 5.081, y: 51.876 },
            { x: 5.056, y: 51.873 },
            { x: 5.059, y: 51.858 },
            { x: 5.027, y: 51.859 },
            { x: 5.001, y: 51.875 },
            { x: 4.995, y: 51.902 },
            { x: 4.956, y: 51.916 },
            { x: 4.939, y: 51.929 },
            { x: 4.932, y: 51.948 },
            { x: 4.878, y: 51.938 },
            { x: 4.86, y: 51.968 },
            { x: 4.818, y: 52 },
            { x: 4.857, y: 52.006 },
            { x: 4.847, y: 52.018 },
            { x: 4.803, y: 52.014 },
            { x: 4.83, y: 52.05 },
            { x: 4.868, y: 52.063 },
            { x: 4.826, y: 52.075 },
            { x: 4.825, y: 52.107 },
            { x: 4.792, y: 52.122 },
            { x: 4.813, y: 52.14 },
            { x: 4.833, y: 52.145 },
            { x: 4.875, y: 52.139 },
            { x: 4.874, y: 52.156 },
            { x: 4.892, y: 52.162 },
            { x: 4.844, y: 52.18 },
            { x: 4.814, y: 52.202 },
            { x: 4.795, y: 52.227 },
            { x: 4.843, y: 52.237 },
            { x: 4.87, y: 52.253 },
            { x: 4.911, y: 52.253 },
            { x: 4.929, y: 52.268 },
            { x: 4.997, y: 52.289 },
            { x: 5.022, y: 52.302 },
        ],
    ],
    GG2707: [
        [
            { x: 5.287, y: 52.691 },
            { x: 5.287, y: 52.69 },
            { x: 5.246, y: 52.686 },
            { x: 5.236, y: 52.657 },
            { x: 5.199, y: 52.636 },
            { x: 5.159, y: 52.623 },
            { x: 5.128, y: 52.62 },
            { x: 5.089, y: 52.643 },
            { x: 5.068, y: 52.635 },
            { x: 5.046, y: 52.642 },
            { x: 5.019, y: 52.63 },
            { x: 5.02, y: 52.612 },
            { x: 5.005, y: 52.598 },
            { x: 4.95, y: 52.595 },
            { x: 4.915, y: 52.609 },
            { x: 4.866, y: 52.574 },
            { x: 4.842, y: 52.543 },
            { x: 4.782, y: 52.52 },
            { x: 4.719, y: 52.552 },
            { x: 4.695, y: 52.544 },
            { x: 4.696, y: 52.527 },
            { x: 4.66, y: 52.532 },
            { x: 4.598, y: 52.531 },
            { x: 4.615, y: 52.596 },
            { x: 4.627, y: 52.665 },
            { x: 4.638, y: 52.708 },
            { x: 4.637, y: 52.729 },
            { x: 4.653, y: 52.769 },
            { x: 4.676, y: 52.801 },
            { x: 4.706, y: 52.874 },
            { x: 4.717, y: 52.947 },
            { x: 4.731, y: 52.963 },
            { x: 4.763, y: 52.965 },
            { x: 4.779, y: 52.955 },
            { x: 4.809, y: 52.911 },
            { x: 4.875, y: 52.888 },
            { x: 4.936, y: 52.904 },
            { x: 4.967, y: 52.93 },
            { x: 5.054, y: 52.941 },
            { x: 5.166, y: 53 },
            { x: 5.167, y: 52.999 },
            { x: 5.039, y: 52.931 },
            { x: 5.048, y: 52.924 },
            { x: 5.114, y: 52.846 },
            { x: 5.104, y: 52.774 },
            { x: 5.146, y: 52.742 },
            { x: 5.18, y: 52.741 },
            { x: 5.193, y: 52.755 },
            { x: 5.258, y: 52.753 },
            { x: 5.283, y: 52.742 },
            { x: 5.287, y: 52.691 },
        ],
        [
            { x: 4.879, y: 53.157 },
            { x: 4.908, y: 53.135 },
            { x: 4.9, y: 53.125 },
            { x: 4.897, y: 53.079 },
            { x: 4.847, y: 53.034 },
            { x: 4.819, y: 53.029 },
            { x: 4.774, y: 52.996 },
            { x: 4.72, y: 52.985 },
            { x: 4.709, y: 53.022 },
            { x: 4.724, y: 53.068 },
            { x: 4.758, y: 53.106 },
            { x: 4.841, y: 53.183 },
            { x: 4.861, y: 53.183 },
            { x: 4.879, y: 53.157 },
        ],
    ],
    GG3109: [
        [
            { x: 4.782, y: 52.52 },
            { x: 4.715, y: 52.493 },
            { x: 4.698, y: 52.471 },
            { x: 4.675, y: 52.456 },
            { x: 4.711, y: 52.436 },
            { x: 4.739, y: 52.431 },
            { x: 4.729, y: 52.401 },
            { x: 4.757, y: 52.397 },
            { x: 4.756, y: 52.356 },
            { x: 4.817, y: 52.327 },
            { x: 4.817, y: 52.312 },
            { x: 4.788, y: 52.289 },
            { x: 4.726, y: 52.263 },
            { x: 4.702, y: 52.244 },
            { x: 4.67, y: 52.231 },
            { x: 4.633, y: 52.216 },
            { x: 4.603, y: 52.215 },
            { x: 4.557, y: 52.219 },
            { x: 4.568, y: 52.269 },
            { x: 4.589, y: 52.281 },
            { x: 4.612, y: 52.314 },
            { x: 4.569, y: 52.316 },
            { x: 4.564, y: 52.309 },
            { x: 4.494, y: 52.328 },
            { x: 4.555, y: 52.423 },
            { x: 4.567, y: 52.468 },
            { x: 4.587, y: 52.499 },
            { x: 4.598, y: 52.531 },
            { x: 4.66, y: 52.532 },
            { x: 4.696, y: 52.527 },
            { x: 4.695, y: 52.544 },
            { x: 4.719, y: 52.552 },
            { x: 4.782, y: 52.52 },
        ],
    ],
    GG3406: [
        [
            { x: 5.068, y: 52.417 },
            { x: 5.029, y: 52.399 },
            { x: 5.014, y: 52.381 },
            { x: 4.985, y: 52.365 },
            { x: 5.028, y: 52.338 },
            { x: 5.013, y: 52.33 },
            { x: 5.022, y: 52.302 },
            { x: 4.997, y: 52.289 },
            { x: 4.929, y: 52.268 },
            { x: 4.911, y: 52.253 },
            { x: 4.87, y: 52.253 },
            { x: 4.843, y: 52.237 },
            { x: 4.795, y: 52.227 },
            { x: 4.725, y: 52.213 },
            { x: 4.724, y: 52.232 },
            { x: 4.686, y: 52.227 },
            { x: 4.67, y: 52.231 },
            { x: 4.702, y: 52.244 },
            { x: 4.726, y: 52.263 },
            { x: 4.788, y: 52.289 },
            { x: 4.817, y: 52.312 },
            { x: 4.817, y: 52.327 },
            { x: 4.756, y: 52.356 },
            { x: 4.757, y: 52.397 },
            { x: 4.729, y: 52.401 },
            { x: 4.739, y: 52.431 },
            { x: 4.856, y: 52.417 },
            { x: 4.866, y: 52.431 },
            { x: 4.899, y: 52.424 },
            { x: 4.931, y: 52.412 },
            { x: 4.948, y: 52.422 },
            { x: 5, y: 52.425 },
            { x: 5.049, y: 52.415 },
            { x: 5.068, y: 52.417 },
        ],
    ],
    GG3606: [
        [
            { x: 5.138, y: 52.326 },
            { x: 5.139, y: 52.326 },
            { x: 5.166, y: 52.305 },
            { x: 5.24, y: 52.312 },
            { x: 5.291, y: 52.297 },
            { x: 5.313, y: 52.303 },
            { x: 5.314, y: 52.303 },
            { x: 5.297, y: 52.293 },
            { x: 5.306, y: 52.278 },
            { x: 5.266, y: 52.282 },
            { x: 5.223, y: 52.225 },
            { x: 5.217, y: 52.205 },
            { x: 5.193, y: 52.178 },
            { x: 5.121, y: 52.181 },
            { x: 5.046, y: 52.166 },
            { x: 5.036, y: 52.21 },
            { x: 5.033, y: 52.21 },
            { x: 5.042, y: 52.231 },
            { x: 5.058, y: 52.235 },
            { x: 5.027, y: 52.264 },
            { x: 5.043, y: 52.282 },
            { x: 5.022, y: 52.302 },
            { x: 5.013, y: 52.33 },
            { x: 5.028, y: 52.338 },
            { x: 5.094, y: 52.339 },
            { x: 5.138, y: 52.326 },
        ],
    ],
    GG4506: [
        [
            { x: 4.67, y: 52.231 },
            { x: 4.686, y: 52.227 },
            { x: 4.724, y: 52.232 },
            { x: 4.725, y: 52.213 },
            { x: 4.795, y: 52.227 },
            { x: 4.814, y: 52.202 },
            { x: 4.844, y: 52.18 },
            { x: 4.892, y: 52.162 },
            { x: 4.874, y: 52.156 },
            { x: 4.875, y: 52.139 },
            { x: 4.833, y: 52.145 },
            { x: 4.813, y: 52.14 },
            { x: 4.792, y: 52.122 },
            { x: 4.825, y: 52.107 },
            { x: 4.826, y: 52.075 },
            { x: 4.868, y: 52.063 },
            { x: 4.83, y: 52.05 },
            { x: 4.803, y: 52.014 },
            { x: 4.847, y: 52.018 },
            { x: 4.857, y: 52.006 },
            { x: 4.818, y: 52 },
            { x: 4.86, y: 51.968 },
            { x: 4.878, y: 51.938 },
            { x: 4.832, y: 51.939 },
            { x: 4.811, y: 51.925 },
            { x: 4.762, y: 51.914 },
            { x: 4.716, y: 51.896 },
            { x: 4.673, y: 51.89 },
            { x: 4.62, y: 51.89 },
            { x: 4.601, y: 51.907 },
            { x: 4.643, y: 51.913 },
            { x: 4.584, y: 51.965 },
            { x: 4.599, y: 51.988 },
            { x: 4.579, y: 51.99 },
            { x: 4.572, y: 51.972 },
            { x: 4.554, y: 51.969 },
            { x: 4.546, y: 51.992 },
            { x: 4.555, y: 52.006 },
            { x: 4.565, y: 52.059 },
            { x: 4.557, y: 52.061 },
            { x: 4.531, y: 52.081 },
            { x: 4.49, y: 52.098 },
            { x: 4.464, y: 52.127 },
            { x: 4.428, y: 52.101 },
            { x: 4.401, y: 52.112 },
            { x: 4.444, y: 52.146 },
            { x: 4.374, y: 52.187 },
            { x: 4.416, y: 52.231 },
            { x: 4.494, y: 52.328 },
            { x: 4.564, y: 52.309 },
            { x: 4.569, y: 52.316 },
            { x: 4.612, y: 52.314 },
            { x: 4.589, y: 52.281 },
            { x: 4.568, y: 52.269 },
            { x: 4.557, y: 52.219 },
            { x: 4.603, y: 52.215 },
            { x: 4.633, y: 52.216 },
            { x: 4.67, y: 52.231 },
        ],
    ],
    GG4607: [
        [
            { x: 4.62, y: 51.89 },
            { x: 4.647, y: 51.857 },
            { x: 4.605, y: 51.848 },
            { x: 4.571, y: 51.847 },
            { x: 4.554, y: 51.83 },
            { x: 4.499, y: 51.835 },
            { x: 4.397, y: 51.831 },
            { x: 4.369, y: 51.817 },
            { x: 4.354, y: 51.819 },
            { x: 4.264, y: 51.803 },
            { x: 4.247, y: 51.785 },
            { x: 4.212, y: 51.8 },
            { x: 4.182, y: 51.805 },
            { x: 4.156, y: 51.823 },
            { x: 4.094, y: 51.829 },
            { x: 4.074, y: 51.843 },
            { x: 4.045, y: 51.829 },
            { x: 4.063, y: 51.813 },
            { x: 4.165, y: 51.779 },
            { x: 4.189, y: 51.775 },
            { x: 4.243, y: 51.745 },
            { x: 4.263, y: 51.728 },
            { x: 4.329, y: 51.703 },
            { x: 4.384, y: 51.702 },
            { x: 4.398, y: 51.711 },
            { x: 4.404, y: 51.699 },
            { x: 4.394, y: 51.694 },
            { x: 4.368, y: 51.685 },
            { x: 4.357, y: 51.671 },
            { x: 4.281, y: 51.655 },
            { x: 4.232, y: 51.661 },
            { x: 4.22, y: 51.674 },
            { x: 4.189, y: 51.683 },
            { x: 4.157, y: 51.682 },
            { x: 4.155, y: 51.683 },
            { x: 4.155, y: 51.697 },
            { x: 4.111, y: 51.708 },
            { x: 4.059, y: 51.712 },
            { x: 4.022, y: 51.743 },
            { x: 4.03, y: 51.757 },
            { x: 4.022, y: 51.779 },
            { x: 3.992, y: 51.802 },
            { x: 3.949, y: 51.803 },
            { x: 3.897, y: 51.794 },
            { x: 3.869, y: 51.781 },
            { x: 3.855, y: 51.758 },
            { x: 3.842, y: 51.756 },
            { x: 3.839, y: 51.758 },
            { x: 3.864, y: 51.778 },
            { x: 3.86, y: 51.814 },
            { x: 4, y: 51.849 },
            { x: 4.036, y: 51.824 },
            { x: 4.061, y: 51.842 },
            { x: 4.065, y: 51.854 },
            { x: 4.029, y: 51.886 },
            { x: 4.054, y: 51.915 },
            { x: 4.039, y: 51.924 },
            { x: 4, y: 51.915 },
            { x: 3.985, y: 51.918 },
            { x: 3.961, y: 51.961 },
            { x: 3.984, y: 51.985 },
            { x: 4.042, y: 51.986 },
            { x: 4.048, y: 51.995 },
            { x: 4.095, y: 51.985 },
            { x: 4.125, y: 52.002 },
            { x: 4.147, y: 51.981 },
            { x: 4.187, y: 51.967 },
            { x: 4.192, y: 51.955 },
            { x: 4.218, y: 51.942 },
            { x: 4.242, y: 51.942 },
            { x: 4.259, y: 51.928 },
            { x: 4.293, y: 51.921 },
            { x: 4.337, y: 51.947 },
            { x: 4.394, y: 51.962 },
            { x: 4.428, y: 51.976 },
            { x: 4.459, y: 52.002 },
            { x: 4.457, y: 52.022 },
            { x: 4.477, y: 52.032 },
            { x: 4.505, y: 52.032 },
            { x: 4.557, y: 52.061 },
            { x: 4.565, y: 52.059 },
            { x: 4.555, y: 52.006 },
            { x: 4.546, y: 51.992 },
            { x: 4.554, y: 51.969 },
            { x: 4.572, y: 51.972 },
            { x: 4.579, y: 51.99 },
            { x: 4.599, y: 51.988 },
            { x: 4.584, y: 51.965 },
            { x: 4.643, y: 51.913 },
            { x: 4.601, y: 51.907 },
            { x: 4.62, y: 51.89 },
        ],
    ],
    GG4816: [
        [
            { x: 5.027, y: 51.859 },
            { x: 5.026, y: 51.819 },
            { x: 5, y: 51.821 },
            { x: 4.936, y: 51.828 },
            { x: 4.889, y: 51.819 },
            { x: 4.846, y: 51.799 },
            { x: 4.797, y: 51.8 },
            { x: 4.755, y: 51.782 },
            { x: 4.737, y: 51.756 },
            { x: 4.675, y: 51.724 },
            { x: 4.644, y: 51.719 },
            { x: 4.637, y: 51.717 },
            { x: 4.636, y: 51.717 },
            { x: 4.619, y: 51.723 },
            { x: 4.588, y: 51.712 },
            { x: 4.53, y: 51.701 },
            { x: 4.467, y: 51.715 },
            { x: 4.406, y: 51.722 },
            { x: 4.398, y: 51.711 },
            { x: 4.404, y: 51.723 },
            { x: 4.349, y: 51.743 },
            { x: 4.249, y: 51.766 },
            { x: 4.247, y: 51.785 },
            { x: 4.264, y: 51.803 },
            { x: 4.354, y: 51.819 },
            { x: 4.369, y: 51.817 },
            { x: 4.397, y: 51.831 },
            { x: 4.499, y: 51.835 },
            { x: 4.554, y: 51.83 },
            { x: 4.571, y: 51.847 },
            { x: 4.605, y: 51.848 },
            { x: 4.647, y: 51.857 },
            { x: 4.62, y: 51.89 },
            { x: 4.673, y: 51.89 },
            { x: 4.716, y: 51.896 },
            { x: 4.762, y: 51.914 },
            { x: 4.811, y: 51.925 },
            { x: 4.832, y: 51.939 },
            { x: 4.878, y: 51.938 },
            { x: 4.932, y: 51.948 },
            { x: 4.939, y: 51.929 },
            { x: 4.956, y: 51.916 },
            { x: 4.995, y: 51.902 },
            { x: 5.001, y: 51.875 },
            { x: 5.027, y: 51.859 },
        ],
        [
            { x: 4.311, y: 51.747 },
            { x: 4.353, y: 51.737 },
            { x: 4.337, y: 51.727 },
            { x: 4.311, y: 51.728 },
            { x: 4.262, y: 51.747 },
            { x: 4.311, y: 51.747 },
        ],
    ],
    GG5006: [
        [
            { x: 3.842, y: 51.756 },
            { x: 3.85, y: 51.738 },
            { x: 3.888, y: 51.744 },
            { x: 3.914, y: 51.731 },
            { x: 3.973, y: 51.733 },
            { x: 3.982, y: 51.718 },
            { x: 4.007, y: 51.703 },
            { x: 4.015, y: 51.687 },
            { x: 4.044, y: 51.685 },
            { x: 4.092, y: 51.667 },
            { x: 4.109, y: 51.678 },
            { x: 4.155, y: 51.683 },
            { x: 4.157, y: 51.682 },
            { x: 4.169, y: 51.654 },
            { x: 4.201, y: 51.639 },
            { x: 4.237, y: 51.634 },
            { x: 4.214, y: 51.628 },
            { x: 4.192, y: 51.609 },
            { x: 4.192, y: 51.594 },
            { x: 4.228, y: 51.564 },
            { x: 4.236, y: 51.543 },
            { x: 4.219, y: 51.505 },
            { x: 4.276, y: 51.425 },
            { x: 4.264, y: 51.416 },
            { x: 4.268, y: 51.384 },
            { x: 4.277, y: 51.376 },
            { x: 4.243, y: 51.375 },
            { x: 4.232, y: 51.395 },
            { x: 4.197, y: 51.407 },
            { x: 4.157, y: 51.395 },
            { x: 4.107, y: 51.403 },
            { x: 4.062, y: 51.414 },
            { x: 4.05, y: 51.43 },
            { x: 4.033, y: 51.432 },
            { x: 3.975, y: 51.462 },
            { x: 3.921, y: 51.444 },
            { x: 3.919, y: 51.427 },
            { x: 3.901, y: 51.395 },
            { x: 3.876, y: 51.397 },
            { x: 3.812, y: 51.386 },
            { x: 3.755, y: 51.415 },
            { x: 3.735, y: 51.411 },
            { x: 3.692, y: 51.444 },
            { x: 3.658, y: 51.457 },
            { x: 3.64, y: 51.444 },
            { x: 3.575, y: 51.439 },
            { x: 3.527, y: 51.463 },
            { x: 3.497, y: 51.494 },
            { x: 3.451, y: 51.515 },
            { x: 3.433, y: 51.527 },
            { x: 3.438, y: 51.542 },
            { x: 3.553, y: 51.589 },
            { x: 3.575, y: 51.594 },
            { x: 3.634, y: 51.589 },
            { x: 3.681, y: 51.6 },
            { x: 3.683, y: 51.623 },
            { x: 3.714, y: 51.642 },
            { x: 3.722, y: 51.663 },
            { x: 3.689, y: 51.678 },
            { x: 3.679, y: 51.707 },
            { x: 3.717, y: 51.736 },
            { x: 3.789, y: 51.744 },
            { x: 3.817, y: 51.741 },
            { x: 3.839, y: 51.758 },
            { x: 3.842, y: 51.756 },
        ],
        [
            { x: 4.097, y: 51.667 },
            { x: 4.097, y: 51.648 },
            { x: 4.071, y: 51.632 },
            { x: 4.05, y: 51.631 },
            { x: 4.007, y: 51.617 },
            { x: 3.968, y: 51.614 },
            { x: 3.94, y: 51.634 },
            { x: 3.914, y: 51.629 },
            { x: 3.869, y: 51.596 },
            { x: 3.897, y: 51.56 },
            { x: 3.873, y: 51.555 },
            { x: 3.868, y: 51.54 },
            { x: 3.927, y: 51.544 },
            { x: 3.94, y: 51.525 },
            { x: 3.957, y: 51.531 },
            { x: 4.018, y: 51.519 },
            { x: 4.052, y: 51.503 },
            { x: 4.055, y: 51.482 },
            { x: 4.093, y: 51.446 },
            { x: 4.133, y: 51.431 },
            { x: 4.181, y: 51.444 },
            { x: 4.223, y: 51.439 },
            { x: 4.214, y: 51.492 },
            { x: 4.177, y: 51.502 },
            { x: 4.164, y: 51.523 },
            { x: 4.074, y: 51.527 },
            { x: 4.049, y: 51.553 },
            { x: 4.005, y: 51.576 },
            { x: 3.99, y: 51.593 },
            { x: 4.024, y: 51.597 },
            { x: 4.075, y: 51.613 },
            { x: 4.128, y: 51.602 },
            { x: 4.101, y: 51.643 },
            { x: 4.12, y: 51.654 },
            { x: 4.152, y: 51.655 },
            { x: 4.15, y: 51.678 },
            { x: 4.109, y: 51.674 },
            { x: 4.097, y: 51.667 },
        ],
        [
            { x: 3.807, y: 51.697 },
            { x: 3.754, y: 51.675 },
            { x: 3.725, y: 51.669 },
            { x: 3.723, y: 51.656 },
            { x: 3.709, y: 51.631 },
            { x: 3.687, y: 51.622 },
            { x: 3.682, y: 51.601 },
            { x: 3.715, y: 51.592 },
            { x: 3.799, y: 51.605 },
            { x: 3.835, y: 51.606 },
            { x: 3.869, y: 51.596 },
            { x: 3.914, y: 51.629 },
            { x: 3.89, y: 51.633 },
            { x: 3.877, y: 51.667 },
            { x: 3.857, y: 51.678 },
            { x: 3.825, y: 51.684 },
            { x: 3.807, y: 51.697 },
        ],
        [
            { x: 3.707, y: 51.509 },
            { x: 3.726, y: 51.524 },
            { x: 3.688, y: 51.556 },
            { x: 3.655, y: 51.569 },
            { x: 3.683, y: 51.53 },
            { x: 3.707, y: 51.509 },
        ],
        [
            { x: 3.516, y: 51.407 },
            { x: 3.568, y: 51.401 },
            { x: 3.585, y: 51.388 },
            { x: 3.641, y: 51.376 },
            { x: 3.687, y: 51.371 },
            { x: 3.723, y: 51.35 },
            { x: 3.789, y: 51.35 },
            { x: 3.807, y: 51.338 },
            { x: 3.867, y: 51.336 },
            { x: 3.917, y: 51.361 },
            { x: 3.959, y: 51.368 },
            { x: 3.974, y: 51.407 },
            { x: 4.016, y: 51.401 },
            { x: 4.053, y: 51.366 },
            { x: 4.086, y: 51.364 },
            { x: 4.115, y: 51.352 },
            { x: 4.137, y: 51.363 },
            { x: 4.191, y: 51.376 },
            { x: 4.206, y: 51.374 },
            { x: 4.235, y: 51.348 },
            { x: 4.166, y: 51.293 },
            { x: 4.058, y: 51.243 },
            { x: 4.015, y: 51.245 },
            { x: 3.986, y: 51.234 },
            { x: 3.959, y: 51.216 },
            { x: 3.886, y: 51.2 },
            { x: 3.889, y: 51.223 },
            { x: 3.86, y: 51.211 },
            { x: 3.825, y: 51.209 },
            { x: 3.791, y: 51.214 },
            { x: 3.795, y: 51.256 },
            { x: 3.756, y: 51.269 },
            { x: 3.694, y: 51.276 },
            { x: 3.658, y: 51.29 },
            { x: 3.641, y: 51.288 },
            { x: 3.591, y: 51.304 },
            { x: 3.515, y: 51.287 },
            { x: 3.528, y: 51.246 },
            { x: 3.45, y: 51.242 },
            { x: 3.407, y: 51.257 },
            { x: 3.37, y: 51.292 },
            { x: 3.377, y: 51.302 },
            { x: 3.358, y: 51.315 },
            { x: 3.385, y: 51.334 },
            { x: 3.369, y: 51.372 },
            { x: 3.43, y: 51.391 },
            { x: 3.436, y: 51.388 },
            { x: 3.516, y: 51.407 },
        ],
    ],
    GG5206: [
        [
            { x: 5, y: 51.821 },
            { x: 5.016, y: 51.808 },
            { x: 5.048, y: 51.798 },
            { x: 5.067, y: 51.781 },
            { x: 5.096, y: 51.788 },
            { x: 5.138, y: 51.773 },
            { x: 5.138, y: 51.751 },
            { x: 5.128, y: 51.738 },
            { x: 5.054, y: 51.712 },
            { x: 5.009, y: 51.713 },
            { x: 4.958, y: 51.723 },
            { x: 4.918, y: 51.72 },
            { x: 4.933, y: 51.677 },
            { x: 4.933, y: 51.651 },
            { x: 4.91, y: 51.629 },
            { x: 4.914, y: 51.598 },
            { x: 4.864, y: 51.579 },
            { x: 4.87, y: 51.571 },
            { x: 4.866, y: 51.538 },
            { x: 4.911, y: 51.51 },
            { x: 4.978, y: 51.522 },
            { x: 4.992, y: 51.512 },
            { x: 4.988, y: 51.483 },
            { x: 5.017, y: 51.475 },
            { x: 5.004, y: 51.444 },
            { x: 4.963, y: 51.422 },
            { x: 4.929, y: 51.396 },
            { x: 4.889, y: 51.415 },
            { x: 4.839, y: 51.415 },
            { x: 4.789, y: 51.409 },
            { x: 4.773, y: 51.413 },
            { x: 4.787, y: 51.433 },
            { x: 4.83, y: 51.421 },
            { x: 4.823, y: 51.449 },
            { x: 4.84, y: 51.479 },
            { x: 4.815, y: 51.495 },
            { x: 4.753, y: 51.5 },
            { x: 4.693, y: 51.452 },
            { x: 4.667, y: 51.444 },
            { x: 4.67, y: 51.426 },
            { x: 4.63, y: 51.426 },
            { x: 4.575, y: 51.433 },
            { x: 4.535, y: 51.423 },
            { x: 4.53, y: 51.45 },
            { x: 4.548, y: 51.473 },
            { x: 4.538, y: 51.482 },
            { x: 4.487, y: 51.477 },
            { x: 4.442, y: 51.469 },
            { x: 4.385, y: 51.449 },
            { x: 4.397, y: 51.442 },
            { x: 4.392, y: 51.408 },
            { x: 4.432, y: 51.375 },
            { x: 4.422, y: 51.365 },
            { x: 4.385, y: 51.354 },
            { x: 4.341, y: 51.358 },
            { x: 4.335, y: 51.378 },
            { x: 4.277, y: 51.376 },
            { x: 4.268, y: 51.384 },
            { x: 4.264, y: 51.416 },
            { x: 4.276, y: 51.425 },
            { x: 4.219, y: 51.505 },
            { x: 4.236, y: 51.543 },
            { x: 4.228, y: 51.564 },
            { x: 4.192, y: 51.594 },
            { x: 4.192, y: 51.609 },
            { x: 4.214, y: 51.628 },
            { x: 4.237, y: 51.634 },
            { x: 4.317, y: 51.646 },
            { x: 4.35, y: 51.648 },
            { x: 4.388, y: 51.669 },
            { x: 4.394, y: 51.694 },
            { x: 4.404, y: 51.699 },
            { x: 4.444, y: 51.696 },
            { x: 4.49, y: 51.683 },
            { x: 4.52, y: 51.684 },
            { x: 4.602, y: 51.697 },
            { x: 4.636, y: 51.717 },
            { x: 4.637, y: 51.717 },
            { x: 4.644, y: 51.719 },
            { x: 4.675, y: 51.724 },
            { x: 4.737, y: 51.756 },
            { x: 4.755, y: 51.782 },
            { x: 4.797, y: 51.8 },
            { x: 4.846, y: 51.799 },
            { x: 4.889, y: 51.819 },
            { x: 4.936, y: 51.828 },
            { x: 5, y: 51.821 },
        ],
    ],
    GG5406: [
        [
            { x: 5.864, y: 51.758 },
            { x: 5.88, y: 51.75 },
            { x: 5.885, y: 51.728 },
            { x: 5.9, y: 51.72 },
            { x: 5.955, y: 51.709 },
            { x: 5.972, y: 51.646 },
            { x: 6.02, y: 51.622 },
            { x: 6.026, y: 51.596 },
            { x: 6.04, y: 51.582 },
            { x: 6.032, y: 51.552 },
            { x: 6.004, y: 51.57 },
            { x: 5.935, y: 51.554 },
            { x: 5.907, y: 51.552 },
            { x: 5.891, y: 51.56 },
            { x: 5.838, y: 51.566 },
            { x: 5.801, y: 51.579 },
            { x: 5.787, y: 51.598 },
            { x: 5.699, y: 51.584 },
            { x: 5.651, y: 51.582 },
            { x: 5.652, y: 51.57 },
            { x: 5.622, y: 51.553 },
            { x: 5.584, y: 51.563 },
            { x: 5.533, y: 51.558 },
            { x: 5.547, y: 51.538 },
            { x: 5.525, y: 51.522 },
            { x: 5.5, y: 51.521 },
            { x: 5.487, y: 51.542 },
            { x: 5.423, y: 51.534 },
            { x: 5.405, y: 51.548 },
            { x: 5.383, y: 51.544 },
            { x: 5.277, y: 51.549 },
            { x: 5.253, y: 51.54 },
            { x: 5.244, y: 51.526 },
            { x: 5.251, y: 51.51 },
            { x: 5.228, y: 51.499 },
            { x: 5.191, y: 51.43 },
            { x: 5.102, y: 51.429 },
            { x: 5.079, y: 51.471 },
            { x: 5.017, y: 51.475 },
            { x: 4.988, y: 51.483 },
            { x: 4.992, y: 51.512 },
            { x: 4.978, y: 51.522 },
            { x: 4.911, y: 51.51 },
            { x: 4.866, y: 51.538 },
            { x: 4.87, y: 51.571 },
            { x: 4.864, y: 51.579 },
            { x: 4.914, y: 51.598 },
            { x: 4.91, y: 51.629 },
            { x: 4.933, y: 51.651 },
            { x: 4.933, y: 51.677 },
            { x: 4.918, y: 51.72 },
            { x: 4.958, y: 51.723 },
            { x: 5.009, y: 51.713 },
            { x: 5.054, y: 51.712 },
            { x: 5.128, y: 51.738 },
            { x: 5.163, y: 51.743 },
            { x: 5.216, y: 51.743 },
            { x: 5.245, y: 51.734 },
            { x: 5.296, y: 51.737 },
            { x: 5.354, y: 51.755 },
            { x: 5.369, y: 51.79 },
            { x: 5.402, y: 51.821 },
            { x: 5.439, y: 51.81 },
            { x: 5.474, y: 51.815 },
            { x: 5.486, y: 51.829 },
            { x: 5.541, y: 51.816 },
            { x: 5.558, y: 51.828 },
            { x: 5.589, y: 51.83 },
            { x: 5.635, y: 51.82 },
            { x: 5.657, y: 51.798 },
            { x: 5.732, y: 51.772 },
            { x: 5.745, y: 51.759 },
            { x: 5.78, y: 51.752 },
            { x: 5.864, y: 51.758 },
        ],
    ],
    GG5608: [
        [
            { x: 5.838, y: 51.566 },
            { x: 5.853, y: 51.504 },
            { x: 5.872, y: 51.45 },
            { x: 5.931, y: 51.385 },
            { x: 5.875, y: 51.353 },
            { x: 5.672, y: 51.315 },
            { x: 5.626, y: 51.274 },
            { x: 5.619, y: 51.229 },
            { x: 5.566, y: 51.221 },
            { x: 5.555, y: 51.244 },
            { x: 5.558, y: 51.262 },
            { x: 5.516, y: 51.295 },
            { x: 5.485, y: 51.3 },
            { x: 5.465, y: 51.285 },
            { x: 5.442, y: 51.282 },
            { x: 5.417, y: 51.262 },
            { x: 5.346, y: 51.276 },
            { x: 5.336, y: 51.263 },
            { x: 5.296, y: 51.261 },
            { x: 5.226, y: 51.268 },
            { x: 5.242, y: 51.305 },
            { x: 5.2, y: 51.323 },
            { x: 5.163, y: 51.31 },
            { x: 5.134, y: 51.316 },
            { x: 5.132, y: 51.347 },
            { x: 5.071, y: 51.393 },
            { x: 5.102, y: 51.429 },
            { x: 5.191, y: 51.43 },
            { x: 5.228, y: 51.499 },
            { x: 5.251, y: 51.51 },
            { x: 5.244, y: 51.526 },
            { x: 5.253, y: 51.54 },
            { x: 5.277, y: 51.549 },
            { x: 5.383, y: 51.544 },
            { x: 5.405, y: 51.548 },
            { x: 5.423, y: 51.534 },
            { x: 5.487, y: 51.542 },
            { x: 5.5, y: 51.521 },
            { x: 5.525, y: 51.522 },
            { x: 5.547, y: 51.538 },
            { x: 5.533, y: 51.558 },
            { x: 5.584, y: 51.563 },
            { x: 5.622, y: 51.553 },
            { x: 5.652, y: 51.57 },
            { x: 5.651, y: 51.582 },
            { x: 5.699, y: 51.584 },
            { x: 5.787, y: 51.598 },
            { x: 5.801, y: 51.579 },
            { x: 5.838, y: 51.566 },
        ],
    ],
    GG6011: [
        [
            { x: 5.953, y: 51.748 },
            { x: 5.955, y: 51.738 },
            { x: 5.994, y: 51.738 },
            { x: 6.045, y: 51.717 },
            { x: 6.026, y: 51.709 },
            { x: 6.037, y: 51.673 },
            { x: 6.117, y: 51.657 },
            { x: 6.091, y: 51.606 },
            { x: 6.121, y: 51.593 },
            { x: 6.157, y: 51.567 },
            { x: 6.177, y: 51.539 },
            { x: 6.212, y: 51.513 },
            { x: 6.213, y: 51.491 },
            { x: 6.224, y: 51.475 },
            { x: 6.205, y: 51.4 },
            { x: 6.226, y: 51.36 },
            { x: 6.169, y: 51.333 },
            { x: 6.125, y: 51.275 },
            { x: 6.073, y: 51.243 },
            { x: 6.068, y: 51.221 },
            { x: 6.073, y: 51.183 },
            { x: 6.082, y: 51.172 },
            { x: 6.113, y: 51.175 },
            { x: 6.165, y: 51.194 },
            { x: 6.181, y: 51.186 },
            { x: 6.139, y: 51.173 },
            { x: 6.175, y: 51.158 },
            { x: 6.163, y: 51.149 },
            { x: 6.126, y: 51.145 },
            { x: 6.092, y: 51.135 },
            { x: 6.076, y: 51.119 },
            { x: 6.036, y: 51.097 },
            { x: 6.01, y: 51.091 },
            { x: 5.97, y: 51.061 },
            { x: 5.969, y: 51.047 },
            { x: 5.938, y: 51.035 },
            { x: 5.913, y: 51.067 },
            { x: 5.887, y: 51.052 },
            { x: 5.877, y: 51.032 },
            { x: 5.853, y: 51.029 },
            { x: 5.849, y: 51.046 },
            { x: 5.827, y: 51.048 },
            { x: 5.824, y: 51.067 },
            { x: 5.799, y: 51.06 },
            { x: 5.795, y: 51.09 },
            { x: 5.834, y: 51.1 },
            { x: 5.81, y: 51.118 },
            { x: 5.84, y: 51.131 },
            { x: 5.856, y: 51.145 },
            { x: 5.824, y: 51.168 },
            { x: 5.778, y: 51.151 },
            { x: 5.767, y: 51.184 },
            { x: 5.74, y: 51.187 },
            { x: 5.708, y: 51.182 },
            { x: 5.658, y: 51.185 },
            { x: 5.646, y: 51.2 },
            { x: 5.566, y: 51.221 },
            { x: 5.619, y: 51.229 },
            { x: 5.626, y: 51.274 },
            { x: 5.672, y: 51.315 },
            { x: 5.875, y: 51.353 },
            { x: 5.931, y: 51.385 },
            { x: 5.872, y: 51.45 },
            { x: 5.853, y: 51.504 },
            { x: 5.838, y: 51.566 },
            { x: 5.891, y: 51.56 },
            { x: 5.907, y: 51.552 },
            { x: 5.935, y: 51.554 },
            { x: 6.004, y: 51.57 },
            { x: 6.032, y: 51.552 },
            { x: 6.04, y: 51.582 },
            { x: 6.026, y: 51.596 },
            { x: 6.02, y: 51.622 },
            { x: 5.972, y: 51.646 },
            { x: 5.955, y: 51.709 },
            { x: 5.9, y: 51.72 },
            { x: 5.885, y: 51.728 },
            { x: 5.88, y: 51.75 },
            { x: 5.864, y: 51.758 },
            { x: 5.868, y: 51.776 },
            { x: 5.893, y: 51.778 },
            { x: 5.915, y: 51.753 },
            { x: 5.953, y: 51.748 },
        ],
    ],
    GG7014: [
        [
            { x: 4.557, y: 52.061 },
            { x: 4.505, y: 52.032 },
            { x: 4.477, y: 52.032 },
            { x: 4.457, y: 52.022 },
            { x: 4.459, y: 52.002 },
            { x: 4.428, y: 51.976 },
            { x: 4.394, y: 51.962 },
            { x: 4.337, y: 51.947 },
            { x: 4.293, y: 51.921 },
            { x: 4.259, y: 51.928 },
            { x: 4.242, y: 51.942 },
            { x: 4.218, y: 51.942 },
            { x: 4.192, y: 51.955 },
            { x: 4.187, y: 51.967 },
            { x: 4.147, y: 51.981 },
            { x: 4.125, y: 52.002 },
            { x: 4.187, y: 52.052 },
            { x: 4.205, y: 52.061 },
            { x: 4.259, y: 52.101 },
            { x: 4.303, y: 52.128 },
            { x: 4.374, y: 52.187 },
            { x: 4.444, y: 52.146 },
            { x: 4.401, y: 52.112 },
            { x: 4.428, y: 52.101 },
            { x: 4.464, y: 52.127 },
            { x: 4.49, y: 52.098 },
            { x: 4.531, y: 52.081 },
            { x: 4.557, y: 52.061 },
        ],
    ],
    GG7206: [
        [
            { x: 6.191, y: 53.411 },
            { x: 6.162, y: 53.408 },
            { x: 6.155, y: 53.392 },
            { x: 6.181, y: 53.357 },
            { x: 6.218, y: 53.354 },
            { x: 6.221, y: 53.342 },
            { x: 6.254, y: 53.348 },
            { x: 6.287, y: 53.341 },
            { x: 6.279, y: 53.303 },
            { x: 6.254, y: 53.289 },
            { x: 6.256, y: 53.271 },
            { x: 6.218, y: 53.242 },
            { x: 6.23, y: 53.218 },
            { x: 6.2, y: 53.195 },
            { x: 6.177, y: 53.167 },
            { x: 6.176, y: 53.135 },
            { x: 6.206, y: 53.115 },
            { x: 6.262, y: 53.114 },
            { x: 6.315, y: 53.094 },
            { x: 6.305, y: 53.081 },
            { x: 6.368, y: 53.067 },
            { x: 6.363, y: 53.034 },
            { x: 6.428, y: 52.972 },
            { x: 6.393, y: 52.933 },
            { x: 6.333, y: 52.906 },
            { x: 6.303, y: 52.925 },
            { x: 6.257, y: 52.928 },
            { x: 6.207, y: 52.891 },
            { x: 6.12, y: 52.854 },
            { x: 6.031, y: 52.815 },
            { x: 5.997, y: 52.817 },
            { x: 5.972, y: 52.842 },
            { x: 5.93, y: 52.835 },
            { x: 5.924, y: 52.824 },
            { x: 5.876, y: 52.801 },
            { x: 5.844, y: 52.805 },
            { x: 5.82, y: 52.817 },
            { x: 5.795, y: 52.807 },
            { x: 5.749, y: 52.84 },
            { x: 5.713, y: 52.838 },
            { x: 5.685, y: 52.846 },
            { x: 5.658, y: 52.844 },
            { x: 5.644, y: 52.861 },
            { x: 5.618, y: 52.851 },
            { x: 5.585, y: 52.849 },
            { x: 5.567, y: 52.834 },
            { x: 5.545, y: 52.833 },
            { x: 5.454, y: 52.855 },
            { x: 5.426, y: 52.848 },
            { x: 5.366, y: 52.874 },
            { x: 5.354, y: 52.887 },
            { x: 5.371, y: 52.899 },
            { x: 5.407, y: 52.91 },
            { x: 5.401, y: 52.939 },
            { x: 5.413, y: 52.964 },
            { x: 5.397, y: 53.003 },
            { x: 5.408, y: 53.02 },
            { x: 5.376, y: 53.055 },
            { x: 5.393, y: 53.063 },
            { x: 5.375, y: 53.091 },
            { x: 5.343, y: 53.074 },
            { x: 5.297, y: 53.067 },
            { x: 5.167, y: 52.999 },
            { x: 5.166, y: 53 },
            { x: 5.293, y: 53.067 },
            { x: 5.349, y: 53.078 },
            { x: 5.378, y: 53.095 },
            { x: 5.379, y: 53.108 },
            { x: 5.404, y: 53.122 },
            { x: 5.42, y: 53.182 },
            { x: 5.449, y: 53.22 },
            { x: 5.483, y: 53.241 },
            { x: 5.527, y: 53.257 },
            { x: 5.587, y: 53.3 },
            { x: 5.643, y: 53.319 },
            { x: 5.732, y: 53.343 },
            { x: 5.792, y: 53.362 },
            { x: 5.831, y: 53.371 },
            { x: 5.888, y: 53.391 },
            { x: 5.933, y: 53.388 },
            { x: 6.016, y: 53.403 },
            { x: 6.095, y: 53.408 },
            { x: 6.139, y: 53.404 },
            { x: 6.177, y: 53.415 },
            { x: 6.191, y: 53.411 },
        ],
        [
            { x: 5.47, y: 53.407 },
            { x: 5.384, y: 53.402 },
            { x: 5.357, y: 53.388 },
            { x: 5.293, y: 53.37 },
            { x: 5.257, y: 53.373 },
            { x: 5.221, y: 53.364 },
            { x: 5.196, y: 53.35 },
            { x: 5.155, y: 53.355 },
            { x: 5.194, y: 53.393 },
            { x: 5.251, y: 53.404 },
            { x: 5.376, y: 53.423 },
            { x: 5.473, y: 53.441 },
            { x: 5.511, y: 53.445 },
            { x: 5.541, y: 53.433 },
            { x: 5.476, y: 53.417 },
            { x: 5.47, y: 53.407 },
        ],
        [
            { x: 5.687, y: 53.465 },
            { x: 5.771, y: 53.46 },
            { x: 5.884, y: 53.467 },
            { x: 5.937, y: 53.457 },
            { x: 5.805, y: 53.438 },
            { x: 5.73, y: 53.443 },
            { x: 5.703, y: 53.43 },
            { x: 5.67, y: 53.423 },
            { x: 5.63, y: 53.43 },
            { x: 5.616, y: 53.453 },
            { x: 5.645, y: 53.469 },
            { x: 5.687, y: 53.465 },
        ],
        [
            { x: 6.391, y: 53.516 },
            { x: 6.385, y: 53.511 },
            { x: 6.274, y: 53.489 },
            { x: 6.252, y: 53.478 },
            { x: 6.197, y: 53.472 },
            { x: 6.13, y: 53.474 },
            { x: 6.155, y: 53.5 },
            { x: 6.289, y: 53.509 },
            { x: 6.367, y: 53.517 },
            { x: 6.391, y: 53.516 },
        ],
        [
            { x: 5.1, y: 53.301 },
            { x: 5.063, y: 53.294 },
            { x: 5.033, y: 53.281 },
            { x: 4.972, y: 53.265 },
            { x: 4.979, y: 53.252 },
            { x: 4.919, y: 53.216 },
            { x: 4.854, y: 53.222 },
            { x: 4.933, y: 53.255 },
            { x: 4.961, y: 53.271 },
            { x: 5.036, y: 53.302 },
            { x: 5.064, y: 53.308 },
            { x: 5.1, y: 53.301 },
        ],
    ],
    GG6106: [
        [
            { x: 5.877, y: 51.032 },
            { x: 5.879, y: 51.018 },
            { x: 5.906, y: 51.002 },
            { x: 5.897, y: 50.975 },
            { x: 5.955, y: 50.988 },
            { x: 5.966, y: 50.98 },
            { x: 6.017, y: 50.983 },
            { x: 6.018, y: 50.935 },
            { x: 6.082, y: 50.922 },
            { x: 6.075, y: 50.893 },
            { x: 6.088, y: 50.872 },
            { x: 6.077, y: 50.861 },
            { x: 6.019, y: 50.846 },
            { x: 6.025, y: 50.814 },
            { x: 6.004, y: 50.801 },
            { x: 5.975, y: 50.798 },
            { x: 6.028, y: 50.774 },
            { x: 6.021, y: 50.754 },
            { x: 5.89, y: 50.756 },
            { x: 5.886, y: 50.77 },
            { x: 5.807, y: 50.756 },
            { x: 5.777, y: 50.783 },
            { x: 5.695, y: 50.755 },
            { x: 5.694, y: 50.812 },
            { x: 5.656, y: 50.824 },
            { x: 5.639, y: 50.846 },
            { x: 5.644, y: 50.871 },
            { x: 5.683, y: 50.889 },
            { x: 5.698, y: 50.91 },
            { x: 5.726, y: 50.921 },
            { x: 5.756, y: 50.958 },
            { x: 5.72, y: 50.962 },
            { x: 5.766, y: 50.998 },
            { x: 5.776, y: 51.022 },
            { x: 5.758, y: 51.034 },
            { x: 5.779, y: 51.063 },
            { x: 5.799, y: 51.06 },
            { x: 5.824, y: 51.067 },
            { x: 5.827, y: 51.048 },
            { x: 5.849, y: 51.046 },
            { x: 5.853, y: 51.029 },
            { x: 5.877, y: 51.032 },
        ],
    ],
    GG7306: [
        [
            { x: 5.02, y: 52.612 },
            { x: 5.031, y: 52.568 },
            { x: 5.055, y: 52.545 },
            { x: 5.086, y: 52.504 },
            { x: 5.059, y: 52.486 },
            { x: 5.062, y: 52.468 },
            { x: 5.049, y: 52.439 },
            { x: 5.078, y: 52.437 },
            { x: 5.068, y: 52.417 },
            { x: 5.049, y: 52.415 },
            { x: 5, y: 52.425 },
            { x: 4.948, y: 52.422 },
            { x: 4.931, y: 52.412 },
            { x: 4.899, y: 52.424 },
            { x: 4.866, y: 52.431 },
            { x: 4.856, y: 52.417 },
            { x: 4.739, y: 52.431 },
            { x: 4.711, y: 52.436 },
            { x: 4.675, y: 52.456 },
            { x: 4.698, y: 52.471 },
            { x: 4.715, y: 52.493 },
            { x: 4.782, y: 52.52 },
            { x: 4.842, y: 52.543 },
            { x: 4.866, y: 52.574 },
            { x: 4.915, y: 52.609 },
            { x: 4.95, y: 52.595 },
            { x: 5.005, y: 52.598 },
            { x: 5.02, y: 52.612 },
        ],
    ],
};
